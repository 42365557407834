import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { ConfirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { SplitButton } from "primereact/splitbutton";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "src/providers/StoreProvider";
import { PRIVATE_PATH } from "src/routes/index.routes";
import UserService from "src/service/UserService";
import { getDate, getDateMillis, buildSignUpPath, copyToClipboard } from "src/utils";

interface ClientDataTable {
  docId: string;
  name: string;
  financialEmail: string;
  email: string;
  role: string;
  login: string;
  disabled: boolean;
  createdAt: number;
}

const ListClients = () => {
  const {
    clientContext: { clients, setSelectedClient },
    toastContext: { showMessage },
  } = useStore();
  const navigate = useNavigate();
  const [selectedClientDataTable, setSelectedClientDataTable] = useState<ClientDataTable | null>(null);
  const [visible, setVisible] = useState<boolean>(false);

  const handleClients = (): ClientDataTable[] => {
    return clients.map((client) => ({
      docId: client.docId,
      name: client.name,
      financialEmail: client.financialEmail,
      email: client.email,
      role: client.role,
      login: client.uid ? "Sim" : "Não",
      disabled: client.disabled || false,
      createdAt: getDateMillis(client.createdAt),
    }));
  };

  const onSelectedClient = (data: ClientDataTable, path: PRIVATE_PATH) => {
    const client = clients.find((c) => c.docId === data.docId);
    if (client) {
      setSelectedClient(client);
      navigate(path);
    }
  };

  const onClickSetAccountState = (data: ClientDataTable) => {
    setSelectedClientDataTable(data);
    setVisible(true);
  };

  const onAcceptSetAccountState = async (selectedClientDataTable: ClientDataTable) => {
    try {
      await UserService.setAccountState(selectedClientDataTable.docId, !selectedClientDataTable.disabled);
      setVisible(false);
      setSelectedClientDataTable(null);
    } catch (error) {
      console.error(error);
    }
  };

  const copyUrl = (docId: string) => {
    const path = buildSignUpPath(docId);
    copyToClipboard(path);
    showMessage({
      severity: "success",
      summary: "URL de cadastro copiada!",
      detail: "Envie essa URL para o usuário criar o login.",
      life: 5000,
    });
  };

  const editButtonBody = (data: ClientDataTable): JSX.Element => {
    const items = [
      {
        label: "Criar Projeto",
        icon: "pi pi-home",
        command: () => onSelectedClient(data, PRIVATE_PATH.PROJECTS_CREATE),
      },
      {
        label: data.disabled ? "Ativar conta" : "Desativar conta",
        icon: data.disabled ? "pi pi-check" : "pi pi-ban",
        command: () => onClickSetAccountState(data),
      },
    ];

    if (data.login === "Não")
      items.push({
        label: "URL de Cadastro",
        icon: "pi pi-copy",
        command: () => copyUrl(data.docId),
      });

    return (
      <SplitButton
        label="Editar"
        model={items}
        className="p-button-sm p-button-outlined"
        menuButtonClassName="w-auto px-2"
        onClick={() => onSelectedClient(data, PRIVATE_PATH.CLIENTS_CREATE)}
      />
    );
  };

  const createdAtBody = (data: ClientDataTable): JSX.Element => {
    const localeDate = getDate(data.createdAt);
    return <span>{localeDate}</span>;
  };

  const disabledBody = (data: ClientDataTable): JSX.Element => {
    return <span>{data.disabled ? "Sim" : "Não"}</span>;
  };

  return (
    <Card title="Listar Clientes">
      <DataTable
        value={handleClients()}
        responsiveLayout="scroll"
        stripedRows
        paginator
        removableSort
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="{first} - {last} de {totalRecords}"
        rows={5}
        rowsPerPageOptions={[5, 10, 20, 30, 40, 50]}
      >
        <Column header="Ações" body={editButtonBody}></Column>
        <Column field="name" header="Nome" sortable style={{ minWidth: 200 }}></Column>
        <Column field="financialEmail" header="Email Financeiro" sortable></Column>
        <Column field="email" header="Email Pessoal" sortable></Column>
        <Column field="role" header="Perfil" sortable></Column>
        <Column field="login" header="Login" sortable></Column>
        <Column field="createdAt" header="Data de Cadastro" sortable body={createdAtBody}></Column>
        <Column field="disabled" header="Desativado" sortable body={disabledBody}></Column>
      </DataTable>

      {selectedClientDataTable && (
        <ConfirmDialog
          visible={visible}
          message={
            <span>
              {`Deseja ${selectedClientDataTable.disabled ? "ativar" : "desativar"} a conta do cliente `}
              <b>{selectedClientDataTable.name}</b>
              {"?"}
            </span>
          }
          header={`${selectedClientDataTable.disabled ? "Ativar" : "Desativar"} conta`}
          icon="pi pi-exclamation-triangle"
          acceptLabel="Sim"
          rejectLabel="Não"
          accept={() => onAcceptSetAccountState(selectedClientDataTable)}
          reject={() => setVisible(false)}
          onHide={() => setVisible(false)}
        />
      )}
    </Card>
  );
};

export default ListClients;
