import React, { useRef, useState } from "react";
import classNames from "classnames";
import { MenuItem } from "primereact/menuitem";
import { TieredMenu } from "primereact/tieredmenu";
import Button from "src/components/Button";
import AuthenticationService from "src/service/AuthenticationService";
import { useStore } from "src/providers/StoreProvider";
import ProfileSettings from "../ProfileSettings";

const ProfileButton = () => {
  const {
    themeContext: { theme, onClickTheme },
  } = useStore();

  const menu = useRef<TieredMenu>(null);
  const [visible, setVisible] = useState<boolean>(false);

  const items: MenuItem[] = [
    {
      label: "Configurações",
      icon: "pi pi-fw pi-cog",
      command: () => setVisible(true),
    },
    {
      label: `${theme === "light" ? "Modo Escuro" : "Modo Claro"}`,
      icon: `pi pi-fw pi-${theme === "light" ? "moon" : "sun"}`,
      className: classNames("theme-toggle", theme),
      command: () => onClickTheme(),
    },
    { separator: true },
    {
      label: "Sair",
      icon: "pi pi-fw pi-sign-out",
      className: "signout-button",
      command: () => AuthenticationService.signOut(),
    },
  ];

  const onClickProfile = (event: React.MouseEvent<HTMLButtonElement>) => {
    menu.current && menu.current.toggle(event);
  };

  return (
    <>
      <TieredMenu popup ref={menu} model={items} />
      <Button
        icon="pi pi-user"
        className="text-color"
        text
        rounded
        size="large"
        onClick={onClickProfile}
      />
      <ProfileSettings visible={visible} onHide={() => setVisible(false)} />
    </>
  );
};

export default ProfileButton;
