import React, { useContext, createContext } from "react";
import { AuthContext, authContextDefault, useAuthProvider } from "../AuthProvider";
import { ClientContext, clientContextDefault, useClientProvider } from "../ClientProvider";
import { ProjectContext, projectContextDefault, useProjectProvider } from "../ProjectProvider";
import { SidebarContext, sidebarContextDefault, useSidebarProvider } from "../SidebarProvider";
import { ThemeContext, themeContextDefault, useThemeProvider } from "../ThemeProvider";
import { ToastContext, toastContextDefault, useToastProvider } from "../ToastProvider";

interface StoreContext {
  authContext: AuthContext;
  sidebarContext: SidebarContext;
  themeContext: ThemeContext;
  clientContext: ClientContext;
  projectContext: ProjectContext;
  toastContext: ToastContext;
}

const storeContext = createContext<StoreContext>({
  authContext: authContextDefault,
  sidebarContext: sidebarContextDefault,
  themeContext: themeContextDefault,
  clientContext: clientContextDefault,
  projectContext: projectContextDefault,
  toastContext: toastContextDefault,
});

export const useStore = () => useContext(storeContext);

const StoreProvider = ({ children }: { children: React.ReactNode }) => {
  const authContext = useAuthProvider();
  const themeContext = useThemeProvider();
  const sidebarContext = useSidebarProvider();
  const clientContext = useClientProvider(authContext.userData);
  const projectContext = useProjectProvider(authContext.userData);
  const toastContext = useToastProvider();

  return (
    <storeContext.Provider
      value={{
        authContext,
        sidebarContext,
        themeContext,
        clientContext,
        projectContext,
        toastContext,
      }}
    >
      {children}
    </storeContext.Provider>
  );
};

export default StoreProvider;
