import { MENU_ITEM_TYPE } from "src/models/types";

export const inputFieldHeight = 41.46;
export const maxInputNumber = 50;
const CHARS = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

export const scrollToSection = <T extends HTMLElement>(
  elementRef: React.RefObject<T>
): void => {
  if (elementRef.current) {
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  }
};

export const createId = (length: number): string => {
  let id = "";
  for (let i = 0; i < length; i++) {
    id += CHARS.charAt(Math.floor(Math.random() * CHARS.length));
  }
  return id;
};

export const createRootId = (id: string): string => {
  return MENU_ITEM_TYPE.ROOT + "/" + id;
};

export const buildSignUpPath = (docId: string): string => {
  const [domain] = window.location.href.split("#");
  return `${domain}#/sign_up/${docId}`;
};

export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text);
};

export const requiredFieldMessage = "Campo obrigatório.";
export const invalidEmailMessage = "Email inválido.";

export const statesBR: string[] = [
  "AC",
  "AL",
  "AP",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RO",
  "RR",
  "SC",
  "SP",
  "SE",
  "TO",
];

/**
 * Validate email
 * @param email
 * @returns True if is valid email otherwise False
 */
export const validateEmail = (email: string): boolean => {
  // cannot include spaces
  if (email.includes(" ")) return false;

  // Must contain at least one '@'
  if (!email.includes("@")) return false;

  const splitEmail = email.split("@");
  // cannot include more than one '@'
  if (splitEmail.length > 2) return false;

  const [username, domain] = splitEmail;
  // username length must be greater than 0
  if (username.length === 0) return false;
  // domain length must be greater than or equal 3
  if (domain.length < 3) return false;
  // domain must includes at least one dot
  if (!domain.includes(".")) return false;

  const splitUsername = username.split(".");
  const splitDomain = domain.split(".");
  // dots must contain before and after values
  if (splitUsername.includes("") || splitDomain.includes("")) return false;

  const chars = CHARS + "@.";

  for (let i = 0; i < email.length; i++) {
    const char = email.charAt(i);
    if (!chars.includes(char)) return false;
  }

  return true;
};

export const roundWithTwoDecimalPlaces = (num: number): number => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

/** Return True if is a hexadecimal string */
export const validateIsHexa = (value: string): boolean => {
  const hexadecimalValues = "0123456789ABCDEF";
  for (const char of value) if (!hexadecimalValues.includes(char)) return false;
  return true;
};

export const getDateUTC = (): string => {
  return new Date().toUTCString();
};

export const getDateLocale = (UTC: string): string => {
  return new Date(Date.parse(UTC)).toLocaleString();
};

export const getDateMillis = (UTC: string): number => {
  return Date.parse(UTC);
};

export const getDate = (millis: number): string => {
  return new Date(millis).toLocaleDateString();
};
