import { useEffect, useRef, useState } from "react";
import { io, Socket } from "socket.io-client";
import { IotEvents, Payload, SocketEvents, Topics } from "src/models/types";
import { useStore } from "src/providers/StoreProvider";

interface AwsIoT {
  socket: Socket;
  socketConnected: boolean;
  socketDisconnect(): void;
}

const useAwsIoT = (): AwsIoT => {
  const {
    authContext: { userData },
  } = useStore();
  const socketRef = useRef<Socket>(createSocketIo());
  const [socketConnected, setSocketConnected] = useState<boolean>(false);

  function createSocketIo(): Socket {
    return io(process.env.REACT_APP_API_URL as string, {
      autoConnect: false,
      forceNew: true,
      auth: { clientId: userData ? userData.docId : "" },
    });
  }

  useEffect(() => {
    connect();
  }, []);

  /** socket connect */
  function connect() {
    socketRef.current.on(SocketEvents.CONNECT, () => {
      setSocketConnected(true);
      socketRef.current.emit(SocketEvents.SET_SOCKET_ID);
      // console.log("connect: ", socketRef.current.id);
    });

    socketRef.current.on(SocketEvents.CONNECT_ERROR, () => {
      setSocketConnected(false);
      socketRef.current.removeAllListeners();
      socketRef.current.disconnect();
      socketRef.current = createSocketIo();
      connect();
    });

    socketRef.current.on(SocketEvents.DISCONNECT, (reason: Socket.DisconnectReason) => {
      setSocketConnected(false);
      // the disconnection was initiated by the server, you need to reconnect manually
      if (reason !== "io client disconnect") socketRef.current.disconnect().connect();
      // else the socket will automatically try to reconnect
      // console.log("disconnect: ", reason);
    });

    socketRef.current.connect();
  }

  function socketDisconnect() {
    socketRef.current.disconnect();
  }

  return {
    socket: socketRef.current,
    socketConnected,
    socketDisconnect,
  };
};

export default useAwsIoT;

// Acknowledgement
export function ack(status: number) {
  // console.log("ack_status: ", status);
}

/** Subscribe to an AWS IoT topics */
export function iot_subscribe(socket: Socket, topics: Topics) {
  socket.emit(IotEvents.SUBSCRIBE, topics, ack);
  // console.log("subscribe: ", topics);
}

/** Unsubscribe to an AWS IoT topics */
export function iot_unsubscribe(socket: Socket, topics: Topics) {
  socket.emit(IotEvents.UNSUBSCRIBE, topics, ack);
  // console.log("unsubscribe: ", topics);
}

/** Publish message to an AWS IoT topics */
export function iot_publish(socket: Socket, topics: Topics, payload: Payload) {
  socket.emit(IotEvents.PUBLISH, topics, payload, ack);
  // console.log("publish: ", topics);
}
