import React from "react";
import classNames from "classnames";
import { MenuItem } from "primereact/menuitem";
import { Ripple } from "primereact/ripple";
import { CSSTransition } from "react-transition-group";
import { useStore } from "src/providers/StoreProvider";
import { MENU_ITEM_TYPE } from "src/models/types";
import { useLocation } from "react-router-dom";

interface MenuProps {
  items?: MenuItem[];
  className?: string;
}

const Menu = (props: MenuProps) => {
  const { items, className } = props;
  const {
    sidebarContext: { checkIfButtonIsActive, handleOnClickButton },
  } = useStore();
  const location = useLocation();

  const renderNavlink = (item: MenuItem) => {
    const id = item.id;
    const hasItems = !!item.items;

    const active = id && id.includes(location.pathname);

    const toggleIcon = hasItems && (
      <i className="pi pi-fw pi-angle-down menuitem-toggle-icon" />
    );

    return (
      <a
        tabIndex={0}
        href={item.url}
        target={item.target}
        className={classNames("p-ripple", {
          "router-link-active router-link-exact-active": active,
        })}
        onClick={(e) => {
          handleOnClickButton(item);
          if (item.command) {
            item.command({ item, originalEvent: e });
            e.preventDefault();
          }
        }}
      >
        <React.Fragment>
          <i className={item.icon} />
          <span>{item.label}</span>
          {toggleIcon}
          <Ripple />
        </React.Fragment>
      </a>
    );
  };

  const renderButtons = (item: MenuItem) => {
    const active = !item.url && checkIfButtonIsActive(item.id);
    const hasItems = !!item.items;
    return (
      <li
        key={item.id}
        className={classNames(item.className, {
          "active-menuitem": active,
        })}
      >
        {renderNavlink(item)}
        {hasItems && (
          <CSSTransition
            classNames="layout-submenu-wrapper"
            timeout={{ enter: 200, exit: 200 }}
            in={active}
            unmountOnExit
          >
            <Menu items={item.items as MenuItem[] | undefined} />
          </CSSTransition>
        )}
      </li>
    );
  };

  const renderCategory = (item: MenuItem) => {
    return (
      <li
        key={item.label}
        className={classNames(item.className, "layout-menuitem-category")}
      >
        <div className="layout-menuitem-root-text">{item.label}</div>
        <Menu items={item.items as MenuItem[] | undefined} />
      </li>
    );
  };

  const renderItems = () => {
    return (
      items &&
      items.map((item: MenuItem) =>
        item.id === MENU_ITEM_TYPE.CATEGORY
          ? renderCategory(item)
          : renderButtons(item)
      )
    );
  };

  return items ? <ul className={className}>{renderItems()}</ul> : null;
};

export default Menu;
