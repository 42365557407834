import React from "react";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Card
      className="w-full text-center"
      title="Erro 404"
      footer={<Button label="Voltar" onClick={() => navigate(-1)} />}
    >
      Página não encontrada!
    </Card>
  );
};

export default NotFound;
