import React, { useCallback, useEffect, useRef, useState } from "react";
import { useStore } from "src/providers/StoreProvider";
import Form from "src/components/Form";
import Button from "src/components/Button";
import { ProgressBar } from "primereact/progressbar";
import { BlockUI } from "primereact/blockui";
import Title from "src/components/Title";
import ListItem from "src/components/ListItem";
import classNames from "classnames";
import { ModulesProgress, ModuleProgressData, ModuleMessageData, DELAY_15_SECS } from "src/models/project.types";
import useModuleSetupManager, { MODULE_HANDLERS } from "src/hooks/useModuleSetupManager";

const Step6 = () => {
  const {
    projectContext: { project },
    toastContext: { showMessage },
  } = useStore();
  const intervalID = useRef<number>();
  const [modulesProgress, setModulesProgress] = useState<ModulesProgress>({});
  const [blockUI, setBlockUI] = useState<boolean>(false);

  const connectionMessage = ({ moduleId, message, online }: ModuleMessageData) => {
    window.setTimeout(() => {
      const modulesProgressTmp = { ...modulesProgress };
      if (online !== undefined) modulesProgressTmp[moduleId].data.online = online;
      setModulesProgress(modulesProgressTmp);
      showMessage({
        summary: message,
        severity: online ? "success" : "error",
        life: 3000,
      });
    }, 30);
  };

  const moduleSetupProgress = (moduleId: string, { progress, online, setupMode, error }: ModuleProgressData) => {
    const modulesProgressTmp = { ...modulesProgress };
    if (progress !== undefined) modulesProgressTmp[moduleId].data.progress = progress;
    if (online !== undefined) modulesProgressTmp[moduleId].data.online = online;
    if (setupMode !== undefined) modulesProgressTmp[moduleId].data.setupMode = setupMode;
    if (error !== undefined) modulesProgressTmp[moduleId].data.error = error;
    setModulesProgress(modulesProgressTmp);
  };

  const { startModuleSetup, startAllModulesSetup, setObserveModulesStatus } = useModuleSetupManager({
    connectionMessage,
    moduleSetupProgress,
  });

  // Ficar verificando se o modulo esta online
  useEffect(() => {
    setObserveModulesStatus(true);
  }, []);

  // atribuir os modulos que tem IDs completos ao modulesProgress que vai cuidar de renderizar o progesso do setup
  useEffect(() => {
    getDefaultModulesProgress();
  }, []);

  // verificar se todos os modulos sairam do setupMode para desbloquear a tela
  useEffect(() => {
    verifyModulesSetupMode();
  }, [modulesProgress]);

  const getDefaultModulesProgress = () => {
    const modulesProgressTmp: ModulesProgress = {};
    project.modules.forEach((module) => {
      if (module.id.length === 12)
        modulesProgressTmp[module.id] = {
          moduleName: module.name,
          data: { progress: 0, online: false, setupMode: false, error: false },
        };
    });
    setModulesProgress(modulesProgressTmp);
  };

  const verifyModulesSetupMode = () => {
    let hasModuleInSetupMode = false;
    for (const [moduleId, { data }] of Object.entries(modulesProgress)) {
      if (data.setupMode) {
        hasModuleInSetupMode = true;
        break;
      }
    }
    if (!hasModuleInSetupMode) {
      window.clearInterval(intervalID.current);
      setBlockUI(false);
      setObserveModulesStatus(true);
    }
  };

  const handleStartModuleSetup = (moduleId: string) => {
    startModuleSetup(moduleId);
    blockUiAndSetInterval();
  };

  const handleStartAllModulesSetup = () => {
    getDefaultModulesProgress();
    startAllModulesSetup();
    blockUiAndSetInterval();
  };

  const blockUiAndSetInterval = () => {
    setBlockUI(true);
    intervalID.current = window.setInterval(verifyModulesPacketReceived, DELAY_15_SECS);
  };

  const verifyModulesPacketReceived = () => {
    MODULE_HANDLERS.forEach((h) => {
      if (h.getSetupMode()) {
        if (!h.getPacketReceived()) h.setupEnd(true);
        h.verifyPackage();
      }
    });
  };

  const renderModules = useCallback((): Array<JSX.Element | null> => {
    return Object.entries(modulesProgress).map(([moduleId, { moduleName, data }]) => {
      return (
        <ListItem borderGreen={data.online} borderRed={!data.online}>
          <div className="w-full flex flex-wrap justify-content-between align-items-center">
            <b>
              {moduleName} - {moduleId}
            </b>

            <ProgressBar
              value={data.progress}
              className={classNames("h-1rem w-16rem md:w-4 my-1", {
                "ongoing-color": !data.error && data.progress && data.progress < 100,
                "success-color": !data.error && data.progress === 100,
                "error-color": data.error,
              })}
            />

            <Button
              label="Configurar"
              className="my-1"
              disabled={!data.error}
              size="small"
              onClick={() => handleStartModuleSetup(moduleId)}
            />
          </div>
        </ListItem>
      );
    });
  }, [modulesProgress]);

  const blockUITemplate = () => {
    return (
      <div className="text-center" style={{ marginBottom: "9rem" }}>
        <i className="pi pi-lock text-3xl mb-3" />
        <div className="text-1xl">Aguarde a configuração terminar</div>
      </div>
    );
  };

  return (
    <BlockUI blocked={blockUI} fullScreen template={blockUITemplate()}>
      <Form>
        <Title pageTitle text="Configurações" />

        <Button
          label="Iniciar configuração"
          containerClassName="px-2 mb-5"
          raised
          onClick={handleStartAllModulesSetup}
        />

        <div className="mx-2 mb-5 w-full">{renderModules()}</div>
      </Form>
    </BlockUI>
  );
};

export default Step6;
