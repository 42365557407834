import React from "react";
import Button from "src/components/Button";
import { useStore } from "src/providers/StoreProvider";

const MenuButton = () => {
  const {
    sidebarContext: { toggleSidebar },
  } = useStore();

  return (
    <Button
      icon="pi pi-bars"
      className="text-color"
      text
      rounded
      size="large"
      onClick={toggleSidebar}
    />
  );
};

export default MenuButton;
