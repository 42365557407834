import React from "react";
import { Routes, Route, Navigate, unstable_HistoryRouter as Router } from "react-router-dom";
import history from "./history";
import { PUBLIC_PATH, PRIVATE_PATH, getRoutesByRole } from "./index.routes";
import SignIn from "src/pages/SignIn";
import SignUp from "src/pages/SignUp";
import NotFound from "src/pages/NotFound";
import PrivateRoutes from "./PrivateRoutes";
import { useStore } from "src/providers/StoreProvider";
import Loading from "src/components/Loading";

const PublicRoutes = () => {
  const {
    authContext: { auth, userData, loadingAuth },
  } = useStore();

  return (
    <Router history={history}>
      <Routes>
        <Route path={PRIVATE_PATH.HOME} element={<PrivateRoutes />}>
          {getRoutesByRole(userData && userData.role).map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
          {userData && <Route path={PUBLIC_PATH.NOT_FOUND} element={<NotFound />} />}
        </Route>

        <Route path={PUBLIC_PATH.SIGN_IN} element={<SignIn />} />

        <Route path={PUBLIC_PATH.SIGN_UP} element={<SignUp />} />

        {!auth && (
          <Route
            path={PUBLIC_PATH.NOT_FOUND}
            element={loadingAuth ? <Loading centerOfScreen /> : <Navigate to={PUBLIC_PATH.SIGN_IN} />}
          />
        )}
      </Routes>
    </Router>
  );
};

export default PublicRoutes;
