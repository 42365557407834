import React, { useCallback, useEffect } from "react";
import { Dropdown, DropdownProps } from "primereact/dropdown";
import { InputText, InputTextProps } from "primereact/inputtext";
import { SelectItemOptionsType } from "primereact/selectitem";
import InputField from "src/components/InputField";
import { useStore } from "src/providers/StoreProvider";
import { PROJECT_TYPE } from "src/models/project.types";
import Form from "src/components/Form";
import Title from "src/components/Title";

const Step0 = () => {
  const {
    clientContext: { selectedClient, clients },
    projectContext: { project, setProjectData },
  } = useStore();

  const setType = (type: PROJECT_TYPE) => {
    const projectTmp = { ...project };
    projectTmp.type = type;
    if (type === PROJECT_TYPE.TEMPLATE) projectTmp.clientId = "";
    setProjectData(projectTmp, true);
  };

  const setClientId = (clientId: string) => {
    setProjectData({ ...project, clientId, type: PROJECT_TYPE.PROJECT }, true);
  };

  const setName = (name: string) => {
    setProjectData({ ...project, name }, true);
  };

  useEffect(() => {
    if (selectedClient) {
      setType(PROJECT_TYPE.PROJECT);
      setClientId(selectedClient.docId);
    }
  }, []);

  const typeOptions = useCallback(() => {
    return [
      { label: PROJECT_TYPE.PROJECT, value: PROJECT_TYPE.PROJECT },
      { label: PROJECT_TYPE.TEMPLATE, value: PROJECT_TYPE.TEMPLATE, disabled: true },
    ] as SelectItemOptionsType;
  }, []);

  const clientOptions = useCallback(() => {
    return clients.map((selectedClient) => ({
      label: selectedClient.name,
      value: selectedClient.docId,
    })) as SelectItemOptionsType;
  }, [clients]);

  return (
    <Form>
      <Title pageTitle text="Dados Básicos" />
      <InputField<DropdownProps>
        Input={Dropdown}
        inputProps={{
          value: project.type,
          options: typeOptions(),
          onChange: (event) => {
            const value = event.value as PROJECT_TYPE;
            setType(value);
          },
        }}
        id="type"
        label="Tipo"
        fieldClassName="col-12 md:col-4"
      />

      <InputField<InputTextProps>
        Input={InputText}
        inputProps={{
          value: project.name,
          onChange: (event) => {
            setName(event.target.value);
          },
        }}
        id="name"
        label="Nome"
        fieldClassName="col-12 md:col-4"
      />

      <InputField<DropdownProps>
        Input={Dropdown}
        inputProps={{
          value: project.clientId,
          options: clientOptions(),
          disabled: project.type === PROJECT_TYPE.TEMPLATE,
          onChange: (event) => {
            const value = event.value as string;
            setClientId(value);
          },
        }}
        id="selectedClient"
        label="Cliente"
        fieldClassName="col-12 md:col-4"
      />
    </Form>
  );
};

export default Step0;
