import { useEffect, useRef, useState } from "react";
import { Socket } from "socket.io-client";
import {
  Central,
  DELAY_15_SECS,
  KeyData,
  LoadData,
  Module,
  ModuleIotHandler,
  ModuleMessageData,
  ModuleProgressData,
  SceneData,
  StateData,
} from "src/models/project.types";
import { useStore } from "src/providers/StoreProvider";
import useAwsIoT from "./useAwsIoT";

export let MODULE_HANDLERS: ModuleIotHandler[] = [];

export interface ModuleSetupManagerOptions {
  connectionMessage?: (data: ModuleMessageData) => void;
  moduleSetupProgress?: (moduleId: string, data: ModuleProgressData) => void;
}

const useModuleSetupManager = (options?: ModuleSetupManagerOptions) => {
  const {
    projectContext: { project },
  } = useStore();

  const intervalID = useRef<number>();
  const [observeModulesStatus, setObserveModulesStatus] = useState<boolean>(false);
  const keysDataRef = useRef<KeyData[]>([]);
  const loadsDataRef = useRef<LoadData[]>([]);
  const scenesDataRef = useRef<SceneData[]>([]);
  const statesDataRef = useRef<StateData[]>([]);

  const { socket, socketConnected, socketDisconnect } = useAwsIoT();

  useEffect(() => {
    if (socketConnected) {
      MODULE_HANDLERS = [];
      project.modules.forEach((module) => {
        if (module.id.length === 12) addHandler(module.id);
      });
      return () => {
        MODULE_HANDLERS.forEach((h) => h.removeHandler());
        MODULE_HANDLERS = [];
        socketDisconnect();
      };
    }
  }, [socketConnected]);

  useEffect(() => {
    clearTheInterval();
    if (observeModulesStatus) {
      intervalID.current = window.setInterval(getModulesInfo, DELAY_15_SECS);
      return () => {
        clearTheInterval();
      };
    }
  }, [observeModulesStatus]);

  function getModulesInfo() {
    MODULE_HANDLERS.forEach((h) => h.getInfo());
  }

  function clearTheInterval() {
    window.clearInterval(intervalID.current);
  }

  /** ADD MODULE HANDLER */
  function addHandler(moduleId: string) {
    const newHandler = new ModuleIotHandler({
      moduleId,
      projectId: project.id,
      getSocket,
      getModuleIndex,
      getModuleData,
      getCentralData,
      getKeysData,
      getLoadsData,
      getScenesData,
      getStatesData,
      connectionMessage: options && options.connectionMessage,
      moduleSetupProgress: options && options.moduleSetupProgress,
    });
    MODULE_HANDLERS.push(newHandler);
  }

  /** UPDATE MODULE HANDLER */
  function updateHandler(moduleId: string) {
    const index = MODULE_HANDLERS.findIndex((h) => h.getModuleId() === moduleId);
    if (index === -1) return;
    MODULE_HANDLERS[index].setModuleId(moduleId);
  }

  /** REMOVE MODULE HANDLER */
  function removeHandler(moduleId: string) {
    const index = MODULE_HANDLERS.findIndex((h) => h.getModuleId() === moduleId);
    if (index === -1) return;
    MODULE_HANDLERS[index].removeHandler();
    MODULE_HANDLERS.splice(index, 1);
  }

  function startAllModulesSetup() {
    setObserveModulesStatus(false);
    generateSetupData();
    MODULE_HANDLERS.forEach((h) => h.setup());
  }

  function startModuleSetup(moduleId: string) {
    setObserveModulesStatus(false);
    generateSetupData();
    const handler = MODULE_HANDLERS.find((h) => h.getModuleId() === moduleId);
    if (handler) handler.setup();
  }

  function generateSetupData() {
    generateKeysArray();
    generateLoadsArray();
    generateScenesAndStatesArrays();
  }

  function generateKeysArray() {
    const { modules, inputDevices } = project;
    let keysData: KeyData[] = [];
    inputDevices.forEach((input) => {
      const moduleIndex = modules.findIndex((m) => m.name === input.module);
      if (moduleIndex !== -1)
        input.keys.forEach((key, index) =>
          keysData.push({
            moduleIndex,
            moduleId: modules[moduleIndex].id,
            deviceName: input.name,
            port: input.port,
            sequence: input.sequence,
            name: key.name,
            type: key.type,
            keyIndex: index,
          })
        );
    });
    keysDataRef.current = keysData;
  }

  function generateLoadsArray() {
    const { modules, outputDevices } = project;
    let loadsData: LoadData[] = [];
    outputDevices.forEach((output) => {
      const moduleIndex = modules.findIndex((m) => m.name === output.module);
      if (moduleIndex !== -1)
        output.loads.forEach((load) =>
          loadsData.push({
            moduleIndex,
            moduleId: modules[moduleIndex].id,
            slot: output.slot,
            type: output.type,
            name: load.name,
            sequence: load.sequence,
            value: load.value,
          })
        );
    });
    loadsDataRef.current = loadsData;
  }

  function generateScenesAndStatesArrays() {
    const { scenes } = project;
    let scenesData: SceneData[] = [];
    let statesData: StateData[] = [];
    scenes.forEach((scene) => {
      const keyData = keysDataRef.current.find((k) => k.deviceName === scene.deviceName && k.name === scene.keyName);
      if (keyData) {
        scenesData.push({
          moduleIndex: keyData.moduleIndex,
          moduleId: keyData.moduleId,
          deviceName: scene.deviceName,
          keyName: scene.keyName,
          id: scene.id,
          event: scene.event,
          time: scene.time,
          led: scene.led,
          func: scene.func,
          name: scene.name,
        });

        scene.states.forEach((state) =>
          statesData.push({
            moduleIndex: keyData.moduleIndex,
            moduleId: keyData.moduleId,
            sceneId: scene.id,
            stateId: state.id,
            name: state.name,
            type: state.type,
            value: state.value,
          })
        );
      }
    });
    scenesDataRef.current = scenesData;
    statesDataRef.current = statesData;
  }

  function getSocket(): Socket {
    return socket;
  }

  function getModuleIndex(moduleId: string): number {
    return project.modules.findIndex((m) => m.id === moduleId);
  }

  function getModuleData(moduleId: string): Module | undefined {
    return project.modules.find((m) => m.id === moduleId);
  }

  function getCentralData(): Central {
    return project.central;
  }

  function getKeysData(): KeyData[] {
    return keysDataRef.current;
  }

  function getLoadsData(): LoadData[] {
    return loadsDataRef.current;
  }

  function getScenesData(): SceneData[] {
    return scenesDataRef.current;
  }

  function getStatesData(): StateData[] {
    return statesDataRef.current;
  }

  return {
    addHandler,
    updateHandler,
    removeHandler,
    startModuleSetup,
    startAllModulesSetup,
    setObserveModulesStatus,
    generateKeysArray,
    generateLoadsArray,
  };
};

export default useModuleSetupManager;
