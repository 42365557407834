import { RefObject, useRef } from "react";
import { Toast, ToastMessage } from "primereact/toast";

export interface ToastContext {
  toast: RefObject<Toast>;
  showMessage: (message: ToastMessage) => void;
}

export const toastContextDefault: ToastContext = {
  toast: { current: null },
  showMessage: () => {},
};

export const useToastProvider = (): ToastContext => {
  const toast = useRef<Toast>(null);

  const showMessage = (message: ToastMessage) => {
    if (toast.current) toast.current.show(message);
  };

  return {
    toast,
    showMessage,
  };
};
