import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useStore } from "src/providers/StoreProvider";
import { getFirstPath, PRIVATE_PATH, PUBLIC_PATH } from "./index.routes";
import Home from "src/pages/Home";
import Loading from "src/components/Loading";
import { useEffect } from "react";

const PrivateRoutes = () => {
  const {
    authContext: { auth, loadingAuth, userData },
  } = useStore();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (userData && location.pathname === PRIVATE_PATH.HOME) {
      const firstPath = getFirstPath(userData.role);
      navigate(firstPath);
    }
  }, [userData]);

  if (loadingAuth) return <Loading centerOfScreen />;

  if (!auth) return <Navigate to={PUBLIC_PATH.SIGN_IN} />;

  return (
    <Home>
      <Outlet />
    </Home>
  );
};

export default PrivateRoutes;
