import React, { useState } from "react";
import { InputText, InputTextProps } from "primereact/inputtext";
import Button from "src/components/Button";
import InputField from "src/components/InputField";
import { Divider } from "primereact/divider";
import { useStore } from "src/providers/StoreProvider";
import Form from "src/components/Form";
import ListItem from "src/components/ListItem";
import Title from "src/components/Title";
import { InputDevice, OutputDevice } from "src/models/project.types";

const Step1 = () => {
  const {
    projectContext: { project, setProjectData },
  } = useStore();
  const [environmentName, setEnvironmentName] = useState<string>("");

  const onClickAddEnvironment = () => {
    const envTmp = environmentName.trim();
    if (envTmp) setProjectData({ ...project, environments: project.environments.concat(environmentName) }, true);
    setEnvironmentName("");
  };

  const deleteEnvironment = (index: number) => {
    const projectTmp = { ...project };
    const [environment] = projectTmp.environments.splice(index, 1);
    projectTmp.inputDevices = deleteEnvironmentFromInputDevices(environment);
    projectTmp.outputDevices = deleteEnvironmentFromOutputDevices(environment);
    setProjectData(projectTmp, true);
  };

  const deleteEnvironmentFromInputDevices = (environment: string): InputDevice[] => {
    return project.inputDevices.map((inputDevice) => {
      if (inputDevice.environment === environment) inputDevice.environment = "";

      inputDevice.keys = inputDevice.keys.map((key) => {
        if (key.environment === environment) key.environment = "";
        return key;
      });

      return inputDevice;
    });
  };

  const deleteEnvironmentFromOutputDevices = (environment: string): OutputDevice[] => {
    return project.outputDevices.map((outputDevice) => {
      outputDevice.loads = outputDevice.loads.map((load) => {
        if (load.environment === environment) load.environment = "";
        return load;
      });

      return outputDevice;
    });
  };

  const onChangeEnvName = (index: number, newEnv: string) => {
    const projectTmp = { ...project };
    const oldEnv = projectTmp.environments[index];
    projectTmp.environments[index] = newEnv;
    // Propagate env change
    projectTmp.inputDevices = projectTmp.inputDevices.map((input) => {
      if (input.environment === oldEnv) input.environment = newEnv;
      input.keys = input.keys.map((key) => {
        if (key.environment === oldEnv) key.environment = newEnv;
        return key;
      });
      return input;
    });
    projectTmp.outputDevices = projectTmp.outputDevices.map((output) => {
      output.loads = output.loads.map((load) => {
        if (load.environment === oldEnv) load.environment = newEnv;
        return load;
      });
      return output;
    });
    setProjectData(projectTmp, true);
  };

  const renderEnvironments = (): JSX.Element[] => {
    return project.environments.map((env, index) => (
      <ListItem border key={index}>
        <div className="w-full flex flex-wrap justify-content-between align-items-center">
          <InputField<InputTextProps>
            Input={InputText}
            inputProps={{
              value: env,
              onChange: (e) => onChangeEnvName(index, e.target.value),
            }}
            field={false}
            noMargin
            error={!env}
            fieldClassName="w-16rem md:w-4"
          />
          <Button
            label="Excluir"
            size="small"
            outlined
            danger
            className="z-1"
            onClick={() => deleteEnvironment(index)}
          />
        </div>
      </ListItem>
    ));
  };

  return (
    <Form>
      <Title pageTitle text="Ambientes" />
      <InputField<InputTextProps>
        Input={InputText}
        inputProps={{
          value: environmentName,
          tooltip: "Pressione a tecla Enter para adicionar um novo ambiente",
          tooltipOptions: { position: "top" },
          onChange: (event) => {
            setEnvironmentName(event.target.value);
          },
          onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.code === "Enter") onClickAddEnvironment();
          },
        }}
        id="environmentName"
        label="Nome do Ambiente"
        fieldClassName="col md:col-4"
      />

      <Button
        label="Adicionar"
        icon="pi pi-fw pi-plus"
        containerClassName="px-2 flex-initial flex align-items-end"
        className="mb-5"
        raised
        onClick={onClickAddEnvironment}
      />

      <Divider align="left">
        <b>{`Ambientes Adicionados: ${project.environments.length}`}</b>
      </Divider>

      <div className="mx-2 mb-5 w-full">{renderEnvironments()}</div>
    </Form>
  );
};

export default Step1;
