import { useEffect, useState } from "react";

export interface ThemeContext {
  theme: string;
  onClickTheme: () => void;
}

export type ThemeMode = "dark" | "light";

const defaultTheme = "light";

export const themeContextDefault: ThemeContext = {
  theme: defaultTheme,
  onClickTheme: () => {},
};

export const useThemeProvider = (): ThemeContext => {
  const [theme, setTheme] = useState<ThemeMode>(defaultTheme);

  useEffect(() => {
    const selectedTheme = localStorage.getItem("theme");
    if (selectedTheme) setTheme(selectedTheme as ThemeMode);
  }, []);

  useEffect(() => {
    const themeElement = document.getElementById("theme-link");
    const themeHref = `assets/themes/md-${theme}-indigo/theme.css`;
    if (themeElement) themeElement.setAttribute("href", themeHref);
  }, [theme]);

  const onClickTheme = () => {
    const selectedTheme = theme === "light" ? "dark" : "light";
    setTheme(selectedTheme);
    localStorage.setItem("theme", selectedTheme);
  };

  return {
    theme,
    onClickTheme,
  };
};
