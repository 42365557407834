import React from "react";
import classNames from "classnames";

interface ListItemProps {
  children: React.ReactNode;
  border?: boolean;
  borderGreen?: boolean;
  borderRed?: boolean;
  noPadding?: boolean;
  containerClassName?: string;
  headerProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
}

const ListItem = (props: ListItemProps) => {
  const { children, border, borderGreen, borderRed, containerClassName, headerProps, noPadding } = props;
  return (
    <div
      className={classNames("p-accordion list-item", containerClassName, {
        "list-item-border": border,
        "accordion-header-module-online": borderGreen,
        "accordion-header-module-offline": borderRed,
        "p-0": noPadding,
      })}
    >
      <div className="p-accordion-header">
        <div
          {...headerProps}
          className={classNames("p-accordion-header-link", {
            "p-0": noPadding,
          })}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default ListItem;
