import React, { useEffect, useRef, useState } from "react";
import { Dropdown, DropdownChangeEvent, DropdownProps } from "primereact/dropdown";
import { InputText, InputTextProps } from "primereact/inputtext";
import Form from "src/components/Form";
import InputField from "src/components/InputField";
import Title from "src/components/Title";
import {
  InputDevice,
  Key,
  ledEvents,
  SCENE_LED_EVENT,
  Load,
  maxDelay,
  minDelay,
  minTime,
  OUTPUT_DEVICE_TYPE,
  SCENE_EVENT,
  sceneEvents,
  State,
  INPUT_DEVICE_TYPE,
  Scene,
  minDecimalStep,
  DIMMER_ALL_VALUES,
  RELE_ALL_VALUES,
  MOTOR_ALL_VALUES,
  getLoadValue,
  SCENE_FUNCTION_EVENT,
  functionEvents,
  KEY_TYPE,
} from "src/models/project.types";
import { useStore } from "src/providers/StoreProvider";
import ListItem from "src/components/ListItem";
import Button from "src/components/Button";
import { InputNumber, InputNumberChangeEvent, InputNumberProps } from "primereact/inputnumber";
import { OverlayPanel } from "primereact/overlaypanel";
import { SelectItem } from "primereact/selectitem";
import { createId, roundWithTwoDecimalPlaces } from "src/utils";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import classNames from "classnames";
import { Divider } from "primereact/divider";

const defaultEnvLabel = "Selecionar Todos";
const defaultEnvValue = "all";

const Step5 = () => {
  const {
    projectContext: { project, setProjectData },
    themeContext: { theme },
    toastContext: { showMessage },
  } = useStore();
  const op = useRef<OverlayPanel>(null);
  const [deviceName, setDeviceName] = useState<string>("");
  const [keyName, setKeyName] = useState<string>("");
  const [environment, setEnvironment] = useState<string>(defaultEnvValue);
  const [sceneEvent, setSceneEvent] = useState<SCENE_EVENT | null>(null);
  const [time, setTime] = useState<number | null>(null);
  const [led, setLed] = useState<SCENE_LED_EVENT>(SCENE_LED_EVENT.FOLLOW);
  const [func, setFunc] = useState<SCENE_FUNCTION_EVENT>(SCENE_FUNCTION_EVENT.ON);
  const [sceneName, setSceneName] = useState<string>("");
  const [states, setStates] = useState<State[]>([]);
  const [device, setDevice] = useState<InputDevice | null>(null);
  const [key, setKey] = useState<Key | null>(null);
  const [disabledConfigInputs, setDisabledConfigInputs] = useState<boolean>(false);
  const [sceneWasJustSelected, setSceneWasJustSelected] = useState<boolean>(false);
  const [selectedSceneId, setSelectedSceneId] = useState<string>("");

  useEffect(() => {
    setDisabledConfigInputs(!(deviceName && keyName));
  }, [deviceName, keyName]);

  //** On Change Macros update project scenes */
  useEffect(() => {
    addOrUpdateScene();
  }, [states]);

  const verifyMacroHasLoad = (): boolean => {
    return !!states.find((state) => state.type !== "delay");
  };

  const addOrUpdateScene = () => {
    const hasLoad = verifyMacroHasLoad();
    const projectTmp = { ...project };

    if (!selectedSceneId && hasLoad && sceneEvent) {
      //** add nova cena se tiver pelo menos uma carga nos estados */
      const id = createId(5);
      const name = sceneName ? sceneName : `${keyName} - ${sceneEvent}`;
      const newScene: Scene = {
        id,
        deviceName,
        keyName,
        name,
        event: sceneEvent,
        time,
        led,
        func,
        states,
      };
      setSelectedSceneId(id);
      setSceneName(name);
      setProjectData({ ...project, scenes: project.scenes.concat(newScene) }, true);
      return;
    }

    if (selectedSceneId && hasLoad) {
      //** atualiza estados da cena */
      const index = getSceneIndex();
      if (index >= 0) projectTmp.scenes[index].states = states;
      setProjectData(projectTmp, !sceneWasJustSelected);
      setSceneWasJustSelected(false);
      return;
    }

    if (selectedSceneId && !hasLoad) {
      //** remove cena se nao tiver carga nos estados */
      const index = getSceneIndex();
      if (index >= 0) projectTmp.scenes.splice(index, 1);
      setSelectedSceneId("");
      setProjectData(projectTmp, true);
      return;
    }
  };

  const handleSelectedScene = (scene: Scene) => {
    const deviceTmp = project.inputDevices.find((d) => d.name === scene.deviceName);
    const keyTmp = deviceTmp && deviceTmp.keys.find((k) => k.name === scene.keyName);
    if (!deviceTmp || !keyTmp) return;
    setDevice(deviceTmp);
    setKey(keyTmp);
    setDeviceName(scene.deviceName);
    setKeyName(scene.keyName);
    setSelectedSceneId(scene.id);
    setSceneEvent(scene.event);
    setTime(scene.time);
    setLed(scene.led);
    setSceneName(scene.name);
    setFunc(scene.func);
    setStates(scene.states);
    setSceneWasJustSelected(true);
  };

  const getScene = (deviceNameTmp: string, keyNameTmp: string, sceneEventTmp: string) => {
    const scene = project.scenes.find(
      (s) => s.deviceName === deviceNameTmp && s.keyName === keyNameTmp && s.event === sceneEventTmp
    );
    scene ? handleSelectedScene(scene) : clearInputFields();
  };

  const getSceneIndex = (): number => {
    const index = project.scenes.findIndex((s) => s.id === selectedSceneId);
    return index;
  };

  const clearInputFields = () => {
    setSelectedSceneId("");
    setTime(null);
    setLed(SCENE_LED_EVENT.FOLLOW);
    setSceneName("");
    setFunc(SCENE_FUNCTION_EVENT.ON);
    setStates([]);
  };

  const onChangeDevice = (e: DropdownChangeEvent) => {
    const deviceNameTmp = String(e.value);
    const deviceTmp = project.inputDevices.find((d) => d.name === deviceNameTmp);
    const keyTmp = deviceTmp && deviceTmp.keys[0];
    if (!deviceTmp || !keyTmp) return;
    setDevice(deviceTmp);
    setKey(keyTmp);
    setDeviceName(deviceNameTmp);
    setKeyName(keyTmp.name);
    setSceneEvent(SCENE_EVENT.ONE);
    getScene(deviceNameTmp, keyTmp.name, SCENE_EVENT.ONE);
  };

  const onChangeKey = (e: DropdownChangeEvent) => {
    const keyNameTmp = String(e.value);
    const keyTmp = device && device.keys.find((k) => k.name === keyNameTmp);
    if (!keyTmp) return;
    setKey(keyTmp);
    setKeyName(keyNameTmp);
    setSceneEvent(SCENE_EVENT.ONE);
    getScene(deviceName, keyNameTmp, SCENE_EVENT.ONE);
  };

  const onChangeSceneEvent = (e: DropdownChangeEvent) => {
    const sceneEventTmp = e.value as SCENE_EVENT;
    setSceneEvent(sceneEventTmp);
    getScene(deviceName, keyName, sceneEventTmp);
  };

  const onChangeTime = (e: InputNumberChangeEvent) => {
    let num = e.value;
    if (num) {
      num < minTime && (num = minTime);
      const index = getSceneIndex();
      if (index >= 0) {
        const projectTmp = { ...project };
        projectTmp.scenes[index].time = num;
        setProjectData(projectTmp, true);
      }
      setTime(num);
    }
  };

  const onChangeLed = (e: DropdownChangeEvent) => {
    const value = e.value;
    const index = getSceneIndex();
    if (index >= 0) {
      const projectTmp = { ...project };
      projectTmp.scenes[index].led = value as SCENE_LED_EVENT;
      setProjectData(projectTmp, true);
    }
    setLed(value);
  };

  const onChangeSceneName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const index = getSceneIndex();
    if (index >= 0) {
      const projectTmp = { ...project };
      projectTmp.scenes[index].name = value;
      setProjectData(projectTmp, true);
    }
    setSceneName(value);
  };

  const onChangeFunc = (e: DropdownChangeEvent) => {
    const value = e.value;
    const index = getSceneIndex();
    if (index >= 0) {
      const projectTmp = { ...project };
      projectTmp.scenes[index].func = value as SCENE_FUNCTION_EVENT;
      setProjectData(projectTmp, true);
    }
    setFunc(value);
  };

  const onChangeStateDelay = (e: InputNumberChangeEvent, stateId: string) => {
    let num = e.value;
    if (num !== null) {
      num = roundWithTwoDecimalPlaces(num);
      num < minDelay && (num = minDelay);
      num > maxDelay && (num = maxDelay);
      setStateValueField(stateId, String(num));
    }
  };

  const onChangeStateValue = (e: DropdownChangeEvent, stateId: string) => {
    setStateValueField(stateId, e.value);
  };

  const sceneEventsOptions = () => {
    if (key && key.type === KEY_TYPE.TRANSITION) return sceneEvents.filter((f) => f === SCENE_EVENT.TRANSITION);
    return sceneEvents.filter((f) => f !== SCENE_EVENT.TRANSITION);
  };

  const functionEventsOptions = () => {
    if (key && key.type === KEY_TYPE.TRANSITION) return functionEvents;
    return functionEvents.filter((f) => f !== SCENE_FUNCTION_EVENT.ON_OFF);
  };

  /** Disabled if input type !== keypad */
  const handleLedInputDisabled = (): boolean => {
    if (!sceneEvent) return true;
    if (device) return device.type !== INPUT_DEVICE_TYPE.KEYPAD;
    return true;
  };

  const renderLoads = (): JSX.Element[] => {
    const elements: JSX.Element[] = [];
    project.outputDevices.forEach((device) => {
      device.loads.forEach((load) => {
        if (environment === defaultEnvValue || environment === load.environment)
          elements.push(
            <ListItem
              containerClassName="mb-0"
              headerProps={{
                onClick: () => addLoadToMacro(load, device.type),
              }}
              children={<b>{load.name}</b>}
            />
          );
      });
    });
    return elements;
  };

  const environmentOptions = (): SelectItem[] => {
    return [
      { label: defaultEnvLabel, value: defaultEnvValue },
      ...project.environments.map((env) => ({ label: env, value: env })),
    ];
  };

  const showDelayErrorMsg = () => {
    showMessage({
      summary: "Não pode inserir delays consecutivos",
      severity: "error",
    });
  };

  const showLoadErrorMsg = () => {
    showMessage({
      summary: "Não pode inserir cargas iguais abaixo do mesmo delay",
      severity: "error",
    });
  };

  const addDelayToMacro = () => {
    const length = states.length;
    if (!!length && states[length - 1].type === "delay") return showDelayErrorMsg();
    setStates([
      ...states,
      {
        id: createId(5),
        name: "Delay",
        value: "0.1",
        type: "delay",
      },
    ]);
    showMessage({
      summary: "Delay adicionado!",
      severity: "success",
    });
  };

  const addLoadToMacro = ({ name }: Load, type: OUTPUT_DEVICE_TYPE) => {
    // Verify if already have this load below last delay
    for (let i = states.length - 1; i >= 0; i--) {
      if (states[i].type === "delay") break;
      if (states[i].name === name && states[i].type === type) return showLoadErrorMsg();
    }
    setStates([...states, { id: createId(5), name, value: getLoadValue(type), type }]);
    showMessage({
      summary: `${name} adicionado!`,
      severity: "success",
    });
  };

  const setStateValueField = (id: string, value: string) => {
    const statesTmp = states.map((state) => {
      if (state.id === id) state.value = value;
      return state;
    });
    setStates(statesTmp);
  };

  const handleOnDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) return;
    const statesTmp = [...states];
    const [movedItem] = statesTmp.splice(source.index, 1);
    statesTmp.splice(destination.index, 0, movedItem);
    const allowedMoveState = allowMoveState(statesTmp);
    if (allowedMoveState) setStates(statesTmp);
  };

  const onDeleteState = (id: string) => {
    const statesTmp = states.filter((state) => state.id !== id);
    setStates(statesTmp);
  };

  const clearMacros = () => {
    setStates([]);
  };

  /**
   * @returns True if move state is allowed
   */
  const allowMoveState = (statesTmp: State[]): boolean => {
    let allowedMoveDelay = true;
    let allowedMoveLoad = true;
    allowedMoveDelay = allowMoveDelay(statesTmp);
    if (!allowedMoveDelay) showDelayErrorMsg();
    allowedMoveLoad = allowMoveLoad(statesTmp);
    if (!allowedMoveLoad) window.setTimeout(() => showLoadErrorMsg(), 50);
    return allowedMoveDelay && allowedMoveLoad;
  };

  /**
   * @returns True if move delay is allowed
   */
  const allowMoveDelay = (statesTmp: State[]): boolean => {
    let prevState = statesTmp[0];
    for (let i = 1; i < statesTmp.length; i++) {
      let nextState = statesTmp[i];
      if (prevState.type === "delay" && nextState.type === "delay") return false;
      prevState = nextState;
    }
    return true;
  };

  /**
   * @returns True if move load is allowed
   */
  const allowMoveLoad = (statesTmp: State[]): boolean => {
    let auxArr: State[] = [];
    for (let i = 0; i < statesTmp.length; i++) {
      const state = statesTmp[i];
      if (state.type === "delay") auxArr = [];
      else {
        const hasState = auxArr.find((m) => m.name === state.name && m.type === state.type);
        if (hasState) return false;
        else auxArr.push(state);
      }
    }
    return true;
  };

  const valueOptions = (type: OUTPUT_DEVICE_TYPE): string[] => {
    if (type === OUTPUT_DEVICE_TYPE.DIMMER) return DIMMER_ALL_VALUES;
    if (type === OUTPUT_DEVICE_TYPE.RELE) return RELE_ALL_VALUES;
    return MOTOR_ALL_VALUES;
  };

  const droppableClassname = (isDraggingOver: boolean) => {
    return classNames("border-round", {
      "dragging-over-light": theme === "light" && isDraggingOver,
      "dragging-over-dark": theme === "dark" && isDraggingOver,
    });
  };

  const draggableClassname = (isDragging: boolean) => {
    return classNames("border-round", {
      "dragging-light": theme === "light" && isDragging,
      "dragging-dark": theme === "dark" && isDragging,
    });
  };

  const renderMacroHeader = (): JSX.Element => {
    return (
      <Form className="p-2 align-items-center justify-content-between">
        <div className="flex align-items-center">
          <Title text={`Macro: ${states.length} ${states.length === 1 ? "estado" : "estados"}`} className="mb-0 mr-3" />
          <Button
            label="Selecionar Estados"
            disabled={!sceneEvent}
            className="w-10rem"
            raised
            size="small"
            onClick={(e) => op.current && op.current.toggle(e)}
          />
          <OverlayPanel
            ref={op}
            showCloseIcon
            style={{
              minWidth: 350,
              height: 290,
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <div className="flex mb-3">
              <InputField<DropdownProps>
                Input={Dropdown}
                inputProps={{
                  options: environmentOptions(),
                  value: environment,
                  onChange: (e) => setEnvironment(e.target.value),
                }}
                field={false}
                noMargin
                id="envFilter"
                label="Ambiente"
                fieldClassName="flex-grow-1"
              />
              <Button
                label="Delay"
                containerClassName="flex-initial flex align-items-end ml-3"
                raised
                onClick={addDelayToMacro}
              />
            </div>
            {renderLoads()}
          </OverlayPanel>
        </div>
        <Button
          label="Limpar Macro"
          containerClassName="mr-2"
          danger
          raised
          size="small"
          disabled={disabledConfigInputs}
          onClick={clearMacros}
        />
        <Divider />
      </Form>
    );
  };

  const renderStates = (): JSX.Element[] => {
    return states.map((state, index) => (
      <Draggable key={state.id} draggableId={state.id} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            className={draggableClassname(snapshot.isDragging)}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <Form className="align-items-center justify-content-between pl-2 py-1">
              <b className="col-6 md:col-8">{state.name}</b>
              <div className="col-6 md:col-4 flex align-items-center justify-content-end">
                {state.type === "delay" ? (
                  <InputField<InputNumberProps>
                    Input={InputNumber}
                    inputProps={{
                      mode: "decimal",
                      step: minDecimalStep,
                      min: minDelay,
                      max: maxDelay,
                      minFractionDigits: 1,
                      maxFractionDigits: 1,
                      value: Number(state.value),
                      showButtons: true,
                      onChange: (e) => onChangeStateDelay(e, state.id),
                    }}
                    field={false}
                    noMargin
                    id={state.id}
                    label=""
                    fieldClassName="state-value"
                  />
                ) : (
                  <InputField<DropdownProps>
                    Input={Dropdown}
                    inputProps={{
                      value: state.value,
                      options: valueOptions(state.type as OUTPUT_DEVICE_TYPE),
                      onChange: (e) => onChangeStateValue(e, state.id),
                    }}
                    field={false}
                    noMargin
                    id={state.id}
                    label=""
                    fieldClassName="state-value"
                  />
                )}

                <Button
                  danger
                  text
                  rounded
                  tooltip="Excluir"
                  tooltipOptions={{ position: "top" }}
                  size="small"
                  icon="pi pi-trash"
                  onClick={() => onDeleteState(state.id)}
                />
              </div>
            </Form>
          </div>
        )}
      </Draggable>
    ));
  };

  const deleteScene = (sceneId: string) => {
    const scenes = project.scenes.filter((scene) => scene.id !== sceneId);
    if (sceneId === selectedSceneId) {
      setDeviceName("");
      setKeyName("");
      setSceneEvent(null);
      clearInputFields();
    }
    setProjectData({ ...project, scenes }, true);
  };

  const sceneTemplate = (scene: Scene) => {
    return (
      <div className="w-full flex align-items-center">
        <div className="font-medium flex-grow-1 mr-4 p-3 pr-0" onClick={() => handleSelectedScene(scene)}>
          {scene.name}
        </div>
        <Button
          label="Excluir"
          size="small"
          outlined
          danger
          containerClassName="flex-grow-0 p-3 pl-0"
          onClick={() => deleteScene(scene.id)}
        />
      </div>
    );
  };

  const renderScenes = (): JSX.Element[] => {
    return project.scenes.map((scene) => (
      <ListItem
        border={scene.id !== selectedSceneId}
        borderGreen={scene.id === selectedSceneId}
        noPadding
        children={sceneTemplate(scene)}
      />
    ));
  };

  return (
    <Form>
      <Title pageTitle text="Cenas" />
      <Title text="Entradas" className="mt-0" />
      <InputField<DropdownProps>
        Input={Dropdown}
        inputProps={{
          value: deviceName,
          options: project.inputDevices,
          optionLabel: "name",
          optionValue: "name",
          onChange: onChangeDevice,
        }}
        id="device"
        label="Dispositivo"
        fieldClassName="col-6"
      />
      <InputField<DropdownProps>
        Input={Dropdown}
        inputProps={{
          value: keyName,
          options: device ? device.keys : [],
          optionLabel: "name",
          optionValue: "name",
          onChange: onChangeKey,
        }}
        id="key"
        label="Entrada"
        fieldClassName="col-6"
      />
      <InputField<InputTextProps>
        Input={InputText}
        inputProps={{
          value: device ? device.module : "",
          disabled: true,
        }}
        id="module"
        label="Módulo"
        fieldClassName="col-6 md:col-4"
      />
      <InputField<InputTextProps>
        Input={InputText}
        inputProps={{
          value: key ? key.type : "",
          disabled: true,
        }}
        id="type"
        label="Tipo"
        fieldClassName="col-6 md:col-4"
      />
      <InputField<InputTextProps>
        Input={InputText}
        inputProps={{
          value: key ? key.environment : "",
          disabled: true,
        }}
        id="env"
        label="Ambiente"
        fieldClassName="col-6 md:col-4"
      />

      <Title text="Configurar Cenas" className="mt-0" />
      <InputField<DropdownProps>
        Input={Dropdown}
        inputProps={{
          options: sceneEventsOptions(),
          value: sceneEvent,
          disabled: disabledConfigInputs,
          onChange: onChangeSceneEvent,
        }}
        id="event"
        label="Tipo de Evento"
        fieldClassName="col-6"
      />
      <InputField<InputNumberProps>
        Input={InputNumber}
        inputProps={{
          step: minDecimalStep,
          min: minTime,
          value: time,
          showButtons: true,
          disabled: sceneEvent !== SCENE_EVENT.PRESS,
          onChange: onChangeTime,
        }}
        id="time"
        label="Tempo"
        fieldClassName="px-2 w-9rem"
      />
      <InputField<DropdownProps>
        Input={Dropdown}
        inputProps={{
          options: ledEvents,
          value: led,
          disabled: handleLedInputDisabled(),
          onChange: onChangeLed,
        }}
        id="led"
        label="Led Keypad"
        fieldClassName="col"
      />
      <InputField<InputTextProps>
        Input={InputText}
        inputProps={{
          value: sceneName,
          disabled: !sceneEvent,
          onChange: onChangeSceneName,
        }}
        id="name"
        label="Nome da Cena"
        fieldClassName="col-6"
      />
      <InputField<DropdownProps>
        Input={Dropdown}
        inputProps={{
          options: functionEventsOptions(),
          value: func,
          disabled: !sceneEvent,
          onChange: onChangeFunc,
        }}
        id="func"
        label="Função"
        fieldClassName="col-6"
      />

      <div className="mx-2 mb-5 p-2 w-full list-item-border border-round macro">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          {renderMacroHeader()}
          <Droppable droppableId="droppable-1">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                className={droppableClassname(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                {renderStates()}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <Divider align="left">
        <b>{`Cenas Adicionadas: ${project.scenes.length}`}</b>
      </Divider>

      <div className="mx-2 mb-5 w-full">{renderScenes()}</div>
    </Form>
  );
};

export default Step5;
