import React from "react";
import { Dialog, DialogProps } from "primereact/dialog";
import Button, { ButtonProps } from "../Button";

interface DialogOptionsProps extends DialogProps {
  cancelButtonProps: ButtonProps;
  confirmButtonProps: ButtonProps;
}

export default function DialogOptions(props: DialogOptionsProps) {
  const { cancelButtonProps, confirmButtonProps, ...dialogProps } = props;

  const footerContent = (
    <div className="w-full flex flex-wrap justify-content-end">
      <Button {...cancelButtonProps} />
      <Button {...confirmButtonProps} />
    </div>
  );

  return <Dialog {...dialogProps} footer={footerContent} />;
}
