// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, enableIndexedDbPersistence } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBzT7907itDxeEKkxMRaLxSjiaf3bze6-8",
  authDomain: "home-builder-807e2.firebaseapp.com",
  projectId: "home-builder-807e2",
  storageBucket: "home-builder-807e2.appspot.com",
  messagingSenderId: "1096453770189",
  appId: "1:1096453770189:web:df7a28cdcd724d4cab9284",
  measurementId: "G-Q2JL8CTD5C"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

enableIndexedDbPersistence(db)
  .then(() => {
    console.info("enableIndexedDbPersistence Enabled Sucessfully!");
  })
  .catch((err) => {
    console.error("enableIndexedDbPersistence Error:", err);
    if (err.code === "failed-precondition")
      console.error(
        "Multiple tabs open, persistence can only be enabled in one tab at a a time"
      );
    else if (err.code === "unimplemented")
      console.error(
        "The current browser does not support all of the features required to enable persistence"
      );
  });
// Subsequent queries will use persistence, if it was enabled successfully
