import React, { useCallback, useState } from "react";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useStore } from "src/providers/StoreProvider";
import { useNavigate } from "react-router-dom";
import { SplitButton } from "primereact/splitbutton";
import { PRIVATE_PATH } from "src/routes/index.routes";
import { ConfirmDialog } from "primereact/confirmdialog";
import { getDate, getDateMillis } from "src/utils";
import ProjectService from "src/service/ProjectService";

interface ProjectDataTable {
  id: string;
  name: string;
  clientName: string;
  createdAt: number;
  updatedAt: number;
}

const ListProjects = () => {
  const {
    clientContext: { clients },
    projectContext: { projects, setProjectData },
  } = useStore();
  const navigate = useNavigate();
  const [selectedProjectDataTable, setSelectedProjectDataTable] = useState<ProjectDataTable | null>(null);
  const [visible, setVisible] = useState<boolean>(false);

  const handleProjects = useCallback((): ProjectDataTable[] => {
    return projects.map<ProjectDataTable>((project) => {
      const client = clients.find((c) => c.docId === project.clientId);
      return {
        id: project.id,
        name: project.name,
        clientName: client ? client.name : "",
        createdAt: getDateMillis(project.createdAt),
        updatedAt: getDateMillis(project.updatedAt),
      };
    });
  }, [projects, clients]);

  const onSelectedProject = useCallback(
    (data: ProjectDataTable, path: PRIVATE_PATH) => {
      const projectTmp = projects.find((c) => c.id === data.id);
      if (projectTmp) {
        setProjectData({ ...projectTmp }, false);
        navigate(path);
      }
    },
    [projects]
  );

  const onClickDeleteProject = (data: ProjectDataTable) => {
    setSelectedProjectDataTable(data);
    setVisible(true);
  };

  const onAcceptDeleteProject = async () => {
    if (!selectedProjectDataTable) return;
    await ProjectService.deleteProject(selectedProjectDataTable.id);
  };

  const editButtonBody = (data: ProjectDataTable): JSX.Element => {
    const items = [
      {
        label: "Excluir",
        icon: "pi pi-trash",
        command: () => onClickDeleteProject(data),
      },
    ];
    return (
      <SplitButton
        label="Editar"
        model={items}
        className="p-button-sm p-button-outlined"
        menuButtonClassName="w-auto px-2"
        onClick={() => onSelectedProject(data, PRIVATE_PATH.PROJECTS_CREATE)}
      />
    );
  };

  const dateBody = (millis: number): JSX.Element => {
    const localeDate = getDate(millis);
    return <span>{localeDate}</span>;
  };

  return (
    <Card title="Listar Projetos">
      <DataTable
        value={handleProjects()}
        responsiveLayout="scroll"
        stripedRows
        paginator
        removableSort
        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="{first} - {last} de {totalRecords}"
        rows={10}
        rowsPerPageOptions={[5, 10, 20, 30, 40, 50]}
      >
        <Column header="Ações" body={editButtonBody}></Column>
        <Column field="name" header="Nome" sortable></Column>
        <Column field="clientName" header="Cliente" sortable></Column>
        <Column
          field="createdAt"
          header="Data de Cadastro"
          sortable
          body={(data: ProjectDataTable) => dateBody(data.createdAt)}
        ></Column>
        <Column
          field="updatedAt"
          header="Atualizado em"
          sortable
          body={(data: ProjectDataTable) => dateBody(data.updatedAt)}
        ></Column>
      </DataTable>

      {selectedProjectDataTable && (
        <ConfirmDialog
          visible={visible}
          message={
            <span>
              {`Deseja excluir o projeto `}
              <b>{selectedProjectDataTable.name}</b>
              {"?"}
            </span>
          }
          header="Excluir Projeto"
          icon="pi pi-exclamation-triangle"
          acceptLabel="Sim"
          rejectLabel="Não"
          accept={onAcceptDeleteProject}
          reject={() => setVisible(false)}
          onHide={() => setVisible(false)}
        />
      )}
    </Card>
  );
};

export default ListProjects;
