import React from "react";
import { Button as PrimeButton, ButtonProps as PrimeButtonProps } from "primereact/button";
import classNames from "classnames";
import { inputFieldHeight } from "src/utils";

export interface ButtonProps extends PrimeButtonProps {
  secondary?: boolean;
  success?: boolean;
  info?: boolean;
  warning?: boolean;
  help?: boolean;
  danger?: boolean;
  link?: boolean;
  rounded?: boolean;
  raised?: boolean;
  text?: boolean;
  plain?: boolean;
  outlined?: boolean;
  full?: boolean;
  size?: "small" | "large";
  containerClassName?: string;
}

const Button = (props: ButtonProps) => {
  const {
    secondary,
    success,
    info,
    warning,
    help,
    danger,
    link,
    rounded,
    raised,
    text,
    plain,
    outlined,
    full,
    size = "normal",
    containerClassName,
    className,
    ...buttonProps
  } = props;

  return (
    <div className={containerClassName}>
      <PrimeButton
        {...buttonProps}
        className={classNames(className, {
          "p-button-secondary": secondary,
          "p-button-success": success,
          "p-button-info": info,
          "p-button-warning": warning,
          "p-button-help": help,
          "p-button-danger": danger,
          "p-button-link": link,
          "p-button-rounded": rounded,
          "p-button-raised": raised,
          "p-button-text": text,
          "p-button-plain": plain,
          "p-button-outlined": outlined,
          "w-full": full,
          "p-button-sm": size === "small",
          "p-button-lg": size === "large",
        })}
        style={{
          ...buttonProps.style,
          height: size === "normal" ? inputFieldHeight : undefined,
        }}
      />
    </div>
  );
};

export default Button;
