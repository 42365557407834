import { Socket } from "socket.io-client";
import { iot_publish, iot_subscribe, iot_unsubscribe } from "src/hooks/useAwsIoT";
import { createId } from "src/utils";
import { Ack, Payload, Topics } from "./types";

export interface Project {
  id: string;
  type: PROJECT_TYPE;
  creatorId: string;
  clientId: string;
  name: string;
  central: Central;
  environments: string[];
  modules: Module[];
  inputDevices: InputDevice[];
  outputDevices: OutputDevice[];
  scenes: Scene[];
  createdAt: string;
  updatedAt: string;
  deleted?: boolean;
}

export enum PROJECT_TYPE {
  PROJECT = "Projeto",
  TEMPLATE = "Template",
}
export const DEFAULT_PORT_NUMBER = 5000;
export const PORT_MAX_NUMBER = 65535;
export const DELAY_5_SECS = 5000;
export const DELAY_15_SECS = 15000;

export interface Central {
  enabled: boolean;
  port: number;
}

export enum MODULE_CONNECTION {
  DHCP = "DHCP",
  IP = "IP estático",
}

export interface Module {
  id: string;
  name: string;
  connection: MODULE_CONNECTION;
  ip: string;
  gateway: string;
  dns: string;
  subnet: string;
}

export const MODULE_MAX_KEYS_NUM = 128;
export const MODULE_PORT_MAX_KEYS_NUM = 16;

export interface Key {
  name: string;
  type: KEY_TYPE;
  environment: string;
}

export enum KEY_TYPE {
  NA = "NA",
  NF = "NF",
  TRANSITION = "Transição",
}

export interface InputDevice extends InputDeviceModel {
  name: string;
  environment: string;
  module: string;
  port: string;
  sequence: string;
  keys: Key[];
}

export interface InputDeviceModel {
  modelName: string;
  type: INPUT_DEVICE_TYPE;
  numberOfKeys: number;
}

export enum INPUT_DEVICE_TYPE {
  KEYPAD = "Keypad",
  MODULE = "Módulo",
}

export const INPUT_DEVICES: InputDeviceModel[] = [
  {
    modelName: "Keypad 4 Teclas",
    type: INPUT_DEVICE_TYPE.KEYPAD,
    numberOfKeys: 4,
  },
  {
    modelName: "Módulo 4 Entradas",
    type: INPUT_DEVICE_TYPE.MODULE,
    numberOfKeys: 4,
  },
];

export interface Load {
  name: string;
  sequence: string;
  environment: string;
  value: string;
}

export interface OutputDevice extends OutputDeviceModel {
  slot: string;
  module: string;
  loads: Load[];
}

export interface OutputDeviceModel {
  modelName: string;
  numberOfLoads: number;
  outputPerLoad: number;
  type: OUTPUT_DEVICE_TYPE;
}

export enum OUTPUT_DEVICE_TYPE {
  DIMMER = "Dimmer",
  RELE = "Relé",
  MOTOR = "Motor",
  // IR = "IR",
  // RF = "RF",
}

export const MODULE_MAX_LOADS_NUM = 40;
export const MODULE_SLOT_MAX_LOADS_NUM = 4;

export const OUTPUT_DEVICES: OutputDeviceModel[] = [
  {
    modelName: "Módulo 4 Dimmers",
    type: OUTPUT_DEVICE_TYPE.DIMMER,
    numberOfLoads: 4,
    outputPerLoad: 1,
  },
  {
    modelName: "Módulo 4 Relés",
    type: OUTPUT_DEVICE_TYPE.RELE,
    numberOfLoads: 4,
    outputPerLoad: 1,
  },
  {
    modelName: "Módulo 2 Motores",
    type: OUTPUT_DEVICE_TYPE.MOTOR,
    numberOfLoads: 2,
    outputPerLoad: 2,
  },
  // {
  //     modelName: "Módulo 4 IRs",
  //     type: OUTPUT_DEVICE_TYPE.IR,
  //     numberOfLoads: 4,
  //     outputPerLoad: 1,
  // },
  // {
  //     modelName: "Módulo 4 RF",
  //     type: OUTPUT_DEVICE_TYPE.RF,
  //     numberOfLoads: 4,
  //     outputPerLoad: 1,
  // },
];

export interface Scene {
  id: string;
  deviceName: string;
  keyName: string;
  event: SCENE_EVENT;
  time: number | null;
  led: SCENE_LED_EVENT;
  name: string;
  func: SCENE_FUNCTION_EVENT;
  states: State[];
}

export interface State {
  /** State Id */
  id: string;
  /** Load name */
  name: string;
  /** Load value */
  value: string;
  /** Load type or Delay */
  type: STATE_TYPE;
}

export type STATE_TYPE = OUTPUT_DEVICE_TYPE | "delay";

export enum SCENE_EVENT {
  ONE = "Um pulso",
  TWO = "Dois pulsos",
  THREE = "Três pulsos",
  FOUR = "Quatro pulsos",
  FIVE = "Cinco pulsos",
  PRESS = "Tempo pressionado",
  REPEAT = "Repetição",
  TRANSITION = "Transição",
}

export enum SCENE_LED_EVENT {
  ON = "Ligar",
  OFF = "Desligar",
  FOLLOW = "Seguir a cena",
  NONE = "Sem efeito",
}

export enum SCENE_FUNCTION_EVENT {
  ON = "Ligar",
  ON_OFF = "Ligar/Desligar",
  TOGGLE = "Toggle",
}

export const sequenceOptions: string[] = ["1", "2", "3", "4"];
export const portOptions: string[] = ["A", "B", "C", "D", "E", "F", "G", "H"];
export const slotOptions: string[] = portOptions.concat("I", "J");
export const PORTS_BASE_INDEX = {
  A: 0,
  B: 16,
  C: 32,
  D: 48,
  E: 64,
  F: 80,
  G: 96,
  H: 112,
};
export const SLOTS_BASE_INDEX = {
  A: 0,
  B: 4,
  C: 8,
  D: 12,
  E: 16,
  F: 20,
  G: 24,
  H: 28,
  I: 32,
  J: 36,
};

export const DIMMER_INITIAL_VALUES = ["0%", "10%", "20%", "30%", "40%", "50%", "60%", "70%", "80%", "90%", "100%"];
export const DIMMER_ALL_VALUES = DIMMER_INITIAL_VALUES.concat("Incrementar", "Decrementar", "Variar");
export const DIMMER_TABLE_VALUES = {
  "0%": 0,
  "10%": 10,
  "20%": 20,
  "30%": 30,
  "40%": 40,
  "50%": 50,
  "60%": 60,
  "70%": 70,
  "80%": 80,
  "90%": 90,
  "100%": 100,
  Incrementar: 1002,
  Decrementar: 1003,
  Variar: 1004,
};

export const RELE_ALL_VALUES = ["Desligar", "Ligar"];
export const RELE_TABLE_VALUES = {
  Desligar: 0,
  Ligar: 1,
};

export const MOTOR_INITIAL_VALUES = ["Abrir", "Fechar", "Parar"];
export const MOTOR_ALL_VALUES = MOTOR_INITIAL_VALUES.concat("Abrir/Parar", "Fechar/Parar", "Abrir/Parar/Fechar/Parar");
export const MOTOR_TABLE_VALUES = {
  Fechar: 0,
  Abrir: 1,
  Parar: 2,
  "Abrir/Parar": 3,
  "Fechar/Parar": 4,
  "Abrir/Parar/Fechar/Parar": 5,
};

export const KEY_TYPE_TABLE_VALUES = {
  NA: 1,
  NF: 2,
  Transição: 3,
  Vazio: 4,
};

export const SCENE_EVENT_TABLE_VALUES = (event: SCENE_EVENT) => {
  switch (event) {
    case SCENE_EVENT.ONE:
      return 1;
    case SCENE_EVENT.TWO:
      return 2;
    case SCENE_EVENT.THREE:
      return 3;
    case SCENE_EVENT.FOUR:
      return 4;
    case SCENE_EVENT.FIVE:
      return 5;
    default:
      return 0;
  }
};

export const SCENE_TIME_TABLE_VALUES = (time: number | null) => {
  if (time) return time * 1000;
  return 0;
};

export const SCENE_LED_TABLE_VALUES = (led: SCENE_LED_EVENT) => {
  switch (led) {
    case SCENE_LED_EVENT.ON:
      return 100;
    case SCENE_LED_EVENT.FOLLOW:
      return 101;
    case SCENE_LED_EVENT.NONE:
      return 106;
    default:
      return 0;
  }
};

export const SCENE_FUNCTION_TABLE_VALUES = (func: SCENE_FUNCTION_EVENT) => {
  switch (func) {
    case SCENE_FUNCTION_EVENT.ON:
      return 1;
    case SCENE_FUNCTION_EVENT.ON_OFF:
      return 2;
    case SCENE_FUNCTION_EVENT.TOGGLE:
      return 3;
    default:
      return 0;
  }
};

export const loadTypes: OUTPUT_DEVICE_TYPE[] = [
  OUTPUT_DEVICE_TYPE.DIMMER,
  OUTPUT_DEVICE_TYPE.RELE,
  OUTPUT_DEVICE_TYPE.MOTOR,
];
export const sceneEvents: SCENE_EVENT[] = [
  SCENE_EVENT.ONE,
  SCENE_EVENT.TWO,
  SCENE_EVENT.THREE,
  SCENE_EVENT.FOUR,
  SCENE_EVENT.FIVE,
  SCENE_EVENT.PRESS,
  SCENE_EVENT.REPEAT,
  SCENE_EVENT.TRANSITION,
];
export const ledEvents: SCENE_LED_EVENT[] = [
  SCENE_LED_EVENT.ON,
  SCENE_LED_EVENT.OFF,
  SCENE_LED_EVENT.FOLLOW,
  SCENE_LED_EVENT.NONE,
];
export const functionEvents: SCENE_FUNCTION_EVENT[] = [
  SCENE_FUNCTION_EVENT.ON,
  SCENE_FUNCTION_EVENT.ON_OFF,
  SCENE_FUNCTION_EVENT.TOGGLE,
];
export const minDecimalStep = 0.1;
export const minTime = 2;
export const minDelay = 0.1;
export const maxDelay = 60;

export const getLoadValue = (type: OUTPUT_DEVICE_TYPE): string => {
  switch (type) {
    case OUTPUT_DEVICE_TYPE.DIMMER:
      return "0%";
    case OUTPUT_DEVICE_TYPE.RELE:
      return "Desligar";
    case OUTPUT_DEVICE_TYPE.MOTOR:
      return "Fechar";
    default:
      return "";
  }
};

export interface KeyData {
  moduleIndex: number;
  moduleId: string;
  deviceName: string;
  port: string;
  sequence: string;
  name: string;
  type: KEY_TYPE;
  keyIndex: number;
}
export interface LoadData {
  moduleIndex: number;
  moduleId: string;
  slot: string;
  sequence: string;
  name: string;
  value: string;
  type: OUTPUT_DEVICE_TYPE;
}
export interface SceneData {
  moduleIndex: number;
  moduleId: string;
  deviceName: string;
  keyName: string;
  id: string;
  event: SCENE_EVENT;
  time: number | null;
  led: SCENE_LED_EVENT;
  func: SCENE_FUNCTION_EVENT;
  name: string;
}
export interface StateData {
  moduleIndex: number;
  moduleId: string;
  sceneId: string;
  stateId: string;
  /** Load name */
  name: string;
  /** Load value */
  value: string;
  /** Load type or Delay */
  type: STATE_TYPE;
}

export interface ModuleMessageData {
  moduleId: string;
  message: string;
  online?: boolean;
}

export interface ModuleProgressData {
  progress?: number;
  online?: boolean;
  setupMode?: boolean;
  error?: boolean;
}

export interface ModulesProgress {
  [moduleId: string]: {
    moduleName: string;
    data: ModuleProgressData;
  };
}

export interface ModuleIotHandlerOptions {
  moduleId: string;
  projectId: string;
  getSocket: () => Socket;
  getModuleIndex: (moduleId: string) => number;
  getModuleData: (moduleId: string) => Module | undefined;
  getCentralData: () => Central;
  getKeysData: () => KeyData[];
  getLoadsData: () => LoadData[];
  getScenesData: () => SceneData[];
  getStatesData: () => StateData[];
  connectionMessage?: (data: ModuleMessageData) => void;
  moduleSetupProgress?: (moduleId: string, data: ModuleProgressData) => void;
}

/**
 * Class to handle module configuration
 */
export class ModuleIotHandler {
  #moduleId: string;
  #projectId: string;
  #online: boolean = false;
  #setupMode: boolean = false;
  #setupTopicSubscribed: string = "";
  #buffer: string[] = [];
  #packetReceived: boolean = false;
  #progressCount: number = 0;
  #totalProgress: number = 0;
  #getSocket: () => Socket;
  #getModuleIndex: (moduleId: string) => number;
  #getModuleData: (moduleId: string) => Module | undefined;
  #getCentralData: () => Central;
  #getKeysData: () => KeyData[];
  #getLoadsData: () => LoadData[];
  #getScenesData: () => SceneData[];
  #getStatesData: () => StateData[];
  #connectionMessage?: (data: ModuleMessageData) => void;
  #moduleSetupProgress?: (moduleId: string, data: ModuleProgressData) => void;

  constructor(options: ModuleIotHandlerOptions) {
    this.#projectId = options.projectId;
    this.#getSocket = options.getSocket;
    this.#getModuleIndex = options.getModuleIndex;
    this.#getModuleData = options.getModuleData;
    this.#getCentralData = options.getCentralData;
    this.#getKeysData = options.getKeysData;
    this.#getLoadsData = options.getLoadsData;
    this.#getScenesData = options.getScenesData;
    this.#getStatesData = options.getStatesData;
    this.#connectionMessage = options.connectionMessage;
    this.#moduleSetupProgress = options.moduleSetupProgress;
    // Set this.#moduleId and addHandler
    this.setModuleId(options.moduleId);
  }

  /**
   * Set new moduleId
   * @param moduleId
   */
  setModuleId(moduleId: string) {
    this.#moduleId = moduleId;
    this.#setHandler();
  }

  /**
   * Get moduleId
   * @returns module ID
   */
  getModuleId() {
    return this.#moduleId;
  }

  #buildPrivatePublishTopic() {
    return `${this.#projectId}\\${this.#moduleId}`;
  }

  #buildPrivateSubscribeTopic() {
    return `${this.#projectId}\\${this.#moduleId}\\app`;
  }

  #buildPublicSubcribeTopic() {
    return `${this.#moduleId}\\app`;
  }

  #buildSetupSubcribeTopic(randomId: string) {
    return `${this.#moduleId}\\${randomId}`;
  }

  /**
   * Called whenever moduleId change
   */
  #setHandler() {
    this.removeHandler();
    this.#addHandler();
  }

  #addHandler() {
    this.#subscribe(this.#buildPublicSubcribeTopic());
    this.#subscribe(this.#buildPrivateSubscribeTopic());
    this.#addSocketListener(this.#buildPublicSubcribeTopic());
    this.#addSocketListener(this.#buildPrivateSubscribeTopic());
    this.getInfo();
  }

  removeHandler() {
    this.#unsubscribe(this.#buildPublicSubcribeTopic());
    this.#unsubscribe(this.#buildPrivateSubscribeTopic());
    this.#removeSocketListener(this.#buildPublicSubcribeTopic());
    this.#removeSocketListener(this.#buildPrivateSubscribeTopic());
    this.setOnline(false);
  }

  #addSocketListener(topic: string) {
    if (!topic) return;
    let socket = this.#getSocket();
    if (topic === this.#buildPublicSubcribeTopic()) {
      socket.on(topic, (topic: string, message: string, ack: Ack) => this.#publicMessageListener(ack));
      return;
    }
    socket.on(topic, (topic: string, message: string, ack: Ack) => this.#privateMessageListener(message, ack));
  }

  #removeSocketListener(topic: string) {
    if (!topic) return;
    let socket = this.#getSocket();
    socket.off(topic);
  }

  #subscribe(topic: string) {
    if (!topic) return;
    let socket = this.#getSocket();
    iot_subscribe(socket, [topic]);
  }

  #unsubscribe(topic: string) {
    if (!topic) return;
    let socket = this.#getSocket();
    iot_unsubscribe(socket, [topic]);
  }

  #publish(topics: Topics, payload: Payload) {
    if (!this.#moduleId) return;
    let socket = this.#getSocket();
    iot_publish(socket, topics, payload);
  }

  #privateMessageListener(message: string, ack: Ack) {
    ack(200);
    this.#packetReceived = true;
    this.setOnline(true);
    this.#buffer.push(message);
    this.#processPackage();
  }

  #publicMessageListener(ack: Ack) {
    ack(200);
    this.#packetReceived = true;
    this.setOnline(true);
    this.#publish([this.#moduleId], `VZIO:DKTECH:0.0:SET_TOPICO_BASE:${this.#projectId}:\n`);
    const topic = this.#buildPublicSubcribeTopic();
    this.#unsubscribe(topic);
    this.#removeSocketListener(topic);
  }

  #processPackage() {
    const [message] = this.#buffer.splice(0, 1);
    const bundle = this.#protocol(message);
    // console.log("BUNDLE:", bundle);
    if (bundle) this.#publish([this.#buildPrivatePublishTopic()], bundle);
  }

  #handleSetupProgress() {
    const keysNumber = this.#getKeysWithScenes().length;
    const loadsNumber = this.#getLoads().length;
    const scenesNumber = this.#getScenes().length;
    const statesNumber = this.#getStates().length;
    this.#progressCount = 0;
    this.#totalProgress = keysNumber + loadsNumber + scenesNumber + statesNumber + 7; // basic bundles amount = 7
    this.#moduleSetupProgress &&
      this.#moduleSetupProgress(this.#moduleId, { progress: 0, setupMode: true, online: this.#online, error: false });
  }

  setup() {
    this.#setupMode = true;
    this.#setupTopicSubscribed = this.#buildSetupSubcribeTopic(createId(5));
    this.#subscribe(this.#setupTopicSubscribed);
    this.#addSocketListener(this.#setupTopicSubscribed);
    this.#handleSetupProgress();
    this.#publish(
      [this.#buildPrivatePublishTopic()],
      `VZIO:DKTECH:0.0:SET_MODO_SETUP:${this.#setupTopicSubscribed}:\n`
    );
  }

  setupEnd(error?: boolean) {
    this.#setupMode = false;
    this.#unsubscribe(this.#setupTopicSubscribed);
    this.#removeSocketListener(this.#setupTopicSubscribed);
    this.#moduleSetupProgress &&
      this.#moduleSetupProgress(this.#moduleId, { setupMode: false, online: this.#online, error: !!error });
  }

  verifyPackage() {
    if (this.#packetReceived === false) this.setOnline(false);
    this.#packetReceived = false;
  }

  getInfo() {
    // setupMode tem prioridade sobre o getInfoMode
    if (this.#setupMode) return;
    this.verifyPackage();
    this.#publish([this.#moduleId, this.#buildPrivatePublishTopic()], "VZIO:DKTECH:0.0:GET_INFO:\n");
  }

  setIdentity() {
    // setupMode tem prioridade sobre o getInfoMode
    if (this.#setupMode) return;
    this.#publish([this.#buildPrivatePublishTopic()], "VZIO:DKTECH:0.0:SET_IDENTIFICACAO:\n");
  }

  identifyLoad(type: OUTPUT_DEVICE_TYPE, slot: string, sequence: string) {
    const loads = this.#getLoads();
    const index = loads.findIndex((l) => l.type === type && l.slot === slot && l.sequence === sequence);
    if (index === -1) return;
    let bundle = "";
    const moduleIndex = this.#getModuleIndex(this.#moduleId);

    if (type === OUTPUT_DEVICE_TYPE.DIMMER)
      bundle = `VZIO:DKTECH:0.0:SET_CARGA:1:-1:${moduleIndex}:-1:${index},1,1005,2000,0,0,0,0:\n`;
    if (type === OUTPUT_DEVICE_TYPE.RELE)
      bundle = `VZIO:DKTECH:0.0:SET_CARGA:1:-1:${moduleIndex}:-1:${index},2,1005,0,0,0,0:\n`;
    if (type === OUTPUT_DEVICE_TYPE.MOTOR)
      bundle = `VZIO:DKTECH:0.0:SET_CARGA:1:-1:${moduleIndex}:-1:${index},3,1005,0,0,0,0:\n`;

    this.#publish([this.#buildPrivatePublishTopic()], bundle);
  }

  setOnline(online: boolean) {
    if (this.#online === online) return;
    this.#online = online;
    this.#connectionMessage &&
      this.#connectionMessage({
        moduleId: this.#moduleId,
        message: `A conexão com o Módulo ${this.#moduleId} foi ${online ? "estabelecida" : "perdida"}`,
        online,
      });
  }

  getOnline() {
    return this.#online;
  }

  getPacketReceived() {
    return this.#packetReceived;
  }

  getSetupMode() {
    return this.#setupMode;
  }

  #calculateProgress(): number {
    return Math.floor((this.#progressCount / this.#totalProgress) * 100);
  }

  #incrementProgress(index_ini?: number, index_end?: number) {
    if (index_ini !== undefined && index_end !== undefined) this.#progressCount += 1 + index_end - index_ini;
    else this.#progressCount++;
    this.#moduleSetupProgress && this.#moduleSetupProgress(this.#moduleId, { progress: this.#calculateProgress() });
  }

  #protocol(bundle: string): string | null {
    // console.log("PROTOCOL PROCESS MESSAGE:", bundle);

    const splittedBundle = bundle.split(":");
    const command = splittedBundle[3];
    const index_ini = Number(splittedBundle[4]);
    const index_end = Number(splittedBundle[5]);

    switch (command) {
      case "SET_INFO":
        return null;

      case "GET_INDEX_MODULO":
        this.#incrementProgress();
        return this.#buildModuleIndex();

      case "GET_CONFIG_REDE":
        this.#incrementProgress();
        return this.#buildModuleConfig();

      case "GET_N_CONFIG_ENTRADAS":
        this.#incrementProgress();
        return this.#buildKeysNumberConfig();

      case "GET_CONFIG_ENTRADA":
        this.#incrementProgress(index_ini, index_end);
        return this.#buildKeysConfig(index_ini, index_end);

      case "GET_N_CONFIG_CARGAS":
        this.#incrementProgress();
        return this.#buildLoadsNumberConfig();

      case "GET_CONFIG_CARGA":
        this.#incrementProgress(index_ini, index_end);
        return this.#buildLoadsConfig(index_ini, index_end);

      case "GET_N_CONFIG_CENAS":
        this.#incrementProgress();
        return this.#buildScenesNumberConfig();

      case "GET_CONFIG_CENA":
        this.#incrementProgress(index_ini, index_end);
        return this.#buildScenesConfig(index_ini, index_end);

      case "GET_N_CONFIG_ESTADOS":
        this.#incrementProgress();
        return this.#buildStatesNumberConfig();

      case "GET_CONFIG_ESTADO":
        this.#incrementProgress(index_ini, index_end);
        return this.#buildStatesConfig(index_ini, index_end);

      case "GET_CONFIG_TCP_SERVER":
        this.#incrementProgress();
        return this.#buildTcpServerConfig();

      case "GET_CONFIG_AWS":
        return this.#buildAwsConfig();

      case "SET_FIM_SETUP":
        this.setupEnd();
        return null;

      default:
        this.#setupMode = false;
        return null;
    }
  }

  #getKeys() {
    const keys = this.#getKeysData().filter((key) => key.moduleId === this.#moduleId);
    return keys;
  }

  #getKeysWithScenes() {
    const scenes = this.#getScenes();
    let keys = this.#getKeys();
    keys = keys.filter(
      (key) => !!scenes.find((scene) => scene.deviceName === key.deviceName && scene.keyName === key.name)
    );
    return keys;
  }

  #getLoads() {
    const loads = this.#getLoadsData().filter((load) => load.moduleId === this.#moduleId);
    return loads;
  }

  #getScenes() {
    const scenes = this.#getScenesData().filter((scene) => scene.moduleId === this.#moduleId);
    return scenes;
  }

  #getStates() {
    const states = this.#getStatesData().filter((state) => state.moduleId === this.#moduleId);
    return states;
  }

  #buildModuleIndex(): string | null {
    const index = this.#getModuleIndex(this.#moduleId);
    if (index === -1) return null;
    return `VZIO:DKTECH:0.0:SET_INDEX_MODULO:${index}:\n`;
  }

  #buildModuleConfig(): string | null {
    const module = this.#getModuleData(this.#moduleId);
    if (!module) return null;
    if (module.connection === MODULE_CONNECTION.DHCP) return "VZIO:DKTECH:0.0:SET_CONFIG_REDE:1:\n";
    return `VZIO:DKTECH:0.0:SET_CONFIG_REDE:0,${module.ip},${module.gateway},${module.dns},${module.subnet}:\n`;
  }

  #buildKeysNumberConfig(): string {
    const num = this.#getKeysWithScenes().length;
    return `VZIO:DKTECH:0.0:SET_N_CONFIG_ENTRADAS:${num}:\n`;
  }

  // #getKeyIndexOnPort(keys: KeyData[], port: string, deviceName: string, keyName: string): number {
  //   const keyIndexOnPort = keys
  //     .filter((k) => k.port === port)
  //     .sort((key1, key2) => key1.sequence.localeCompare(key2.sequence))
  //     .findIndex((k) => k.deviceName === deviceName && k.name === keyName);
  //   return keyIndexOnPort;
  // }

  #buildKeysConfig(index_ini: number, index_end: number): string {
    // const keys = this.#getKeys();
    const keysWithScenes = this.#getKeysWithScenes();
    const scenes = this.#getScenes();

    const keysConfigsArray = keysWithScenes.map((key, index) => {
      // ignorar entradas fora da faixa de entradas requisitadas pelo modulo
      if (index < index_ini || index > index_end) return null;
      const base = PORTS_BASE_INDEX[key.port] as number;
      const sequence = Number(key.sequence);
      // const keyIndex = base + this.#getKeyIndexOnPort(keys, key.port, key.deviceName, key.name);
      const keyIndex = base + (sequence - 1) * 4 + key.keyIndex;
      const keyTypeNumber = KEY_TYPE_TABLE_VALUES[key.type] as number;
      const { scenesLength, configScenes } = this.#buildKeysScenesConfig(scenes, key.deviceName, key.name);
      return `${keyIndex}-${keyTypeNumber}-${scenesLength}-${configScenes}`;
    });

    const keysConfigs = keysConfigsArray.filter((c) => c !== null).join(":");
    return `VZIO:DKTECH:0.0:SET_CONFIG_ENTRADA:${index_ini}:${index_end}:${keysConfigs}:\n`;
  }

  #buildKeysScenesConfig(scenes: SceneData[], deviceName: string, keyName: string) {
    const keyScenes = scenes.filter((scene) => scene.deviceName === deviceName && scene.keyName === keyName);

    //** Varrer cenas da tecla */
    const configsScenesArray = keyScenes.map((scene) => {
      const sceneIndex = scenes.findIndex((s) => s.id === scene.id);
      const led = SCENE_LED_TABLE_VALUES(scene.led);
      const func = SCENE_FUNCTION_TABLE_VALUES(scene.func);

      if (scene.event === SCENE_EVENT.TRANSITION) {
        return `1,${sceneIndex},${led},${func}`;
      }

      if (scene.event === SCENE_EVENT.PRESS) {
        const time = SCENE_TIME_TABLE_VALUES(scene.time);
        return `2,${sceneIndex},${led},${func},${time}`;
      }

      if (scene.event === SCENE_EVENT.REPEAT) {
        return `5,${sceneIndex},500,500,${led},${func}`;
      }

      //** else event === pulso */
      const numPulses = SCENE_EVENT_TABLE_VALUES(scene.event);
      return `4,${sceneIndex},${numPulses},${led},${func}`;
    });

    return {
      scenesLength: keyScenes.length,
      configScenes: configsScenesArray.join(";"),
    };
  }

  #buildLoadsNumberConfig(): string {
    const loads = this.#getLoads();
    return `VZIO:DKTECH:0.0:SET_N_CONFIG_CARGAS:${loads.length}:\n`;
  }

  #buildLoadsConfig(index_ini: number, index_end: number): string | null {
    const loads = this.#getLoads();

    const loadsConfigsArray = loads.map((load, index) => {
      // ignorar cargas fora da faixa de cargas requisitadas pelo modulo
      if (index < index_ini || index > index_end) return null;

      const base = SLOTS_BASE_INDEX[load.slot] as number;
      let outputIndex = base + Number(load.sequence) - 1;

      if (load.type === OUTPUT_DEVICE_TYPE.DIMMER) {
        const value = DIMMER_TABLE_VALUES[load.value] as number;
        return `${index},1,${outputIndex},${value},2000`;
      }

      if (load.type === OUTPUT_DEVICE_TYPE.RELE) {
        const value = RELE_TABLE_VALUES[load.value] as number;
        return `${index},2,${outputIndex},${value}`;
      }

      //** load.type === OUTPUT_DEVICE_TYPE.MOTOR */
      // sempre vai achar uma carga, no minimo sera igual a ela mesma
      const hasMotorIndex = loads.findIndex((l) => l.type === load.type && l.slot === load.slot);
      const indexDiff = index - hasMotorIndex;
      if (indexDiff === 1) outputIndex++;
      const value = MOTOR_TABLE_VALUES[load.value] as number;
      return `${index},3,${outputIndex},${outputIndex + 1},${value}`;
    });

    const loadsConfigs = loadsConfigsArray.filter((c) => c !== null).join(";");
    return `VZIO:DKTECH:0.0:SET_CONFIG_CARGA:${index_ini}:${index_end}:${loadsConfigs}:\n`;
  }

  #buildScenesNumberConfig(): string {
    const scenes = this.#getScenes();
    return `VZIO:DKTECH:0.0:SET_N_CONFIG_CENAS:${scenes.length}:\n`;
  }

  #buildScenesConfig(index_ini: number, index_end: number): string {
    const scenes = this.#getScenes();
    const states = this.#getStates();

    const scenesConfigsArray = scenes.map((scene, index) => {
      // ignorar cenas fora da faixa de cenas requisitadas pelo modulo
      if (index < index_ini || index > index_end) return null;

      const index_first_state = states.findIndex((state) => state.sceneId === scene.id);
      const numStates = states.filter((state) => state.sceneId === scene.id).length;

      return `${index},${index_first_state},${numStates}`;
    });

    const scenesConfigs = scenesConfigsArray.filter((c) => c !== null).join(";");
    return `VZIO:DKTECH:0.0:SET_CONFIG_CENA:${index_ini}:${index_end}:${scenesConfigs}:\n`;
  }

  #buildStatesNumberConfig(): string {
    const states = this.#getStates();
    return `VZIO:DKTECH:0.0:SET_N_CONFIG_ESTADOS:${states.length}:\n`;
  }

  #buildStatesConfig(index_ini: number, index_end: number): string {
    const loadsData = this.#getLoadsData();
    const states = this.#getStates();

    const statesConfigsArray = states.map((state, index) => {
      // ignorar estados fora da faixa de estados requisitadas pelo modulo
      if (index < index_ini || index > index_end) return null;

      if (state.type === "delay") {
        const value = Number(state.value) * 1000; // valor do delay em milissegundos
        return `${index},6,${value}`;
      }

      // encontra a carga daquele estado no array de todas as cargas
      const loadData = loadsData.find((load) => load.name === state.name);
      if (!loadData) return null;

      const loadIndex = loadsData
        .filter((load) => load.moduleId === loadData.moduleId)
        .findIndex((load) => load.name === loadData.name);

      if (state.type === OUTPUT_DEVICE_TYPE.DIMMER) {
        const value = DIMMER_TABLE_VALUES[state.value] as number;
        return `${index},1,${loadData.moduleIndex},${loadIndex},${value},2000,0,0,0,0`;
      }

      if (state.type === OUTPUT_DEVICE_TYPE.RELE) {
        const value = RELE_TABLE_VALUES[state.value] as number;
        return `${index},2,${loadData.moduleIndex},${loadIndex},${value},0,0,0,0`;
      }

      //** else state.type === MOTOR */
      const value = MOTOR_TABLE_VALUES[state.value] as number;
      return `${index},3,${loadData.moduleIndex},${loadIndex},${value},0,0,0,0`;
    });

    const statesConfigs = statesConfigsArray.filter((c) => c !== null).join(";");
    return `VZIO:DKTECH:0.0:SET_CONFIG_ESTADO:${index_ini}:${index_end}:${statesConfigs}:\n`;
  }

  #buildTcpServerConfig(): string {
    const central = this.#getCentralData();
    if (central.enabled) return `VZIO:DKTECH:0.0:SET_CONFIG_TCP_SERVER:1:${central.port}:\n`;
    return `VZIO:DKTECH:0.0:SET_CONFIG_TCP_SERVER:0:\n`;
  }

  #buildAwsConfig(): string {
    return `VZIO:DKTECH:0.0:SET_CONFIG_AWS:IOT_ENDPOINT:PORTA:TOPICO_BASE:\n`;
  }
}
