import React, { useCallback, useState } from "react";
import { InputNumber, InputNumberProps } from "primereact/inputnumber";
import InputField from "src/components/InputField";
import { useStore } from "src/providers/StoreProvider";
import Button from "src/components/Button";
import { Dropdown, DropdownProps } from "primereact/dropdown";
import {
  InputDevice,
  INPUT_DEVICES,
  INPUT_DEVICE_TYPE,
  Key,
  KEY_TYPE,
  MODULE_MAX_KEYS_NUM,
  MODULE_PORT_MAX_KEYS_NUM,
  portOptions,
  Scene,
  sequenceOptions,
} from "src/models/project.types";
import Form from "src/components/Form";
import { InputText, InputTextProps } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { SelectItemOptionsType } from "primereact/selectitem";
import Panel from "src/components/Panel";
import { maxInputNumber } from "src/utils";
import Title from "src/components/Title";

const Step3 = () => {
  const {
    projectContext: { project, setProjectData },
    toastContext: { showMessage },
  } = useStore();
  const [modelName, setModelName] = useState<string>("");
  const [amount, setAmount] = useState<number>(1);

  const onClickAddDevices = () => {
    const newInputDevices = generateInputDevices();
    setProjectData(
      {
        ...project,
        inputDevices: [...project.inputDevices, ...newInputDevices],
      },
      true
    );
  };

  const generateInputDevices = (): InputDevice[] => {
    const device = INPUT_DEVICES.find((d) => d.modelName === modelName);
    if (!device) return [];
    const length = project.inputDevices.length;
    const newInputDevices: InputDevice[] = [];
    for (let i = 1; i <= amount; i++) {
      const name = `#${length + i} ${device.modelName}`;
      newInputDevices.push({
        ...device,
        name,
        environment: "",
        module: "",
        port: "",
        sequence: "",
        keys: generateDeviceKeys(name, device.numberOfKeys),
      });
    }
    return newInputDevices;
  };

  const generateDeviceKeys = (name: string, numberOfKeys: number): Key[] => {
    const keys: Key[] = [];
    for (let i = 1; i <= numberOfKeys; i++)
      keys.push({
        name: `${name} - Tecla ${i}`,
        environment: "",
        type: KEY_TYPE.NA,
      });
    return keys;
  };

  const onChangeDeviceName = (index: number, newName: string) => {
    const projectTmp = { ...project };
    const oldName = projectTmp.inputDevices[index].name;
    projectTmp.inputDevices[index].name = newName;
    projectTmp.scenes = updateScenesDeviceOrKeyName(oldName, newName, "deviceName");
    setProjectData(projectTmp, true);
  };

  /** Propagate device or key name change */
  const updateScenesDeviceOrKeyName = (oldName: string, newName: string, field: "deviceName" | "keyName"): Scene[] => {
    return project.scenes.map((scene) => {
      if (scene[field] === oldName) scene[field] = newName;
      return scene;
    });
  };

  const countRemainingModuleKeysNum = (module: string): number => {
    let keysNum = 0;
    project.inputDevices.forEach((input) => {
      if (input.module === module) keysNum += input.numberOfKeys;
    });
    return MODULE_MAX_KEYS_NUM - keysNum;
  };

  /** Return True if is unique Module, Port and Sequence */
  const validateModulePortSequence = (module: string, port: string, sequence: string): boolean => {
    const hasInput = project.inputDevices.find(
      (input) => input.module === module && input.port === port && input.sequence === sequence
    );
    if (!hasInput) return true;
    showMessage({
      summary: `O ${module} já possui um dispositivo na porta ${port}, sequência ${sequence}`,
      severity: "error",
      life: 5000,
    });
    return false;
  };

  const onChangeModule = (index: number, module: string) => {
    const { name, port, sequence, numberOfKeys } = project.inputDevices[index];
    if (port && !sequence) {
      const remainingKeysNum = countRemainingModulePortKeysNum(module, port);
      if (remainingKeysNum < numberOfKeys) {
        return showMessage({
          summary: `Não restam entradas suficientes para adicionar o dispositivo ${name} à porta ${port}`,
          severity: "error",
          life: 5000,
        });
      }
    }
    if (port && sequence) {
      const isValid = validateModulePortSequence(module, port, sequence);
      if (!isValid) return;
    }
    const remainingKeysNum = countRemainingModuleKeysNum(module);
    if (remainingKeysNum < numberOfKeys) {
      return showMessage({
        summary: `Não restam entradas suficientes para adicionar o dispositivo ${name} ao ${module}`,
        severity: "error",
        life: 5000,
      });
    }
    const projectTmp = { ...project };
    projectTmp.inputDevices[index].module = module;
    setProjectData(projectTmp, true);
  };

  const countRemainingModulePortKeysNum = (module: string, port: string): number => {
    let keysNum = 0;
    project.inputDevices.forEach((input) => {
      if (input.module === module && input.port === port) keysNum += input.numberOfKeys;
    });
    return MODULE_PORT_MAX_KEYS_NUM - keysNum;
  };

  const onChangeModulePort = (index: number, port: string) => {
    const { name, module, sequence, numberOfKeys } = project.inputDevices[index];
    if (sequence) {
      const isValid = validateModulePortSequence(module, port, sequence);
      if (!isValid) return;
    }
    const remainingKeysNum = countRemainingModulePortKeysNum(module, port);
    if (remainingKeysNum < numberOfKeys) {
      return showMessage({
        summary: `Não restam entradas suficientes para adicionar o dispositivo ${name} à porta ${port}`,
        severity: "error",
        life: 5000,
      });
    }
    const projectTmp = { ...project };
    projectTmp.inputDevices[index].port = port;
    setProjectData(projectTmp, true);
  };

  const onChangeModulePortSequence = (index: number, sequence: string) => {
    const { module, port } = project.inputDevices[index];
    const hasInputInThisSequence = project.inputDevices.find(
      (input) => input.module === module && input.port === port && input.sequence === sequence
    );
    if (hasInputInThisSequence) {
      return showMessage({
        summary: `Já existe um dispositivo no ${module} na porta ${port}, sequência ${sequence}`,
        severity: "error",
        life: 5000,
      });
    }
    const projectTmp = { ...project };
    projectTmp.inputDevices[index].sequence = sequence;
    setProjectData(projectTmp, true);
  };

  const setDeviceField = (index: number, field: string, value: string) => {
    const projectTmp = { ...project };
    projectTmp.inputDevices[index][field] = value;

    if (field === "environment") {
      projectTmp.inputDevices[index].keys = setKeypadKeysEnv(projectTmp.inputDevices[index].keys, value);
    }

    setProjectData(projectTmp, true);
  };

  const onChangeKeyName = (deviceIndex: number, keyIndex: number, newName: string) => {
    const projectTmp = { ...project };
    const oldName = projectTmp.inputDevices[deviceIndex].keys[keyIndex].name;
    projectTmp.inputDevices[deviceIndex].keys[keyIndex].name = newName;
    projectTmp.scenes = updateScenesDeviceOrKeyName(oldName, newName, "keyName");
    setProjectData(projectTmp, true);
  };

  const setDeviceKeyField = (deviceIndex: number, keyIndex: number, field: string, value: string | KEY_TYPE) => {
    const projectTmp = { ...project };
    projectTmp.inputDevices[deviceIndex].keys[keyIndex][field] = value;
    setProjectData(projectTmp, true);
  };

  const setKeypadKeysEnv = (keys: Key[], env: string): Key[] => {
    return keys.map((k) => {
      k.environment = env;
      return k;
    });
  };

  const deleteDevice = (index: number) => {
    const projectTmp = { ...project };
    const [inputDevice] = projectTmp.inputDevices.splice(index, 1);
    projectTmp.scenes = deleteDeviceScenes(inputDevice);
    setProjectData(projectTmp, true);
  };

  /** Propagate device deletion */
  const deleteDeviceScenes = (inputDevice: InputDevice): Scene[] => {
    return project.scenes.filter((scene) => scene.deviceName !== inputDevice.name);
  };

  const disableEnvironmentField = (type: INPUT_DEVICE_TYPE) => {
    return type === INPUT_DEVICE_TYPE.MODULE;
  };

  /** Usa tanto para o campo type quanto environment */
  const disableKeyEnvironmentField = (type: INPUT_DEVICE_TYPE) => {
    return type === INPUT_DEVICE_TYPE.KEYPAD;
  };

  const keyTypeOptions = useCallback(() => {
    return [
      { label: KEY_TYPE.NA, value: KEY_TYPE.NA },
      { label: KEY_TYPE.NF, value: KEY_TYPE.NF },
      { label: KEY_TYPE.TRANSITION, value: KEY_TYPE.TRANSITION },
    ] as SelectItemOptionsType;
  }, []);

  const renderHeader = (device: InputDevice, index: number): React.ReactNode => {
    return (
      <Form style={{ marginBottom: -28 }}>
        <InputField<InputTextProps>
          Input={InputText}
          inputProps={{
            value: device.name,
            onChange: (e) => onChangeDeviceName(index, e.target.value),
          }}
          id="name"
          label="Nome *"
          error={!device.name}
          fieldClassName="col-6 sm:col-4 md:col"
        />
        <InputField<DropdownProps>
          Input={Dropdown}
          inputProps={{
            options: project.modules,
            optionLabel: "name",
            optionValue: "name",
            value: device.module,
            onChange: (e) => onChangeModule(index, e.target.value),
          }}
          id="module"
          label="Módulo *"
          error={!device.module}
          fieldClassName="col-6 sm:col-4 md:col"
        />
        <InputField<DropdownProps>
          Input={Dropdown}
          inputProps={{
            options: portOptions,
            value: device.port,
            disabled: !device.module,
            onChange: (e) => onChangeModulePort(index, e.target.value),
          }}
          id="port"
          label="Porta *"
          error={!device.port}
          fieldClassName="col-6 sm:col-2 md:col lg:col-1"
        />
        <InputField<DropdownProps>
          Input={Dropdown}
          inputProps={{
            options: sequenceOptions,
            value: device.sequence,
            disabled: !device.port,
            onChange: (e) => onChangeModulePortSequence(index, e.target.value),
          }}
          id="sequence"
          label="Seq *"
          error={!device.sequence}
          fieldClassName="col-6 sm:col-2 md:col lg:col-1"
        />
        <InputField<DropdownProps>
          Input={Dropdown}
          inputProps={{
            value: device.environment,
            options: project.environments,
            disabled: disableEnvironmentField(device.type),
            onChange: (e) => setDeviceField(index, "environment", e.target.value),
          }}
          id="environment"
          label="Ambiente"
          fieldClassName="col-6 sm:col-4 md:col"
        />
        <InputField<InputTextProps>
          Input={InputText}
          inputProps={{
            value: device.modelName,
            disabled: true,
          }}
          id="type"
          label="Tipo"
          fieldClassName="col-6 sm:col-4 md:col"
        />
        <Button
          label="Excluir"
          containerClassName="px-2 flex-initial flex align-items-end mb-5"
          outlined
          danger
          onClick={() => deleteDevice(index)}
        />
      </Form>
    );
  };

  const renderContent = (device: InputDevice, deviceIndex: number): React.ReactNode => {
    return device.keys.map((key, index) => (
      <Form style={{ marginBottom: -20 }}>
        <InputField<InputTextProps>
          Input={InputText}
          inputProps={{
            value: key.name,
            onChange: (e) => onChangeKeyName(deviceIndex, index, e.target.value),
          }}
          id="keyName"
          label="Nome *"
          error={!key.name}
          fieldClassName="col"
        />
        <InputField<DropdownProps>
          Input={Dropdown}
          inputProps={{
            value: key.type,
            options: keyTypeOptions(),
            disabled: disableKeyEnvironmentField(device.type),
            onChange: (e) => setDeviceKeyField(deviceIndex, index, "type", e.target.value),
          }}
          id="keyType"
          label="Tipo"
          fieldClassName="col"
        />
        <InputField<DropdownProps>
          Input={Dropdown}
          inputProps={{
            value: key.environment,
            options: project.environments,
            disabled: disableKeyEnvironmentField(device.type),
            onChange: (e) => setDeviceKeyField(deviceIndex, index, "environment", e.target.value),
          }}
          id="keyEnv"
          label="Ambiente"
          fieldClassName="col"
        />
      </Form>
    ));
  };

  const renderDevices = (): JSX.Element[] => {
    return project.inputDevices.map((device, index) => (
      <Panel header={renderHeader(device, index)} content={renderContent(device, index)} />
    ));
  };

  return (
    <Form>
      <Title pageTitle text="Dispositivos de Entrada" />
      <InputField<DropdownProps>
        Input={Dropdown}
        inputProps={{
          options: INPUT_DEVICES,
          optionLabel: "modelName",
          optionValue: "modelName",
          value: modelName,
          onChange: (e) => setModelName(e.value),
        }}
        id="device"
        label="Dispositivos"
        fieldClassName="col-12 md:col-3"
      />

      <InputField<InputNumberProps>
        Input={InputNumber}
        inputProps={{
          step: 1,
          min: 1,
          max: maxInputNumber,
          value: amount,
          showButtons: true,
          onChange: (e) => {
            let num = e.value;
            if (num) {
              num > maxInputNumber && (num = maxInputNumber);
              setAmount(num);
            }
          },
        }}
        id="amount"
        label="Quantidade"
        fieldClassName="px-2 w-9rem"
      />

      <Button
        label="Adicionar"
        icon="pi pi-fw pi-plus"
        containerClassName="px-2 flex-initial flex align-items-end mb-5"
        raised
        onClick={onClickAddDevices}
      />

      <Divider align="left">
        <b>{`Dispositivos Adicionados: ${project.inputDevices.length}`}</b>
      </Divider>

      <div className="mx-2 mb-5 w-full">{renderDevices()}</div>
    </Form>
  );
};

export default Step3;
