import React from "react";
import classNames from "classnames";
import { ProgressSpinner } from "primereact/progressspinner";

interface LoadingProps {
  centerOfScreen?: boolean;
  containerClassName?: string;
  iconClassName?: string;
  iconStyle?: React.CSSProperties;
}

const Loading = (props: LoadingProps) => {
  const { centerOfScreen, containerClassName, iconClassName, iconStyle } =
    props;

  return (
    <div
      className={
        centerOfScreen
          ? "flex w-full h-screen justify-content-center align-items-center"
          : containerClassName
      }
    >
      <ProgressSpinner
        className={classNames("text-6xl text-primary", iconClassName)}
        style={iconStyle}
      />
    </div>
  );
};

export default Loading;
