import React from "react";
import classNames from "classnames";

interface InputFieldProps<P> {
  Input: React.ComponentType<P>;
  inputProps: P;
  id?: string;
  label?: string;
  labelClassName?: string;
  fieldClassName?: string;
  field?: boolean;
  error?: boolean;
  errorText?: string;
  icon?: string;
  iconPos?: "left" | "right";
  noMargin?: boolean;
  onClickIcon?: (event: React.MouseEvent<HTMLElement>) => void;
}

const InputField = <P extends object>(props: InputFieldProps<P>) => {
  const {
    Input,
    inputProps,
    id,
    label,
    labelClassName,
    fieldClassName,
    field = true,
    icon,
    iconPos,
    onClickIcon,
    error,
    errorText,
    noMargin,
  } = props;
  const className = (inputProps as any).className as string;

  return (
    <div className={classNames({ field }, fieldClassName)}>
      {label && (
        <label
          htmlFor={id}
          className={classNames(
            "block text-900 font-medium mb-2",
            labelClassName
          )}
        >
          {label}
        </label>
      )}

      <div
        className={classNames("w-full", className, {
          "p-input-icon-left": iconPos === "left",
          "p-input-icon-right": iconPos === "right",
        })}
      >
        {icon && (
          <i
            className={classNames("pi", icon, {
              "cursor-pointer": !!onClickIcon,
            })}
            style={{ top: 0, margin: "18px 0" }}
            onClick={onClickIcon}
          />
        )}
        <Input
          {...inputProps}
          id={id}
          className={classNames(
            "w-full p-inputtext-sm p-multiselect-sm",
            {
              "mb-3": !noMargin && !error,
              "mb-1": !noMargin && error,
              "mb-0": noMargin,
              "p-invalid": error,
            },
            className
          )}
        />
      </div>

      {error && <p className="text-sm p-error">{errorText}</p>}
    </div>
  );
};

export default InputField;
