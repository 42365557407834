import React, { useCallback, useEffect, useState } from "react";
import { Dropdown, DropdownProps } from "primereact/dropdown";
import { InputText, InputTextProps } from "primereact/inputtext";
import { InputNumber, InputNumberChangeEvent, InputNumberProps } from "primereact/inputnumber";
import Button from "src/components/Button";
import InputField from "src/components/InputField";
import { maxInputNumber, validateIsHexa } from "src/utils";
import { useStore } from "src/providers/StoreProvider";
import Title from "src/components/Title";
import { Divider } from "primereact/divider";
import {
  DEFAULT_PORT_NUMBER,
  InputDevice,
  Module,
  ModuleMessageData,
  MODULE_CONNECTION,
  OutputDevice,
  PORT_MAX_NUMBER,
} from "src/models/project.types";
import { SelectItemOptionsType } from "primereact/selectitem";
import Form from "src/components/Form";
import Checkbox from "src/components/Checkbox";
import ListItem from "src/components/ListItem";
import { CheckboxChangeEvent } from "primereact/checkbox";
import useModuleSetupManager, { MODULE_HANDLERS } from "src/hooks/useModuleSetupManager";

const Step2 = () => {
  const {
    projectContext: { project, setProjectData },
    toastContext: { showMessage },
  } = useStore();
  const [amount, setAmount] = useState<number>(1);
  const [forceReRender, setForceReRender] = useState<boolean>(false);

  const connectionMessage = (data: ModuleMessageData) => {
    window.setTimeout(() => {
      setForceReRender((prev) => !prev);
      showMessage({
        summary: data.message,
        severity: data.online ? "success" : "error",
        life: 3000,
      });
    }, 30);
  };

  const { addHandler, updateHandler, removeHandler, setObserveModulesStatus } = useModuleSetupManager({
    connectionMessage,
  });

  useEffect(() => {
    setObserveModulesStatus(true);
  }, []);

  const onClickAddModule = useCallback(() => {
    const numModules = project.modules.length;
    const newModules: Module[] = [];
    for (let i = 1; i <= amount; i++) {
      newModules.push({
        name: `Módulo ${numModules + i}`,
        id: "",
        connection: MODULE_CONNECTION.DHCP,
        ip: "",
        gateway: "",
        subnet: "",
        dns: "",
      });
    }
    setProjectData(
      {
        ...project,
        modules: [...project.modules, ...newModules],
      },
      true
    );
  }, [project]);

  const onChangeCentralPort = useCallback(
    (event: InputNumberChangeEvent) => {
      let num = event.value;
      if (num) {
        num > PORT_MAX_NUMBER && (num = PORT_MAX_NUMBER);
        const projectTmp = { ...project };
        projectTmp.central.port = num;
        setProjectData(projectTmp, true);
      }
    },
    [project]
  );

  const onChangeCentralEnabled = useCallback(
    (event: CheckboxChangeEvent) => {
      const { checked } = event;
      const projectTmp = { ...project };
      projectTmp.central.enabled = !!checked;
      if (!checked) projectTmp.central.port = DEFAULT_PORT_NUMBER;
      setProjectData(projectTmp, true);
    },
    [project]
  );

  const connectionOptions = useCallback(() => {
    return [
      { label: MODULE_CONNECTION.DHCP, value: MODULE_CONNECTION.DHCP },
      { label: MODULE_CONNECTION.IP, value: MODULE_CONNECTION.IP },
    ] as SelectItemOptionsType;
  }, []);

  const validateIPV4 = useCallback((ipv4: string): boolean => {
    const values = ipv4.split(".");
    for (const value of values) {
      if (value.length > 3) return false;
      const num = Number(value);
      const isNaN = Number.isNaN(num);
      if (isNaN) return false;
      if (num > 255) return false;
    }
    if (values.length > 4) return false;
    return true;
  }, []);

  const onChangeModuleName = useCallback(
    (index: number, newName: string) => {
      const projectTmp = { ...project };
      const oldName = projectTmp.modules[index].name;
      projectTmp.modules[index].name = newName;
      // Propagate module name change
      projectTmp.inputDevices = projectTmp.inputDevices.map((input) => {
        if (input.module === oldName) input.module = newName;
        return input;
      });
      projectTmp.outputDevices = projectTmp.outputDevices.map((output) => {
        if (output.module === oldName) output.module = newName;
        return output;
      });
      setProjectData(projectTmp, true);
    },
    [project]
  );

  const onChangeModuleId = useCallback(
    (index: number, moduleId: string) => {
      if (moduleId.length === 12) {
        const hasModule = project.modules.find((m) => m.id === moduleId);
        if (hasModule) {
          showMessage({
            summary: `Você já possui um módulo com ID ${moduleId}`,
            severity: "error",
            life: 5000,
          });
          return;
        }
      }
      const isHexaValues = validateIsHexa(moduleId);
      // Only allows hexadecimal characters
      if (!isHexaValues) return;
      const projectTmp = { ...project };
      const oldModuleId = projectTmp.modules[index].id;
      projectTmp.modules[index].id = moduleId;
      setProjectData(projectTmp, true);
      setModuleHandler(oldModuleId, moduleId);
    },
    [project]
  );

  const setModuleHandler = useCallback((oldModuleId: string, moduleId: string) => {
    // Do nothing
    if (oldModuleId === moduleId) return;

    // Update moduleId
    if (oldModuleId.length === 12 && moduleId.length === 12) {
      updateHandler(moduleId);
      return;
    }

    // Add new module handler
    if (moduleId.length === 12) {
      addHandler(moduleId);
      return;
    }

    // Remove module handler
    removeHandler(oldModuleId);
  }, []);

  const setModuleField = useCallback(
    (index: number, field: string, value: string) => {
      const projectTmp = { ...project };
      projectTmp.modules[index][field] = value;
      setProjectData(projectTmp, true);
    },
    [project]
  );

  const onBlurIP = useCallback(
    (index: number) => {
      const projectTmp = { ...project };
      const values = projectTmp.modules[index].ip.split(".");
      if (values.length < 4) return;
      values[3] = "1";
      projectTmp.modules[index].gateway = values.join(".");
      setProjectData(projectTmp, true);
    },
    [project]
  );

  const onChangeConnection = useCallback(
    (index: number, connection: MODULE_CONNECTION) => {
      const projectTmp = { ...project };
      projectTmp.modules[index].connection = connection;
      if (connection === MODULE_CONNECTION.DHCP) {
        projectTmp.modules[index].ip = "";
        projectTmp.modules[index].gateway = "";
        projectTmp.modules[index].subnet = "";
        projectTmp.modules[index].dns = "";
      } else {
        projectTmp.modules[index].subnet = "255.255.255.0";
        projectTmp.modules[index].dns = "8.8.8.8";
      }
      setProjectData(projectTmp, true);
    },
    [project]
  );

  const setModuleIPV4Field = useCallback(
    (index: number, field: string, value: string) => {
      const isValid = validateIPV4(value);
      if (isValid) setModuleField(index, field, value);
    },
    [project]
  );

  const deleteModule = useCallback(
    (index: number) => {
      const projectTmp = { ...project };
      const [module] = projectTmp.modules.splice(index, 1);
      projectTmp.inputDevices = deleteModuleFromInputDevices(module.name);
      projectTmp.outputDevices = deleteModuleFromOutputDevices(module.name);
      setProjectData(projectTmp, true);
      removeHandler(module.id);
    },
    [project]
  );

  const deleteModuleFromInputDevices = useCallback(
    (moduleName: string): InputDevice[] => {
      return project.inputDevices.map((inputDevice) => {
        if (inputDevice.module === moduleName) {
          inputDevice.module = "";
          inputDevice.port = "";
          inputDevice.sequence = "";
        }
        return inputDevice;
      });
    },
    [project]
  );

  const deleteModuleFromOutputDevices = useCallback(
    (moduleName: string): OutputDevice[] => {
      return project.outputDevices.map((outputDevice) => {
        if (outputDevice.module === moduleName) {
          outputDevice.module = "";
          outputDevice.slot = "";
        }
        return outputDevice;
      });
    },
    [project]
  );

  const disableIPV4Field = useCallback((connection: MODULE_CONNECTION) => {
    return connection === MODULE_CONNECTION.DHCP;
  }, []);

  const renderModules = useCallback((): JSX.Element[] => {
    return project.modules.map((module, index) => {
      let online = false;
      let moduleHandler = MODULE_HANDLERS.find((h) => h.getModuleId() === module.id);
      if (moduleHandler) online = moduleHandler.getOnline();
      return (
        <ListItem borderGreen={online} borderRed={!online}>
          <Form style={{ marginBottom: -28, width: "110%" }}>
            <InputField<InputTextProps>
              Input={InputText}
              inputProps={{
                value: module.name,
                onChange: (e) => onChangeModuleName(index, e.target.value),
              }}
              id="name"
              label="Nome *"
              error={!module.name}
              fieldClassName="col-4 sm:col-4 md:col"
            />
            <InputField<InputTextProps>
              Input={InputText}
              inputProps={{
                value: module.id,
                maxLength: 12,
                onChange: (e) => onChangeModuleId(index, e.target.value.toLocaleUpperCase()),
              }}
              id="id"
              label="ID *"
              error={!module.id}
              fieldClassName="col-4 sm:col-4 md:col"
            />
            <InputField<DropdownProps>
              Input={Dropdown}
              inputProps={{
                value: module.connection,
                options: connectionOptions(),
                onChange: (e) => onChangeConnection(index, e.target.value),
              }}
              id="connection"
              label="Conexão"
              fieldClassName="col-4 sm:col-4 md:col"
            />
            <InputField<InputTextProps>
              Input={InputText}
              inputProps={{
                maxLength: 15,
                value: module.ip,
                disabled: disableIPV4Field(module.connection),
                onChange: (e) => setModuleIPV4Field(index, "ip", e.target.value),
                onBlur: () => onBlurIP(index),
              }}
              id="ip"
              label="IP"
              error={module.connection === MODULE_CONNECTION.IP && !module.ip}
              fieldClassName="col-4 sm:col-4 md:col"
            />
            <InputField<InputTextProps>
              Input={InputText}
              inputProps={{
                maxLength: 15,
                value: module.gateway,
                disabled: disableIPV4Field(module.connection),
                onChange: (e) => setModuleIPV4Field(index, "gateway", e.target.value),
              }}
              id="gateway"
              label="Gateway"
              error={module.connection === MODULE_CONNECTION.IP && !module.gateway}
              fieldClassName="col-4 sm:col-4 md:col"
            />
            <InputField<InputTextProps>
              Input={InputText}
              inputProps={{
                maxLength: 15,
                value: module.subnet,
                disabled: disableIPV4Field(module.connection),
                onChange: (e) => setModuleIPV4Field(index, "subnet", e.target.value),
              }}
              id="subnet"
              label="Subnet"
              error={module.connection === MODULE_CONNECTION.IP && !module.subnet}
              fieldClassName="col-4 sm:col-4 md:col"
            />
            <InputField<InputTextProps>
              Input={InputText}
              inputProps={{
                maxLength: 15,
                value: module.dns,
                disabled: disableIPV4Field(module.connection),
                onChange: (e) => setModuleIPV4Field(index, "dns", e.target.value),
              }}
              id="dns"
              label="DNS"
              error={module.connection === MODULE_CONNECTION.IP && !module.dns}
              fieldClassName="col-4 sm:col-4 md:col"
            />

            <Button
              tooltip="Identificar módulo"
              tooltipOptions={{ position: "top" }}
              label={online ? "Online" : "Offline"}
              containerClassName="col flex-initial flex align-items-end mb-5"
              success={online}
              danger={!online}
              onClick={() => online && moduleHandler && moduleHandler.setIdentity()}
            />

            <Button
              label="Excluir"
              containerClassName="col flex-initial flex align-items-end mb-5"
              outlined
              danger
              onClick={() => deleteModule(index)}
            />
          </Form>
        </ListItem>
      );
    });
  }, [project, forceReRender]);

  return (
    <Form>
      <Title pageTitle text="Módulos" />
      <span>
        <Title text="Central" />
        <div className="flex align-items-center">
          <InputField<InputNumberProps>
            Input={InputNumber}
            inputProps={{
              min: 0,
              max: PORT_MAX_NUMBER,
              value: project.central.port,
              disabled: !project.central.enabled,
              showButtons: true,
              onChange: onChangeCentralPort,
            }}
            id="port"
            label="Porta"
            fieldClassName="px-2 w-9rem"
          />
          <Checkbox
            label="Habilitar"
            containerClassName="px-2"
            checkboxProps={{
              id: "enableCentral",
              checked: project.central.enabled,
              onChange: onChangeCentralEnabled,
            }}
          />
        </div>
      </span>

      <span className="ml-0 md:ml-5">
        <Title text="Adicionar Módulos" />
        <div className="flex">
          <InputField<InputNumberProps>
            Input={InputNumber}
            inputProps={{
              step: 1,
              min: 1,
              max: maxInputNumber,
              value: amount,
              showButtons: true,
              onChange: (e) => {
                let num = e.value;
                if (num) {
                  num > maxInputNumber && (num = maxInputNumber);
                  setAmount(num);
                }
              },
            }}
            id="amount"
            label="Quantidade"
            fieldClassName="px-2 w-9rem"
          />
          <Button
            label="Adicionar"
            icon="pi pi-fw pi-plus"
            containerClassName="px-2 flex-initial flex align-items-end mb-5"
            raised
            onClick={onClickAddModule}
          />
        </div>
      </span>

      <Divider align="left">
        <b>{`Módulos Adicionados: ${project.modules.length}`}</b>
      </Divider>

      <div className="mx-2 mb-5 w-full">{renderModules()}</div>
    </Form>
  );
};

export default Step2;
