import { useEffect, useRef, useState, Dispatch, SetStateAction } from "react";
import { Unsubscribe } from "firebase/firestore";
import { UserData } from "src/models/user.types";
import UserService from "src/service/UserService";

export interface ClientContext {
  clients: UserData[];
  selectedClient: UserData | null;
  setSelectedClient: Dispatch<SetStateAction<UserData | null>>;
}

export const clientContextDefault: ClientContext = {
  selectedClient: null,
  clients: [],
  setSelectedClient: () => {},
};

export const useClientProvider = (userData: UserData | null): ClientContext => {
  const [clients, setClients] = useState<UserData[]>([]);
  const [selectedClient, setSelectedClient] = useState<UserData | null>(null);
  const unsubscribeClients = useRef<Unsubscribe>(() => {});

  useEffect(() => {
    if (userData) {
      unsubscribeClients.current = UserService.getClientsOnSnapshot(userData.uid, userData.role, (snap) => {
        const clientsTmp = snap.docs.map((doc) => doc.data() as UserData);
        setClients(clientsTmp);
      });
    }
    return () => {
      unsubscribeClients.current();
    };
  }, [userData]);

  return { clients, selectedClient, setSelectedClient };
};
