import React from "react";
import classNames from "classnames";
import {
  Checkbox as PrimeCheckbox,
  CheckboxProps as PrimeCheckboxProps,
} from "primereact/checkbox";

interface CheckboxProps {
  checkboxProps: PrimeCheckboxProps;
  label: string;
  labelClassName?: string;
  containerClassName?: string;
  containerStyle?: React.CSSProperties;
}

const Checkbox = (props: CheckboxProps) => {
  const {
    checkboxProps,
    label,
    labelClassName,
    containerClassName,
    containerStyle,
  } = props;
  return (
    <div className={containerClassName} style={containerStyle}>
      <div className="w-full">
        <PrimeCheckbox {...checkboxProps} />
        <label
          htmlFor={checkboxProps.id}
          className={classNames(
            "text-900 font-medium ml-2 mb-0",
            labelClassName
          )}
        >
          {label}
        </label>
      </div>
    </div>
  );
};

export default Checkbox;
