export enum COLLECTION {
  USERS = "users",
  PROJECTS = "projects",
}

export enum MENU_ITEM_TYPE {
  CATEGORY = "category",
  ROOT = "root",
}

/**
 * @see https://socket.io/docs/v4/client-socket-instance/#events
 */
export enum SocketEvents {
  CONNECT = "connect",
  CONNECT_ERROR = "connect_error",
  DISCONNECT = "disconnect",
  //** CUSTOM EVENTS */
  SET_SOCKET_ID = "set_socket_id",
}

export enum IotEvents {
  SUBSCRIBE = "subscribe",
  UNSUBSCRIBE = "unsubscribe",
  PUBLISH = "publish",
  MESSAGE = "message",
}

export type Topics = string[];
export type Payload = Buffer | string;
export type Ack = (status: number) => void;
