import classNames from "classnames";
import React from "react";

interface FormProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const Form = (props: FormProps) => {
  const { children, className, style } = props;
  return (
    <div className={classNames("formgrid grid", className)} style={style}>
      {children}
    </div>
  );
};

export default Form;
