import React, { useEffect, useRef, useState } from "react";
import Button from "src/components/Button";
import { InputText, InputTextProps } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import AuthenticationService from "src/service/AuthenticationService";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "src/providers/StoreProvider";
import { PRIVATE_PATH, PUBLIC_PATH } from "src/routes/index.routes";
import InputField from "src/components/InputField";
import Loading from "src/components/Loading";
import UserService from "src/service/UserService";
import { FirebaseError } from "firebase/app";
import { AuthErrorCodes } from "firebase/auth";

const SignUp = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    authContext: { auth },
  } = useStore();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [passwordConfirmError, setPasswordConfirmError] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [seePassword, setSeePassword] = useState<boolean>(false);
  const [seePasswordConfirm, setSeePasswordConfirm] = useState<boolean>(false);
  const toast = useRef<Toast>(null);

  useEffect(() => {
    getEmail();
  }, []);

  useEffect(() => {
    if (auth) navigate(PRIVATE_PATH.HOME);
  }, [auth]);

  const getEmail = async () => {
    if (id) {
      const userData = await UserService.getUser(id);
      if (userData) {
        setEmail(userData.email);
        setLoading(false);
      } else {
        navigate(PUBLIC_PATH.SIGN_IN);
      }
    }
  };

  const signUp = async () => {
    const error = validateFields();
    if (!email || error) return;

    try {
      await AuthenticationService.signUp(email, password);
    } catch (error) {
      const err = error as FirebaseError;
      if (err.code === AuthErrorCodes.EMAIL_EXISTS) authAlreadyExists();
      else showErrorMessage();
    }
  };

  const onEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter") signUp();
  };

  const validateFields = () => {
    const _passwordError = handlePasswordError(password);
    const _passwordConfirmError = handlePasswordConfirmError(
      password,
      passwordConfirm
    );
    return _passwordError || _passwordConfirmError;
  };

  const handlePasswordError = (password: string): boolean => {
    const error = !validatePassword(password);
    setPasswordError(error);
    return error;
  };

  const handlePasswordConfirmError = (
    password: string,
    passwordConfirm: string
  ): boolean => {
    const error = password !== passwordConfirm;
    setPasswordConfirmError(error);
    return error;
  };

  const authAlreadyExists = () => {
    showSignUpErrorMessage();
    window.setTimeout(() => navigate(PUBLIC_PATH.SIGN_IN), 5200);
  };

  /**
   * Validate Password
   *
   * @see {@link https://regexlib.com/Search.aspx?k=password&c=-1&m=5&ps=20} for more information.
   *
   * Author: Sujata Bhave
   *
   * This regular expression match can be used for validating strong password.
   * It expects atleast 1 small-case letter, 1 Capital letter, 1 digit, 1 special character
   * and the length should be at least 6 characters. The sequence of the characters is not important.
   * This expression follows the above 4 norms specified by microsoft for a strong password.
   * @params password
   * @returns True if password is valid otherwise False
   */
  const validatePassword = (password: string): boolean => {
    const regEx = new RegExp(
      "(?=^.{6,}$)(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*_+-])(?!.*\\s)(?!.*[^\\da-zA-Z!@#$%^&*_+-]).*$"
    );
    return regEx.test(password);
  };

  const onChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    handlePasswordError(event.target.value);
    handlePasswordConfirmError(event.target.value, passwordConfirm);
  };

  const onChangeConfirmPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPasswordConfirm(event.target.value);
    handlePasswordConfirmError(password, event.target.value);
  };

  const showSignUpErrorMessage = () => {
    if (toast.current)
      toast.current.show({
        severity: "error",
        summary: "Cadastro inválido!",
        detail: "Esse email já possui cadastro. Faça o login.",
        life: 5000,
      });
  };

  const showErrorMessage = () => {
    if (toast.current)
      toast.current.show({
        severity: "error",
        summary: "Erro!",
        detail: "Algum erro inesperado ocorreu. Tente novamente.",
        life: 5000,
      });
  };

  return loading ? (
    <Loading centerOfScreen />
  ) : (
    <React.Fragment>
      <Toast ref={toast} />
      <div className="flex align-items-center justify-content-center h-screen mx-3">
        <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
          <div className="text-center mb-5">
            <div className="text-900 text-3xl font-medium mb-3">Cadastro</div>
          </div>

          <div>
            <InputField<InputTextProps>
              Input={InputText}
              inputProps={{
                type: "email",
                value: email,
                disabled: true,
              }}
              id="email"
              label="Email"
            />

            <InputField<InputTextProps>
              Input={InputText}
              inputProps={{
                type: seePassword ? "text" : "password",
                onKeyDown: onEnterKeyDown,
                onChange: onChangePassword,
              }}
              id="password"
              label="Senha"
              error={passwordError}
              errorText="A senha deve conter pelo menos 6 caracteres, uma letra maiúscula, uma letra minúscula, um número e um caractere especial (!@#$%^&*+_-)."
              icon={seePassword ? "pi-eye" : "pi-eye-slash"}
              iconPos="right"
              onClickIcon={() => setSeePassword(!seePassword)}
            />

            <InputField<InputTextProps>
              Input={InputText}
              inputProps={{
                type: seePasswordConfirm ? "text" : "password",
                onKeyDown: onEnterKeyDown,
                onChange: onChangeConfirmPassword,
              }}
              id="passwordConfirm"
              label="Confirmar Senha"
              error={passwordConfirmError}
              errorText="Insira a senha novamente para confirmar."
              icon={seePasswordConfirm ? "pi-eye" : "pi-eye-slash"}
              iconPos="right"
              onClickIcon={() => setSeePasswordConfirm(!seePasswordConfirm)}
            />

            <Button
              label="Cadastrar"
              icon="pi pi-fw pi-sign-in"
              raised
              full
              onClick={signUp}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignUp;
