import { useState, useEffect } from "react";

interface WindowSize {
  height: number;
  width: number;
}

/** Get window inner width and inner height when window resizes */
const useWindowSize = (): WindowSize => {
  const [size, setSize] = useState<WindowSize>({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    const resize = () => {
      setSize({ height: window.innerHeight, width: window.innerWidth });
    };
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, []);

  return size;
};

export default useWindowSize;
