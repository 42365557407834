import React from "react";
import Menu from "./Menu";
import { MenuItem } from "primereact/menuitem";
import Button from "src/components/Button";
import { useStore } from "src/providers/StoreProvider";
import { createRootId } from "src/utils";
import { PRIVATE_PATH } from "src/routes/index.routes";
import { useNavigate } from "react-router-dom";
import { MENU_ITEM_TYPE } from "src/models/types";
import { USER_ROLE } from "src/models/user.types";

interface SidebarCategories {
  roles: USER_ROLE[];
  menuItem: MenuItem;
}

const useSidebarCategories = (): SidebarCategories[] => {
  const {
    sidebarContext: { closeSidebarOnLayoutOnverlay },
  } = useStore();

  const navigate = useNavigate();

  const SIDEBAR_CATEGORIES: SidebarCategories[] = [
    {
      roles: [USER_ROLE.DEV, USER_ROLE.DKTECH, USER_ROLE.DEALER],
      menuItem: {
        id: MENU_ITEM_TYPE.CATEGORY,
        label: "CLIENTES",
        items: [
          {
            id: createRootId(PRIVATE_PATH.CLIENTS_CREATE),
            label: "Cadastrar Cliente",
            icon: "pi pi-fw pi-user-plus",
            command: () => {
              navigate(PRIVATE_PATH.CLIENTS_CREATE);
              closeSidebarOnLayoutOnverlay();
            },
          },
          {
            id: createRootId(PRIVATE_PATH.CLIENTS_LIST),
            label: "Listar Clientes",
            icon: "pi pi-fw pi-users",
            command: () => {
              navigate(PRIVATE_PATH.CLIENTS_LIST);
              closeSidebarOnLayoutOnverlay();
            },
          },
        ],
      },
    },
    {
      roles: [USER_ROLE.DEV, USER_ROLE.DKTECH, USER_ROLE.DEALER],
      menuItem: {
        id: MENU_ITEM_TYPE.CATEGORY,
        label: "PROJETOS",
        items: [
          {
            id: createRootId(PRIVATE_PATH.PROJECTS_CREATE),
            label: "Criar Projeto",
            icon: "pi pi-fw pi-home",
            command: () => {
              navigate(PRIVATE_PATH.PROJECTS_CREATE);
              closeSidebarOnLayoutOnverlay();
            },
          },
          {
            id: createRootId(PRIVATE_PATH.PROJECTS_LIST),
            label: "Listar Projetos",
            icon: "pi pi-fw pi-list",
            command: () => {
              navigate(PRIVATE_PATH.PROJECTS_LIST);
              closeSidebarOnLayoutOnverlay();
            },
          },
        ],
      },
    },
  ];

  return SIDEBAR_CATEGORIES;
};

const AppSidebar = () => {
  const {
    authContext: { userData },
    sidebarContext: { toggleSidebar, setSidebarClicked },
  } = useStore();

  const SIDEBAR_CATEGORIES = useSidebarCategories();

  const getItemsByRole = (role: USER_ROLE): MenuItem[] => {
    const items: MenuItem[] = [];
    SIDEBAR_CATEGORIES.forEach((category) => {
      const hasAccess = category.roles.includes(role);
      hasAccess && items.push(category.menuItem);
    });
    return items;
  };

  return (
    <aside className="layout-sidebar" onClick={() => setSidebarClicked(true)}>
      <div className="layout-sidebar-content">
        <div className="layout-menu-container">
          <Menu items={userData ? getItemsByRole(userData.role) : []} className="layout-menu" />
        </div>
        <Button
          label=" "
          containerClassName="layout-sidebar-toggle"
          className="p-0 no-underline text-color"
          icon="pi pi-fw pi-angle-double-left"
          iconPos="right"
          link
          full
          onClick={toggleSidebar}
        />
      </div>
    </aside>
  );
};

export default AppSidebar;
