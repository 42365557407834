import classNames from "classnames";
import React from "react";

interface TitleProps {
  text: string;
  className?: string;
  pageTitle?: boolean;
}

const Title = (props: TitleProps) => {
  const { text, className, pageTitle } = props;

  if (pageTitle)
    return (
      <h4 className={classNames("mx-2 mb-5 w-full", className)}>{text}</h4>
    );

  return <h5 className={classNames("divider-margin", className)}>{text}</h5>;
};

export default Title;
