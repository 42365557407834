import React, { useState } from "react";
import { Card } from "primereact/card";
import { Steps } from "primereact/steps";
import Button from "src/components/Button";
import Step0 from "./Step0";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Step6 from "./Step6";
import { useStore } from "src/providers/StoreProvider";
import { MODULE_CONNECTION } from "src/models/project.types";
import DialogOptions from "src/components/DialogOptions";
import { useCallbackPrompt } from "src/hooks/useCallbackPrompt";

const CreateProjects = () => {
  const {
    projectContext: { project, saveProjectFlag, createNewProject, saveProjectData },
    toastContext: { showMessage },
  } = useStore();

  const lastStep = 6;
  const [step, setStep] = useState<number>(0);
  const [newProjectBtnClicked, setNewProjectBtnClicked] = useState<boolean>(false);
  const { showPrompt, confirmNavigation } = useCallbackPrompt(saveProjectFlag);

  const stepsItems = [
    {
      label: "Dados Básicos",
      command: () => changeStep(0),
    },
    {
      label: "Ambientes",
      command: () => changeStep(1),
    },
    {
      label: "Módulos",
      command: () => changeStep(2),
    },
    {
      label: "Dispositivos de Entrada",
      command: () => changeStep(3),
    },
    {
      label: "Cargas",
      command: () => changeStep(4),
    },
    {
      label: "Cenas",
      command: () => changeStep(5),
    },
    {
      label: "Configurações",
      command: () => changeStep(lastStep),
    },
  ];

  const changeStep = (step: number) => {
    if (step === lastStep) {
      const error = verifyProjectEmptyFields();
      if (error) return;
    }
    setStep(step);
  };

  const onClickPreviousStep = () => {
    changeStep(step - 1);
  };

  const onClickNextStep = () => {
    if (step < lastStep) changeStep(step + 1);
  };

  const newProject = () => {
    createNewProject();
    setStep(0);
    setNewProjectBtnClicked(false);
  };

  const handleOnCancelDialogOptions = (): void => {
    newProject();
    if (newProjectBtnClicked) return; //* Manter na mesma tela caso o Dialog tenha sido solicitado pelo botao de Novo Projeto
    confirmNavigation();
  };

  const handleOnConfirmDialogOptions = async (): Promise<void> => {
    await saveProjectData();
    newProject();
    if (newProjectBtnClicked) return; //* Manter na mesma tela caso o Dialog tenha sido solicitado pelo botao de Novo Projeto
    confirmNavigation();
  };

  /**
   * Verificar quais dados do projeto possuem campos obrigatorios nao preenchidos
   * @returns True se tiver erro, senao return False
   */
  const verifyProjectEmptyFields = () => {
    if (verifyModulesEmptyFields()) {
      showMessage({
        summary: "Preencha todos os campos obrigatórios destacados em vermelho para os Módulos",
        severity: "error",
        life: 3000,
      });
      return true;
    }
    if (verifyInputDevicesEmptyFields()) {
      showMessage({
        summary: "Preencha todos os campos obrigatórios destacados em vermelho para os Dispositivos de Entrada",
        severity: "error",
        life: 3000,
      });
      return true;
    }
    if (verifyOutputDevicesEmptyFields()) {
      showMessage({
        summary: "Preencha todos os campos obrigatórios destacados em vermelho para as Cargas",
        severity: "error",
        life: 3000,
      });
      return true;
    }
  };

  /**
   * Verificar se os modulos possuem algum campo obrigatorio vazio
   * @returns True se tiver erro, senao return False
   */
  const verifyModulesEmptyFields = (): boolean => {
    const { modules } = project;
    for (const module of modules) {
      if (!module.name || !module.id) return true;
      if (module.connection === MODULE_CONNECTION.IP)
        if (!module.ip || !module.gateway || !module.subnet || !module.dns) return true;
    }
    return false;
  };

  const verifyInputDevicesEmptyFields = (): boolean => {
    const { inputDevices } = project;
    for (const device of inputDevices) {
      if (!device.name || !device.module || !device.port || !device.sequence) return true;
      for (const key of device.keys) if (!key.name) return true;
    }
    return false;
  };

  const verifyOutputDevicesEmptyFields = (): boolean => {
    const { outputDevices } = project;
    for (const device of outputDevices) {
      if (!device.module || !device.slot) return true;
      for (const load of device.loads) if (!load.name) return true;
    }
    return false;
  };

  const renderCardTitle = () => {
    return (
      <div className="flex flex-wrap justify-content-between">
        <div>{project.name ? `Projeto: ${project.name}` : "Criar Projeto"}</div>
        <div className="flex justify-content-between">
          <Button
            label="Novo"
            icon="pi pi-fw pi-plus"
            containerClassName="mr-3 mt-1"
            raised
            onClick={(e) => {
              e.preventDefault();
              if (saveProjectFlag) setNewProjectBtnClicked(true);
              else newProject();
            }}
          />
          <Button
            label="Salvar"
            icon="pi pi-fw pi-check"
            containerClassName="mt-1"
            success
            raised
            onClick={(e) => {
              e.preventDefault();
              saveProjectData();
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <Card title={renderCardTitle()} subTitle="Campo obrigatório (*)">
      <div className="overflow-auto mb-4">
        <Steps model={stepsItems} activeIndex={step} readOnly={false} style={{ minWidth: 1050 }} />
      </div>

      {step === 0 && <Step0 />}
      {step === 1 && <Step1 />}
      {step === 2 && <Step2 />}
      {step === 3 && <Step3 />}
      {step === 4 && <Step4 />}
      {step === 5 && <Step5 />}
      {step === lastStep && <Step6 />}

      <div className="flex flex-wrap justify-content-between">
        <Button
          label="Voltar"
          icon="pi pi-fw pi-arrow-left"
          disabled={step === 0}
          raised
          className="my-1"
          style={{ width: 120 }}
          onClick={onClickPreviousStep}
        />

        <Button
          label={step === lastStep ? "Salvar" : "Avançar"}
          iconPos={step === lastStep ? "left" : "right"}
          icon={`pi pi-fw pi-${step === lastStep ? "check" : "arrow-right"}`}
          success={step === lastStep}
          raised
          className="my-1"
          style={{ width: 120 }}
          onClick={step === lastStep ? saveProjectData : onClickNextStep}
        />
      </div>

      <DialogOptions
        header="Salvar Projeto"
        children="Deseja salvar as últimas alterações?"
        visible={showPrompt || newProjectBtnClicked}
        style={{ width: "50vw" }}
        onHide={() => null}
        closable={false}
        cancelButtonProps={{
          label: "Não",
          danger: true,
          raised: true,
          containerClassName: "my-1 mr-2",
          icon: "pi pi-fw pi-times",
          onClick: handleOnCancelDialogOptions,
        }}
        confirmButtonProps={{
          label: "Sim",
          success: true,
          raised: true,
          containerClassName: "my-1",
          icon: "pi pi-fw pi-check",
          onClick: handleOnConfirmDialogOptions,
        }}
      />
    </Card>
  );
};

export default CreateProjects;
