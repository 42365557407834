import { db } from "src/api";
import {
  collection,
  query,
  where,
  onSnapshot,
  Unsubscribe,
  FirestoreError,
  QuerySnapshot,
  updateDoc,
  doc,
  setDoc,
} from "firebase/firestore";
import { COLLECTION } from "src/models/types";
import { Project } from "src/models/project.types";
import { getDateUTC } from "src/utils";

class ProjectService {
  static getProjectsOnSnapshot(
    creatorId: string,
    onNext: (snapshot: QuerySnapshot) => void,
    onError?: (error: FirestoreError) => void,
    onCompletion?: () => void
  ): Unsubscribe {
    const q = query(collection(db, COLLECTION.PROJECTS), where("creatorId", "==", creatorId));
    return onSnapshot(q, onNext, onError, onCompletion);
  }

  static createProjectID(): string {
    const docRef = doc(collection(db, COLLECTION.PROJECTS));
    return docRef.id;
  }

  static async saveProject(project: Project): Promise<void> {
    try {
      project.updatedAt = getDateUTC();
      await setDoc(doc(db, COLLECTION.PROJECTS, project.id), project, {
        merge: true,
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  static async deleteProject(id: string): Promise<void> {
    try {
      await updateDoc(doc(db, COLLECTION.PROJECTS, id), {
        deleted: true,
        updatedAt: getDateUTC(),
      });
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }
}

export default ProjectService;
