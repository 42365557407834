import React from "react";
import MenuButton from "./MenuButton";
import ProfileButton from "./ProfileButton";

const AppTopbar = () => {
  return (
    <div className="layout-topbar">
      <MenuButton />
      <ProfileButton />
    </div>
  );
};

export default AppTopbar;
