import { db } from "src/api";
import {
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
  Unsubscribe,
  FirestoreError,
  QuerySnapshot,
  updateDoc,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { COLLECTION } from "src/models/types";
import { USER_ROLE, USER_TYPE } from "src/models/user.types";
import { UserData, UserDataDTO } from "src/models/user.types";
import { getDateUTC } from "src/utils";

class UserService {
  static getUserOnSnapshot(
    uid: string,
    onNext: (snapshot: QuerySnapshot) => void,
    onError?: (error: FirestoreError) => void,
    onCompletion?: () => void
  ): Unsubscribe {
    const q = query(collection(db, COLLECTION.USERS), where("uid", "==", uid));
    return onSnapshot(q, onNext, onError, onCompletion);
  }

  static async createUser(data: UserDataDTO): Promise<string> {
    try {
      const docRef = doc(collection(db, COLLECTION.USERS));
      const docId = docRef.id;
      await setDoc(docRef, {
        ...data,
        docId,
        uid: "",
        disabled: false,
        createdAt: getDateUTC(),
        updatedAt: getDateUTC(),
      });
      return docId;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  static async updateUser(userData: UserData): Promise<void> {
    try {
      await updateDoc(doc(db, COLLECTION.USERS, userData.docId), {
        ...userData,
        updatedAt: getDateUTC(),
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  // TODO: Corrigir esse metodo e colocar docId no lugar do email.
  // TODO: Validar no SignUp se ja tiver UID redirecionar para o SignIn
  static async attachUID(email: string, uid: string): Promise<void> {
    try {
      const res = await getDocs(
        query(collection(db, COLLECTION.USERS), where("email", "==", email))
      );

      if (res.empty) throw new Error("Invalid email.");

      const docId = res.docs[0].id;

      await updateDoc(doc(db, COLLECTION.USERS, docId), {
        uid,
        updatedAt: getDateUTC(),
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  static async getUser(docId: string): Promise<UserData | null> {
    try {
      const docRef = doc(db, COLLECTION.USERS, docId);
      const docSnap = await getDoc(docRef);
      return docSnap.exists() ? (docSnap.data() as UserData) : null;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  static getCarriersOnSnapshot(
    creatorUid: string,
    creatorRole: USER_ROLE,
    onNext: (snapshot: QuerySnapshot) => void,
    onError?: (error: FirestoreError) => void,
    onCompletion?: () => void
  ): Unsubscribe {
    const q =
      creatorRole === USER_ROLE.DKTECH
        ? query(
            collection(db, COLLECTION.USERS),
            where("type", "==", USER_TYPE.CARRIER),
            where("creatorRole", "==", USER_ROLE.DKTECH)
          )
        : query(
            collection(db, COLLECTION.USERS),
            where("type", "==", USER_TYPE.CARRIER),
            where("creatorUid", "==", creatorUid)
          );
    return onSnapshot(q, onNext, onError, onCompletion);
  }

  static getClientsOnSnapshot(
    creatorUid: string,
    creatorRole: USER_ROLE,
    onNext: (snapshot: QuerySnapshot) => void,
    onError?: (error: FirestoreError) => void,
    onCompletion?: () => void
  ): Unsubscribe {
    const q =
      creatorRole === USER_ROLE.DKTECH
        ? query(
            collection(db, COLLECTION.USERS),
            where("creatorRole", "==", USER_ROLE.DKTECH)
          )
        : query(
            collection(db, COLLECTION.USERS),
            where("creatorUid", "==", creatorUid)
          );

    return onSnapshot(q, onNext, onError, onCompletion);
  }

  static async setAccountState(
    docId: string,
    disabled: boolean
  ): Promise<void> {
    try {
      await updateDoc(doc(db, COLLECTION.USERS, docId), {
        disabled,
        updatedAt: getDateUTC(),
      });
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }
}

export default UserService;
