import React from "react";
import { Panel as PrimePanel, PanelHeaderTemplateOptions } from "primereact/panel";
import { Ripple } from "primereact/ripple";
import classNames from "classnames";

interface PanelProps {
  header?: React.ReactNode;
  content?: React.ReactNode;
  panelClassName?: string;
}

const Panel = (props: PanelProps) => {
  const { header, content, panelClassName } = props;

  const headerTemplate = (options: PanelHeaderTemplateOptions) => {
    const { collapsed } = options;
    const className = `${options.className} justify-content-start`;

    return (
      <div
        className={className}
        style={{
          borderBottom: !collapsed ? "none" : undefined,
          borderBottomLeftRadius: !collapsed ? 0 : undefined,
          borderBottomRightRadius: !collapsed ? 0 : undefined,
        }}
      >
        <div className="w-full">{header}</div>
        <button className={classNames("ml-3", options.togglerClassName)} onClick={options.onTogglerClick}>
          <span className={`pi pi-chevron-${collapsed ? "down" : "up"}`} />
          <Ripple />
        </button>
      </div>
    );
  };

  return (
    <PrimePanel toggleable collapsed headerTemplate={headerTemplate} className={classNames("mb-3", panelClassName)}>
      {content}
    </PrimePanel>
  );
};

export default Panel;
