import React, { useEffect, useRef, useState } from "react";
import Button from "src/components/Button";
import { InputText, InputTextProps } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import AuthenticationService from "src/service/AuthenticationService";
import { useNavigate } from "react-router-dom";
import { useStore } from "src/providers/StoreProvider";
import { PRIVATE_PATH } from "src/routes/index.routes";
import InputField from "src/components/InputField";
import { ConfirmPopup } from "primereact/confirmpopup";
import { FirebaseError } from "firebase/app";
import { AuthErrorCodes } from "firebase/auth";
import Checkbox from "src/components/Checkbox";

const SignIn = () => {
  const navigate = useNavigate();
  const {
    authContext: { auth },
  } = useStore();
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [seePassword, setSeePassword] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState<boolean>(false);
  const toast = useRef<Toast>(null);
  const forgetPasswordButton = useRef<HTMLButtonElement>(
    document.getElementById("forgetPasswordButton") as HTMLButtonElement
  );

  useEffect(() => {
    if (auth) navigate(PRIVATE_PATH.HOME);
  }, [auth]);

  // Sign in with email and password
  const signIn = async () => {
    const error = validateFields();
    if (error) return;

    try {
      await AuthenticationService.signIn(email, password, rememberMe);
    } catch (error) {
      const err = error as FirebaseError;
      if (err.code === AuthErrorCodes.USER_DISABLED)
        showUserDisabledErrorMessage();
      else showSignInErrorMessage();
    }
  };

  // Send reset password email
  const sendPasswordResetEmail = async () => {
    try {
      setEmailError(false);
      await AuthenticationService.sendPasswordResetEmail(email);
      showResetPasswordEmailMessage();
    } catch (error) {
      setEmailError(true);
      showResetPasswordEmailErrorMessage();
    }
  };

  // Validate sign in inputs
  const validateFields = () => {
    const _emailError = !email;
    const _passwordError = !password;

    setEmailError(_emailError);
    setPasswordError(_passwordError);

    return _emailError || _passwordError;
  };

  const onClickForgetPasswordButton = () => {
    if (!email) {
      setEmailError(true);
      showResetPasswordEmailErrorMessage(true);
      return;
    }
    setEmailError(false);
    setShowConfirmPopup(true);
  };

  const onEnterKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === "Enter") signIn();
  };

  const showSignInErrorMessage = () => {
    if (toast.current)
      toast.current.show({
        severity: "error",
        summary: "Login inválido!",
        detail: "Email ou senha incorretos.",
        life: 5000,
      });
  };

  const showUserDisabledErrorMessage = () => {
    if (toast.current)
      toast.current.show({
        severity: "error",
        summary: "Conta desativada!",
        detail:
          "Sua conta está desativada. Entre em contato com o suporte DKTech para obter mais informações.",
        life: 10000,
      });
  };

  const showResetPasswordEmailMessage = () => {
    if (toast.current)
      toast.current.show({
        severity: "success",
        summary: "Email de redefinição de senha enviado!",
        detail: (
          <>
            O email foi enviado para <b>{email}</b>. Verifique também as caixas
            de Spam e Lixo Eletrônico.
          </>
        ),
        life: 10000,
      });
  };

  const showResetPasswordEmailErrorMessage = (emptyEmail?: boolean) => {
    if (toast.current)
      toast.current.show({
        severity: "error",
        summary: emptyEmail ? "Email não preenchido!" : "Email inválido!",
        detail: "Insira seu email de login.",
        life: 5000,
      });
  };

  return (
    <React.Fragment>
      <Toast ref={toast} />
      <div className="flex align-items-center justify-content-center h-screen">
        <div className="surface-card p-4 shadow-2 border-round w-full sm:w-8 md:w-6 lg:w-4">
          <div className="text-center mb-5">
            <div className="text-900 text-3xl font-medium mb-3">Login</div>
          </div>

          <div>
            <InputField<InputTextProps>
              Input={InputText}
              inputProps={{
                type: "email",
                onKeyDown: onEnterKeyDown,
                onChange: (event) => {
                  setEmail(event.target.value);
                },
              }}
              id="email"
              label="Email"
              error={emailError}
              errorText="Insira o email."
            />

            <InputField<InputTextProps>
              Input={InputText}
              inputProps={{
                type: seePassword ? "text" : "password",
                onKeyDown: onEnterKeyDown,
                onChange: (event) => {
                  setPassword(event.target.value);
                },
              }}
              id="password"
              label="Senha"
              error={passwordError}
              errorText="Insira a senha."
              icon={seePassword ? "pi-eye" : "pi-eye-slash"}
              iconPos="right"
              onClickIcon={() => setSeePassword(!seePassword)}
            />

            <div className="flex flex-wrap align-items-center justify-content-between mb-6">
              <Checkbox
                label="Manter login"
                containerClassName="my-2"
                checkboxProps={{
                  id: "rememberme",
                  checked: rememberMe,
                  onChange: (e) => setRememberMe(!!e.checked),
                }}
              />
              <ConfirmPopup
                target={forgetPasswordButton.current}
                visible={showConfirmPopup}
                onHide={() => setShowConfirmPopup(false)}
                message="Deseja enviar email de redefinição de senha?"
                icon="pi pi-exclamation-triangle"
                accept={sendPasswordResetEmail}
                reject={() => setShowConfirmPopup(false)}
              />
              <Button
                id="forgetPasswordButton"
                label="Esqueci minha senha"
                link
                className="p-0 my-2"
                onClick={onClickForgetPasswordButton}
              />
            </div>

            <Button
              label="Entrar"
              icon="pi pi-fw pi-sign-in"
              raised
              full
              onClick={signIn}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignIn;
