import React, { useEffect, useState } from "react";
import { Divider } from "primereact/divider";
import { Dropdown, DropdownProps } from "primereact/dropdown";
import { InputNumber, InputNumberProps } from "primereact/inputnumber";
import { InputText, InputTextProps } from "primereact/inputtext";
import Button from "src/components/Button";
import Form from "src/components/Form";
import InputField from "src/components/InputField";
import Panel from "src/components/Panel";
import {
  DIMMER_INITIAL_VALUES,
  getLoadValue,
  Load,
  ModuleIotHandler,
  MODULE_MAX_LOADS_NUM,
  MODULE_SLOT_MAX_LOADS_NUM,
  MOTOR_INITIAL_VALUES,
  OutputDevice,
  OutputDeviceModel,
  OUTPUT_DEVICES,
  OUTPUT_DEVICE_TYPE,
  RELE_ALL_VALUES,
  Scene,
  slotOptions,
  State,
} from "src/models/project.types";
import { useStore } from "src/providers/StoreProvider";
import { maxInputNumber } from "src/utils";
import Title from "src/components/Title";
import useModuleSetupManager, { MODULE_HANDLERS } from "src/hooks/useModuleSetupManager";

const Step4 = () => {
  const {
    projectContext: { project, setProjectData },
    toastContext: { showMessage },
  } = useStore();
  const [modelName, setModelName] = useState<string>("");
  const [amount, setAmount] = useState<number>(1);

  const { generateLoadsArray } = useModuleSetupManager();

  useEffect(() => {
    generateLoadsArray();
  }, []);

  const onClickAddDevices = () => {
    const newOutputDevices = generateOutputDevices();
    setProjectData(
      {
        ...project,
        outputDevices: [...project.outputDevices, ...newOutputDevices],
      },
      true
    );
  };

  const generateOutputDevices = (): OutputDevice[] => {
    const device = OUTPUT_DEVICES.find((d) => d.modelName === modelName);
    if (!device) return [];
    const length = project.outputDevices.length;
    const newOutputDevices: OutputDevice[] = [];
    for (let i = 1; i <= amount; i++)
      newOutputDevices.push({
        ...device,
        slot: "",
        module: "",
        loads: generateDeviceLoads(device, length + i),
      });
    return newOutputDevices;
  };

  const generateDeviceLoads = (device: OutputDeviceModel, length: number): Load[] => {
    const loads: Load[] = [];
    for (let i = 1; i <= device.numberOfLoads; i++)
      loads.push({
        name: `#${length} ${device.modelName} - Carga ${i}`,
        sequence: String(i),
        environment: "",
        value: getLoadValue(device.type),
      });
    return loads;
  };

  const verifyModuleOutputs = (module: string, numOutputsRequired: number): boolean => {
    let numOutputsUsed = 0;
    project.outputDevices.forEach((output) => {
      if (output.module === module) numOutputsUsed += output.numberOfLoads * output.outputPerLoad;
    });
    const hasOutputs = numOutputsUsed + numOutputsRequired <= MODULE_MAX_LOADS_NUM;
    return hasOutputs;
  };

  const onChangeModule = (index: number, module: string) => {
    const { slot, modelName, numberOfLoads, outputPerLoad } = project.outputDevices[index];
    const numOutputsRequired = numberOfLoads * outputPerLoad;

    if (slot) {
      const hasSlotOutputs = verifySlotOutputs(module, slot, numOutputsRequired);
      if (!hasSlotOutputs)
        return showMessage({
          summary: `Não restam saídas suficientes para adicionar o dispositivo ${modelName} ao slot ${slot} do ${module}`,
          severity: "error",
          life: 5000,
        });
    }

    const hasModuleOutputs = verifyModuleOutputs(module, numOutputsRequired);
    if (!hasModuleOutputs)
      return showMessage({
        summary: `Não restam saídas suficientes para adicionar o dispositivo ${modelName} ao ${module}`,
        severity: "error",
        life: 5000,
      });

    const projectTmp = { ...project };
    projectTmp.outputDevices[index].module = module;
    setProjectData(projectTmp, true);
  };

  const verifySlotOutputs = (module: string, slot: string, numOutputsRequired: number): boolean => {
    let numOutputsUsed = 0;
    project.outputDevices.forEach((output) => {
      if (output.module === module && output.slot === slot)
        numOutputsUsed += output.numberOfLoads * output.outputPerLoad;
    });
    return numOutputsUsed + numOutputsRequired <= MODULE_SLOT_MAX_LOADS_NUM;
  };

  const onChangeSlot = (index: number, slot: string) => {
    const { module, modelName, numberOfLoads, outputPerLoad } = project.outputDevices[index];
    const hasOutputs = verifySlotOutputs(module, slot, numberOfLoads * outputPerLoad);
    if (!hasOutputs)
      return showMessage({
        summary: `Não restam saídas suficientes para adicionar o dispositivo ${modelName} ao slot ${slot} do ${module}`,
        severity: "error",
        life: 5000,
      });
    const projectTmp = { ...project };
    projectTmp.outputDevices[index].slot = slot;
    setProjectData(projectTmp, true);
  };

  const onChangeLoadName = (deviceIndex: number, loadIndex: number, newName: string) => {
    const projectTmp = { ...project };
    const oldName = projectTmp.outputDevices[deviceIndex].loads[loadIndex].name;
    projectTmp.outputDevices[deviceIndex].loads[loadIndex].name = newName;
    projectTmp.scenes = updateScenesLoadName(oldName, newName);
    setProjectData(projectTmp, true);
  };

  // Propagate load name change
  const updateScenesLoadName = (oldName: string, newName: string): Scene[] => {
    return project.scenes.map((scene) => {
      scene.states = scene.states.map((state) => {
        if (state.name === oldName) state.name = newName;
        return state;
      });
      return scene;
    });
  };

  const setDeviceLoadField = (deviceIndex: number, loadIndex: number, field: string, value: string) => {
    const projectTmp = { ...project };
    projectTmp.outputDevices[deviceIndex].loads[loadIndex][field] = value;
    setProjectData(projectTmp, true);
  };

  const deleteDevice = (index: number) => {
    const projectTmp = { ...project };
    const [outputDevice] = projectTmp.outputDevices.splice(index, 1);
    projectTmp.scenes = deleteLoadsStates(outputDevice);
    setProjectData(projectTmp, true);
  };

  const deleteLoadsStates = (outputDevice: OutputDevice): Scene[] => {
    const scenesTmp: Scene[] = [];
    project.scenes.forEach((scene) => {
      let states = deleteStatesFromScenes(outputDevice.loads, scene.states);
      // se tiver pelo menos uma carga a cena ainda existe
      if (states.find((state) => state.type !== "delay")) scenesTmp.push({ ...scene, states });
    });
    return scenesTmp;
  };

  const deleteStatesFromScenes = (loadsToDelete: Load[], states: State[]): State[] => {
    for (let index = 0; index < states.length; index++) {
      let state = states[index];
      if (state.type !== "delay" && loadsToDelete.find((l) => l.name === state.name)) {
        // logica para verificar se a carga vai deixar delays sucessivos
        let prevIndex = index - 1;
        let nextIndex = index + 1;
        let prevStateIsDelay = false;
        let nextStateIsDelay = false;
        if (prevIndex >= 0) if (states[prevIndex].type === "delay") prevStateIsDelay = true;
        if (nextIndex < states.length) if (states[nextIndex].type === "delay") nextStateIsDelay = true;
        states.splice(index, prevStateIsDelay && nextStateIsDelay ? 2 : 1);
        index = -1; // setar index = -1 para recomecar o for
      }
    }
    return states;
  };

  const valueOptions = (type: OUTPUT_DEVICE_TYPE): string[] => {
    if (type === OUTPUT_DEVICE_TYPE.DIMMER) return DIMMER_INITIAL_VALUES;
    if (type === OUTPUT_DEVICE_TYPE.RELE) return RELE_ALL_VALUES;
    return MOTOR_INITIAL_VALUES;
  };

  const onClickIndentifyLoad = (device: OutputDevice, sequence: string) => {
    const module = project.modules.find((m) => m.name === device.module);
    let moduleHandler: ModuleIotHandler | undefined = undefined;
    if (module) moduleHandler = MODULE_HANDLERS.find((h) => h.getModuleId() === module.id);
    moduleHandler && moduleHandler.identifyLoad(device.type, device.slot, sequence);
  };

  const renderHeader = (device: OutputDevice, index: number): React.ReactNode => {
    return (
      <Form style={{ marginBottom: -28 }}>
        <InputField<DropdownProps>
          Input={Dropdown}
          inputProps={{
            options: project.modules,
            optionLabel: "name",
            optionValue: "name",
            value: device.module,
            onChange: (e) => onChangeModule(index, e.target.value),
          }}
          id="module"
          label="Módulo *"
          error={!device.module}
          fieldClassName="col-6 sm:col-4 md:col"
        />
        <InputField<DropdownProps>
          Input={Dropdown}
          inputProps={{
            options: slotOptions,
            disabled: !device.module,
            value: device.slot,
            onChange: (e) => onChangeSlot(index, e.target.value),
          }}
          id="slot"
          label="Slot *"
          error={!device.slot}
          fieldClassName="col-6 sm:col-4 md:col"
        />
        <InputField<InputTextProps>
          Input={InputText}
          inputProps={{
            value: device.modelName,
            disabled: true,
          }}
          id="type"
          label="Tipo"
          fieldClassName="col-6 sm:col-4 md:col"
        />
        <Button
          label="Excluir"
          containerClassName="px-2 flex-initial flex align-items-end mb-5"
          outlined
          danger
          onClick={() => deleteDevice(index)}
        />
      </Form>
    );
  };

  const renderContent = (device: OutputDevice, deviceIndex: number): React.ReactNode => {
    return device.loads.map((load, index) => {
      return (
        <Form>
          <InputField<InputTextProps>
            Input={InputText}
            inputProps={{
              value: load.name,
              onChange: (e) => onChangeLoadName(deviceIndex, index, e.target.value),
            }}
            field={false}
            id="name"
            label="Nome *"
            error={!load.name}
            fieldClassName="col-6 md:col"
          />
          <InputField<InputTextProps>
            Input={InputText}
            inputProps={{
              value: load.sequence,
              disabled: true,
            }}
            field={false}
            id="seq"
            label="Seq"
            fieldClassName="col-6 md:col"
          />
          <InputField<DropdownProps>
            Input={Dropdown}
            inputProps={{
              value: load.environment,
              options: project.environments,
              onChange: (e) => setDeviceLoadField(deviceIndex, index, "environment", e.target.value),
            }}
            field={false}
            id="environment"
            label="Ambiente"
            fieldClassName="col-6 md:col"
          />
          <InputField<DropdownProps>
            Input={Dropdown}
            inputProps={{
              value: load.value,
              options: valueOptions(device.type),
              onChange: (e) => setDeviceLoadField(deviceIndex, index, "value", e.target.value),
            }}
            field={false}
            id="value"
            label="Valor ao Ligar"
            fieldClassName="col-6 md:col"
          />
          <Button
            label="Identificar"
            containerClassName="px-2 flex-initial flex align-items-end mb-3"
            raised
            onClick={() => onClickIndentifyLoad(device, load.sequence)}
          />
        </Form>
      );
    });
  };

  const renderDevices = (): JSX.Element[] => {
    return project.outputDevices.map((device, index) => (
      <Panel header={renderHeader(device, index)} content={renderContent(device, index)} />
    ));
  };

  return (
    <Form>
      <Title pageTitle text="Cargas" />
      <InputField<DropdownProps>
        Input={Dropdown}
        inputProps={{
          options: OUTPUT_DEVICES,
          optionLabel: "modelName",
          optionValue: "modelName",
          value: modelName,
          onChange: (e) => setModelName(e.value),
        }}
        id="loads"
        label="Cargas"
        fieldClassName="col-12 md:col-3"
      />

      <InputField<InputNumberProps>
        Input={InputNumber}
        inputProps={{
          step: 1,
          min: 1,
          max: maxInputNumber,
          value: amount,
          showButtons: true,
          onChange: (e) => {
            let num = e.value;
            if (num) {
              num > maxInputNumber && (num = maxInputNumber);
              setAmount(num);
            }
          },
        }}
        id="amount"
        label="Quantidade"
        fieldClassName="px-2 w-9rem"
      />

      <Button
        label="Adicionar"
        icon="pi pi-fw pi-plus"
        containerClassName="px-2 flex-initial flex align-items-end mb-5"
        raised
        onClick={onClickAddDevices}
      />

      <Divider align="left">
        <b>{`Dispositivos Adicionados: ${project.outputDevices.length}`}</b>
      </Divider>

      <div className="mx-2 mb-5 w-full">{renderDevices()}</div>
    </Form>
  );
};

export default Step4;
