import { PathRouteProps } from "react-router-dom";
import { USER_ROLE } from "src/models/user.types";
import CreateClients from "src/pages/Clients/CreateClients";
import ListClients from "src/pages/Clients/ListClients";
import CreateProjects from "src/pages/Projects/CreateProjects";
import ListProjects from "src/pages/Projects/ListProjects";

export enum PUBLIC_PATH {
  SIGN_IN = "/sign_in",
  SIGN_UP = "/sign_up/:id",
  NOT_FOUND = "*",
}

export enum PRIVATE_PATH {
  HOME = "/",
  CLIENTS_CREATE = "/clients/create",
  CLIENTS_LIST = "/clients/list",
  PROJECTS_CREATE = "/projects/create",
  PROJECTS_LIST = "/projects/list",
}

const DEALER_ROUTES: PathRouteProps[] = [
  { path: PRIVATE_PATH.CLIENTS_CREATE, element: <CreateClients /> },
  { path: PRIVATE_PATH.CLIENTS_LIST, element: <ListClients /> },
  { path: PRIVATE_PATH.PROJECTS_CREATE, element: <CreateProjects /> },
  { path: PRIVATE_PATH.PROJECTS_LIST, element: <ListProjects /> },
];

const DKTECH_ROUTES: PathRouteProps[] = [
  { path: PRIVATE_PATH.CLIENTS_CREATE, element: <CreateClients /> },
  { path: PRIVATE_PATH.CLIENTS_LIST, element: <ListClients /> },
  { path: PRIVATE_PATH.PROJECTS_CREATE, element: <CreateProjects /> },
  { path: PRIVATE_PATH.PROJECTS_LIST, element: <ListProjects /> },
];

const DEV_ROUTES: PathRouteProps[] = [...DKTECH_ROUTES];

export const getRoutesByRole = (role: USER_ROLE | null): PathRouteProps[] => {
  switch (role) {
    case USER_ROLE.DEV:
      return DEV_ROUTES;
    case USER_ROLE.DKTECH:
      return DKTECH_ROUTES;
    case USER_ROLE.DEALER:
      return DEALER_ROUTES;
    default:
      return [];
  }
};

export const getFirstPath = (role: USER_ROLE): PRIVATE_PATH => {
  const routesByRole = getRoutesByRole(role);
  if (!routesByRole.length) return PRIVATE_PATH.HOME;
  if (routesByRole.find((r) => r.path === PRIVATE_PATH.PROJECTS_LIST)) return PRIVATE_PATH.PROJECTS_LIST;
  return routesByRole[0].path as PRIVATE_PATH;
};
