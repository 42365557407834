export interface UserData extends UserDataDTO {
  /** doc ID */
  docId: string;
  /** user ID */
  uid: string;
  createdAt: string;
  updatedAt: string;
  disabled: boolean;
}

export interface UserDataDTO {
  /** creator user ID */
  creatorUid: string;
  /** creator user Role */
  creatorRole: USER_ROLE;
  /** user Role */
  role: USER_ROLE;
  /** user Type */
  type: USER_TYPE;
  name: string;
  tradeName?: string;
  cpfCnpj: string;
  indicatorIE: USER_INDICATOR_IE;
  stateSubscription?: string;
  citySubscription?: string;
  suframaSubscription?: string;
  cep: string;
  address: string;
  addressDescription: string;
  number: string;
  neighborhood: string;
  state: string;
  city: string;
  phone?: string;
  celPhone: string;
  /** financial email */
  financialEmail: string;
  /** auth email = (email || financialEmail) */
  email: string;
  /** carrier doc ID */
  carrierDocId: string;
}

export enum USER_ROLE {
  /** Acesso a todos os recursos e aos que estao sendo desenvolvidos */
  DEV = "DEV",
  /** Acesso aos recursos (exceto os que estao sendo desenvolvidos) */
  DKTECH = "DKTech",
  /** Acesso aos recursos de Revenda */
  DEALER = "Revenda",
  /** Cliente da Revenda - Ainda nao possui acesso ao APP */
  CLIENT = "Cliente",
  /** Transportadoras - Ainda nao possui acesso ao APP */
  CARRIER = "Transportadora",
}

export enum USER_TYPE {
  CLIENT = "Cliente",
  CARRIER = "Transportadora",
}

export enum USER_INDICATOR_IE {
  ICMS = "Contribuinte ICMS",
  EXEMPT = "Isento de Cadastro",
  NON_CONTRIBUTOR = "Não Contribuinte",
}
