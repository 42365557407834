import React from "react";
import StoreProvider from "./providers/StoreProvider";
import PublicRoutes from "./routes/PublicRoutes";
import PrimeReact from "primereact/api";
//** CSS */
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./assets/layout/layout.scss";

const App = () => {
  PrimeReact.ripple = true;

  return (
    <StoreProvider>
      <PublicRoutes />
    </StoreProvider>
  );
};

export default App;
