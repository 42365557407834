import React, { useRef, useState, useCallback, useEffect } from "react";
import { Card } from "primereact/card";
import { Dropdown, DropdownChangeEvent, DropdownProps } from "primereact/dropdown";
import { InputText, InputTextProps } from "primereact/inputtext";
import { SelectItemOptionsType } from "primereact/selectitem";
import Button from "src/components/Button";
import InputField from "src/components/InputField";
import { USER_INDICATOR_IE, USER_ROLE, USER_TYPE } from "src/models/user.types";
import { useStore } from "src/providers/StoreProvider";
import UserService from "src/service/UserService";
import {
  statesBR,
  requiredFieldMessage,
  buildSignUpPath,
  copyToClipboard,
  validateEmail,
  invalidEmailMessage,
} from "src/utils";
import { UserData, UserDataDTO } from "src/models/user.types";
import { Unsubscribe } from "firebase/firestore";
import Form from "src/components/Form";

const fieldClassName = "col-12 md:col-4 lg:col-3";

const CreateClients = () => {
  const {
    authContext: { userData },
    clientContext: { selectedClient, setSelectedClient },
    toastContext: { showMessage },
  } = useStore();
  const unsubscribeCarriers = useRef<Unsubscribe>(() => {});
  const [carriers, setCarriers] = useState<UserData[]>([]);
  const [signUpURL, setSignUpURL] = useState<string>("");
  const [type, setType] = useState<USER_TYPE | undefined>();
  const [typeError, setTypeError] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState<boolean>(false);
  const [tradeName, setTradeName] = useState<string>("");
  const [cpfCnpj, setCpfCnpj] = useState<string>("");
  const [cpfCnpjError, setCpfCnpjError] = useState<boolean>(false);
  const [indicatorIE, setIndicatorIE] = useState<USER_INDICATOR_IE | undefined>();
  const [indicatorIEError, setIndicatorIEError] = useState<boolean>(false);
  const [stateSubscription, setStateSubscription] = useState<string>("");
  const [citySubscription, setCitySubscription] = useState<string>("");
  const [suframaSubscription, setSuframaSubscription] = useState<string>("");
  const [cep, setCep] = useState<string>("");
  const [cepError, setCepError] = useState<boolean>(false);
  const [address, setAddress] = useState<string>("");
  const [addressError, setAddressError] = useState<boolean>(false);
  const [addressDescription, setAddressDescription] = useState<string>("");
  const [addressDescriptionError, setAddressDescriptionError] = useState<boolean>(false);
  const [number, setNumber] = useState<string>("");
  const [numberError, setNumberError] = useState<boolean>(false);
  const [neighborhood, setNeighborhood] = useState<string>("");
  const [neighborhoodError, setNeighborhoodError] = useState<boolean>(false);
  const [state, setState] = useState<string>("");
  const [stateError, setStateError] = useState<boolean>(false);
  const [city, setCity] = useState<string>("");
  const [cityError, setCityError] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>("");
  const [celPhone, setCelPhone] = useState<string>("");
  const [celPhoneError, setCelPhoneError] = useState<boolean>(false);
  const [financialEmail, setFinancialEmail] = useState<string>("");
  const [financialEmailError, setFinancialEmailError] = useState<boolean>(false);
  const [validFinancialEmail, setValidFinancialEmail] = useState<boolean>(true);
  const [validEmail, setValidEmail] = useState<boolean>(true);
  const [email, setEmail] = useState<string>("");
  const [carrierDocId, setCarrierDocId] = useState<string>("");
  const [disabledCarrierField, setDisabledCarrierField] = useState<boolean>(true);

  useEffect(() => {
    if (selectedClient) {
      setType(selectedClient.type);
      setName(selectedClient.name);
      setTradeName(selectedClient.tradeName || "");
      setCpfCnpj(selectedClient.cpfCnpj);
      setIndicatorIE(selectedClient.indicatorIE);
      setStateSubscription(selectedClient.stateSubscription || "");
      setCitySubscription(selectedClient.citySubscription || "");
      setSuframaSubscription(selectedClient.suframaSubscription || "");
      setCep(selectedClient.cep);
      setAddress(selectedClient.address);
      setAddressDescription(selectedClient.addressDescription);
      setNumber(selectedClient.number);
      setNeighborhood(selectedClient.neighborhood);
      setState(selectedClient.state);
      setCity(selectedClient.city);
      setPhone(selectedClient.phone || "");
      setCelPhone(selectedClient.celPhone);
      setFinancialEmail(selectedClient.financialEmail);
      setEmail(selectedClient.email || "");
      setCarrierDocId(selectedClient.carrierDocId);
    }
  }, []);

  useEffect(() => {
    if (userData && userData.uid) {
      unsubscribeCarriers.current = UserService.getCarriersOnSnapshot(userData.uid, userData.role, (snap) => {
        const carriersTmp = snap.docs.map((doc) => doc.data() as UserData);
        setCarriers(carriersTmp);
      });
    }
    return () => unsubscribeCarriers.current();
  }, [userData]);

  const getRole = (role: USER_ROLE, type: USER_TYPE): USER_ROLE => {
    if (type === USER_TYPE.CARRIER) return USER_ROLE.CARRIER;
    return role === USER_ROLE.DKTECH ? USER_ROLE.DEALER : USER_ROLE.CLIENT;
  };

  const submit = async () => {
    const error = validateFields();
    if (error) {
      showRequiredFieldsErrorMessage();
      return;
    }

    try {
      if (userData && userData.uid && type && indicatorIE) {
        const userDataDTO: UserDataDTO = {
          creatorUid: userData.uid,
          creatorRole: userData.role,
          role: getRole(userData.role, type),
          type,
          name,
          tradeName,
          cpfCnpj,
          indicatorIE,
          stateSubscription,
          citySubscription,
          suframaSubscription,
          cep,
          address,
          addressDescription,
          number,
          neighborhood,
          state,
          city,
          phone,
          celPhone,
          financialEmail,
          email: email || financialEmail,
          carrierDocId,
        };

        if (selectedClient) {
          const clientUpdated: UserData = {
            ...selectedClient,
            ...userDataDTO,
          };
          await UserService.updateUser(clientUpdated);
          showUpdateSuccessMessage();
          setSelectedClient(null);
          clearFields();
          return;
        }

        const docId = await UserService.createUser(userDataDTO);
        showSubmitSuccessMessage();
        clearFields();

        // Copy sign up URL only for DKTECH role
        if (userData.role === USER_ROLE.DKTECH && type !== USER_TYPE.CARRIER) {
          const path = buildSignUpPath(docId);
          copyUrl(path);
          setSignUpURL(path);
        }
      }
    } catch (error) {
      showSubmitErrorMessage();
    }
  };

  const validateFields = () => {
    const _typeError = !type;
    const _nameError = !name;
    const _cpfCnpjErrorError = !cpfCnpj;
    const _indicatorIEError = !indicatorIE;
    const _cepError = !cep;
    const _addressError = !address;
    const _addressDescriptionError = !addressDescription;
    const _numberError = !number;
    const _neighborhoodError = !neighborhood;
    const _stateError = !state;
    const _cityError = !city;
    const _celPhoneError = !celPhone;
    const _financialEmailError = !financialEmail;
    const _validFinancialEmail = validateEmail(financialEmail);
    const _validEmail = email ? validateEmail(email) : true;

    setTypeError(_typeError);
    setNameError(_nameError);
    setCpfCnpjError(_cpfCnpjErrorError);
    setIndicatorIEError(_indicatorIEError);
    setCepError(_cepError);
    setAddressError(_addressError);
    setAddressDescriptionError(_addressDescriptionError);
    setNumberError(_numberError);
    setNeighborhoodError(_neighborhoodError);
    setStateError(_stateError);
    setCityError(_cityError);
    setCelPhoneError(_celPhoneError);
    setFinancialEmailError(_financialEmailError);
    setValidFinancialEmail(_validFinancialEmail);
    setValidEmail(_validEmail);

    return (
      _typeError ||
      _nameError ||
      _cpfCnpjErrorError ||
      _indicatorIEError ||
      _cepError ||
      _addressError ||
      _addressDescriptionError ||
      _numberError ||
      _neighborhoodError ||
      _stateError ||
      _cityError ||
      _celPhoneError ||
      _financialEmailError ||
      !_validFinancialEmail ||
      !_validEmail
    );
  };

  const clearFields = () => {
    if (type === USER_TYPE.CARRIER) setSignUpURL("");
    setType(undefined);
    setName("");
    setTradeName("");
    setCpfCnpj("");
    setIndicatorIE(undefined);
    setStateSubscription("");
    setCitySubscription("");
    setSuframaSubscription("");
    setCep("");
    setAddress("");
    setAddressDescription("");
    setNumber("");
    setNeighborhood("");
    setState("");
    setCity("");
    setPhone("");
    setCelPhone("");
    setFinancialEmail("");
    setEmail("");
    setCarrierDocId("");
    setDisabledCarrierField(true);
    setTypeError(false);
    setNameError(false);
    setCpfCnpjError(false);
    setIndicatorIEError(false);
    setCepError(false);
    setAddressError(false);
    setAddressDescriptionError(false);
    setNumberError(false);
    setNeighborhoodError(false);
    setStateError(false);
    setCityError(false);
    setCelPhoneError(false);
    setFinancialEmailError(false);
    setValidFinancialEmail(true);
    setValidEmail(true);
  };

  const showSubmitSuccessMessage = () => {
    showMessage({
      severity: "success",
      summary: "Cadastro realizado com sucesso!",
      life: 5000,
    });
  };

  const showUpdateSuccessMessage = () => {
    showMessage({
      severity: "success",
      summary: "Cliente atualizado com sucesso!",
      life: 5000,
    });
  };

  const showSubmitErrorMessage = () => {
    showMessage({
      severity: "error",
      summary: "Erro.",
      detail: "Ocorreu um erro inesperado! Tente novamente.",
      life: 5000,
    });
  };

  const showRequiredFieldsErrorMessage = () => {
    const invalidEmail = !validFinancialEmail || !validEmail;
    showMessage({
      severity: "error",
      summary: invalidEmail ? "E-mail inválido!" : "Campo obrigatório vazio!",
      detail: invalidEmail ? "Insira e-mails válidos nos campos de e-mail." : "Preencha todos os campos obrigatórios.",
      life: 5000,
    });
  };

  const copyUrl = (text: string) => {
    copyToClipboard(text);
    showMessage({
      severity: "success",
      summary: "URL de cadastro copiada!",
      detail: "Envie essa URL para o usuário criar o login.",
      life: 5000,
    });
  };

  const typeOptions = useCallback(() => {
    return [
      { label: USER_TYPE.CLIENT, value: USER_TYPE.CLIENT },
      { label: USER_TYPE.CARRIER, value: USER_TYPE.CARRIER },
    ] as SelectItemOptionsType;
  }, []);

  const indicatorIEOptions = useCallback(() => {
    return [
      { label: USER_INDICATOR_IE.ICMS, value: USER_INDICATOR_IE.ICMS },
      {
        label: USER_INDICATOR_IE.EXEMPT,
        value: USER_INDICATOR_IE.EXEMPT,
      },
      {
        label: USER_INDICATOR_IE.NON_CONTRIBUTOR,
        value: USER_INDICATOR_IE.NON_CONTRIBUTOR,
      },
    ] as SelectItemOptionsType;
  }, []);

  const stateOptions = useCallback(() => {
    return statesBR.map((state) => ({
      label: state,
      value: state,
    })) as SelectItemOptionsType;
  }, []);

  const carrierOptions = useCallback(() => {
    const options: SelectItemOptionsType = [
      {
        label: "Não definida",
        value: "",
      },
    ];
    carriers.forEach((carrier) =>
      options.push({
        label: carrier.tradeName || carrier.name,
        value: carrier.docId,
      })
    );
    return options;
  }, [carriers]);

  const onChangeType = (event: DropdownChangeEvent) => {
    const value = event.value as USER_TYPE;
    setType(value);
    setTypeError(false);
    if (value === USER_TYPE.CARRIER) {
      setCarrierDocId("");
      setDisabledCarrierField(true);
    } else setDisabledCarrierField(false);
  };

  return (
    <Card title="Cadastrar Cliente" subTitle="Campo obrigatório (*)">
      <Form>
        <InputField<DropdownProps>
          Input={Dropdown}
          inputProps={{
            value: type,
            options: typeOptions(),
            onChange: onChangeType,
          }}
          id="type"
          label="Tipo *"
          error={typeError}
          errorText={requiredFieldMessage}
          fieldClassName={fieldClassName}
        />
        <InputField<InputTextProps>
          Input={InputText}
          inputProps={{
            value: name,
            onChange: (event) => {
              setName(event.target.value);
              setNameError(false);
            },
          }}
          id="name"
          label="Nome ou Razão Social *"
          error={nameError}
          errorText={requiredFieldMessage}
          fieldClassName={fieldClassName}
        />
        <InputField<InputTextProps>
          Input={InputText}
          inputProps={{
            value: tradeName,
            onChange: (event) => {
              setTradeName(event.target.value);
            },
          }}
          id="tradeName"
          label="Nome Fantasia"
          fieldClassName={fieldClassName}
        />
        <InputField<InputTextProps>
          Input={InputText}
          inputProps={{
            value: cpfCnpj,
            onChange: (event) => {
              setCpfCnpj(event.target.value);
              setCpfCnpjError(false);
            },
          }}
          id="cpfCnpj"
          label="CPF ou CNPJ *"
          error={cpfCnpjError}
          errorText={requiredFieldMessage}
          fieldClassName={fieldClassName}
        />
        <InputField<DropdownProps>
          Input={Dropdown}
          inputProps={{
            value: indicatorIE,
            options: indicatorIEOptions(),
            onChange: (event) => {
              setIndicatorIE(event.value);
              setIndicatorIEError(false);
            },
          }}
          id="indicatorIE"
          label="Indicador de IE *"
          error={indicatorIEError}
          errorText={requiredFieldMessage}
          fieldClassName={fieldClassName}
        />
        <InputField<InputTextProps>
          Input={InputText}
          inputProps={{
            value: stateSubscription,
            onChange: (event) => {
              setStateSubscription(event.target.value);
            },
          }}
          id="stateSubscription"
          label="Inscrição Estadual"
          fieldClassName={fieldClassName}
        />
        <InputField<InputTextProps>
          Input={InputText}
          inputProps={{
            value: citySubscription,
            onChange: (event) => {
              setCitySubscription(event.target.value);
            },
          }}
          id="citySubscription"
          label="Inscrição Municipal"
          fieldClassName={fieldClassName}
        />
        <InputField<InputTextProps>
          Input={InputText}
          inputProps={{
            value: suframaSubscription,
            onChange: (event) => {
              setSuframaSubscription(event.target.value);
            },
          }}
          id="suframaSubscription"
          label="Inscrição Suframa"
          fieldClassName={fieldClassName}
        />
        <InputField<InputTextProps>
          Input={InputText}
          inputProps={{
            value: cep,
            onChange: (event) => {
              setCep(event.target.value);
              setCepError(false);
            },
          }}
          id="cep"
          label="CEP *"
          error={cepError}
          errorText={requiredFieldMessage}
          fieldClassName={fieldClassName}
        />
        <InputField<InputTextProps>
          Input={InputText}
          inputProps={{
            value: address,
            onChange: (event) => {
              setAddress(event.target.value);
              setAddressError(false);
            },
          }}
          id="address"
          label="Endereço *"
          error={addressError}
          errorText={requiredFieldMessage}
          fieldClassName={fieldClassName}
        />
        <InputField<InputTextProps>
          Input={InputText}
          inputProps={{
            value: number,
            onChange: (event) => {
              setNumber(event.target.value);
              setNumberError(false);
            },
          }}
          id="number"
          label="Número *"
          error={numberError}
          errorText={requiredFieldMessage}
          fieldClassName={fieldClassName}
        />
        <InputField<InputTextProps>
          Input={InputText}
          inputProps={{
            value: addressDescription,
            onChange: (event) => {
              setAddressDescription(event.target.value);
              setAddressDescriptionError(false);
            },
          }}
          id="addressDescription"
          label="Complemento *"
          error={addressDescriptionError}
          errorText={requiredFieldMessage}
          fieldClassName={fieldClassName}
        />
        <InputField<InputTextProps>
          Input={InputText}
          inputProps={{
            value: neighborhood,
            onChange: (event) => {
              setNeighborhood(event.target.value);
              setNeighborhoodError(false);
            },
          }}
          id="neighborhood"
          label="Bairro *"
          error={neighborhoodError}
          errorText={requiredFieldMessage}
          fieldClassName={fieldClassName}
        />
        <InputField<DropdownProps>
          Input={Dropdown}
          inputProps={{
            value: state,
            options: stateOptions(),
            onChange: (event) => {
              setState(event.value);
              setStateError(false);
            },
          }}
          id="state"
          label="Estado *"
          error={stateError}
          errorText={requiredFieldMessage}
          fieldClassName={fieldClassName}
        />
        <InputField<InputTextProps>
          Input={InputText}
          inputProps={{
            value: city,
            onChange: (event) => {
              setCity(event.target.value);
              setCityError(false);
            },
          }}
          id="city"
          label="Cidade *"
          error={cityError}
          errorText={requiredFieldMessage}
          fieldClassName={fieldClassName}
        />
        <InputField<InputTextProps>
          Input={InputText}
          inputProps={{
            value: phone,
            onChange: (event) => {
              setPhone(event.target.value);
            },
          }}
          id="phone"
          label="Telefone fixo"
          fieldClassName={fieldClassName}
        />
        <InputField<InputTextProps>
          Input={InputText}
          inputProps={{
            value: celPhone,
            onChange: (event) => {
              setCelPhone(event.target.value);
              setCelPhoneError(false);
            },
          }}
          id="celPhone"
          label="Telefone celular *"
          error={celPhoneError}
          errorText={requiredFieldMessage}
          fieldClassName={fieldClassName}
        />
        <InputField<InputTextProps>
          Input={InputText}
          inputProps={{
            value: financialEmail,
            type: "email",
            onChange: (event) => {
              const { value } = event.target;
              setFinancialEmail(value);
              setFinancialEmailError(false);
              setValidFinancialEmail(validateEmail(value));
            },
          }}
          id="financialEmail"
          label="E-mail financeiro *"
          error={financialEmailError || !validFinancialEmail}
          errorText={financialEmailError ? requiredFieldMessage : invalidEmailMessage}
          fieldClassName={fieldClassName}
        />
        <InputField<InputTextProps>
          Input={InputText}
          inputProps={{
            value: email,
            type: "email",
            onChange: (event) => {
              const { value } = event.target;
              setEmail(value);
              setValidEmail(validateEmail(value));
            },
            tooltip:
              "E-mail utilizado para login na plataforma. Se não preenchido, o email financeiro será definido para o login desse cliente.",
            tooltipOptions: {
              position: "top",
            },
          }}
          id="email"
          label="E-mail pessoal"
          error={!validEmail}
          errorText={invalidEmailMessage}
          fieldClassName={fieldClassName}
        />
        <InputField<DropdownProps>
          Input={Dropdown}
          inputProps={{
            value: carrierDocId,
            disabled: disabledCarrierField,
            options: carrierOptions(),
            onChange: (event) => {
              setCarrierDocId(event.value);
            },
          }}
          id="carrier"
          label="Transportadora preferencial *"
          fieldClassName={fieldClassName}
        />
      </Form>

      <Button label="Salvar" icon="pi pi-fw pi-check" success={true} raised onClick={submit} />

      {signUpURL && (
        <div className="flex flex-wrap align-items-center pt-3">
          <div className="mr-3 my-1">
            <b>URL de Cadastro: {signUpURL}</b>
          </div>
          <Button
            icon="pi pi-copy"
            text
            rounded
            tooltip="Copiar"
            tooltipOptions={{ position: "top" }}
            onClick={() => copyUrl(signUpURL)}
            className="my-1"
          />
        </div>
      )}
    </Card>
  );
};

export default CreateClients;
