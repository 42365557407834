import { useState, useEffect, useRef } from "react";
import { User } from "firebase/auth";
import AuthenticationService from "src/service/AuthenticationService";
import { UserData } from "src/models/user.types";
import UserService from "src/service/UserService";
import { Unsubscribe } from "firebase/firestore";

export type Auth = User | null;

export interface AuthContext {
  auth: Auth;
  loadingAuth: boolean;
  userData: UserData | null;
  loadingUserData: boolean;
}

export const authContextDefault: AuthContext = {
  auth: null,
  loadingAuth: true,
  userData: null,
  loadingUserData: true,
};

export const useAuthProvider = (): AuthContext => {
  const [auth, setAuth] = useState<Auth>(null);
  const [loadingAuth, setLoadingAuth] = useState<boolean>(true);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [loadingUserData, setLoadingUserData] = useState<boolean>(true);
  const unsubscribeUser = useRef<Unsubscribe>(() => {});

  useEffect(() => {
    const unsubscribe = AuthenticationService.onAuthStateChanged((user) => {
      setAuth(user);
      setLoadingAuth(false);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (auth) {
      unsubscribeUser.current = UserService.getUserOnSnapshot(auth.uid, (snap) => {
        const { docs, empty } = snap;
        const userDataTmp = empty ? null : (docs[0].data() as UserData);
        setUserData(userDataTmp);
        setLoadingUserData(false);
      });
      return () => {
        unsubscribeUser.current();
      };
    }
  }, [auth]);

  return { auth, loadingAuth, userData, loadingUserData };
};
