import { useEffect, useRef, useState } from "react";
import { DEFAULT_PORT_NUMBER, Project, PROJECT_TYPE } from "src/models/project.types";
import { UserData } from "src/models/user.types";
import { Unsubscribe } from "firebase/firestore";
import ProjectService from "src/service/ProjectService";
import { getDateUTC } from "src/utils";

export interface ProjectContext {
  project: Project;
  projects: Project[];
  saveProjectFlag: boolean;
  createNewProject: () => void;
  setProjectData: (projectTmp: Project, saveFlag: boolean) => void;
  saveProjectData: () => Promise<void>;
}

const defaultProject: Project = {
  id: "",
  type: PROJECT_TYPE.PROJECT,
  creatorId: "",
  clientId: "",
  name: "",
  central: {
    enabled: false,
    port: DEFAULT_PORT_NUMBER,
  },
  environments: [],
  modules: [],
  inputDevices: [],
  outputDevices: [],
  scenes: [],
  createdAt: "",
  updatedAt: "",
};

export const projectContextDefault: ProjectContext = {
  project: defaultProject,
  projects: [],
  saveProjectFlag: false,
  createNewProject: () => {},
  setProjectData: () => {},
  saveProjectData: async () => {},
};

export const useProjectProvider = (userData: UserData | null): ProjectContext => {
  const [project, setProject] = useState<Project>(defaultProject);
  const [projects, setProjects] = useState<Project[]>([]);
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [saveProjectFlag, setSaveProjectFlag] = useState<boolean>(false);
  const unsubscribeProjects = useRef<Unsubscribe>(() => {});

  useEffect(() => {
    if (userData) {
      if (firstRender) {
        createNewProject();
        setFirstRender(false);
      }

      unsubscribeProjects.current = ProjectService.getProjectsOnSnapshot(userData.docId, (snap) => {
        const projectsTmp = snap.docs.map((doc) => doc.data() as Project).filter((p) => !p.deleted);
        setProjects(projectsTmp);
      });

      return () => {
        unsubscribeProjects.current();
      };
    }
  }, [userData]);

  const createNewProject = () => {
    if (!userData) return;
    setProject({
      id: ProjectService.createProjectID(),
      type: PROJECT_TYPE.PROJECT,
      creatorId: userData.docId,
      clientId: "",
      name: "",
      central: {
        enabled: false,
        port: DEFAULT_PORT_NUMBER,
      },
      environments: new Array(0),
      modules: new Array(0),
      inputDevices: new Array(0),
      outputDevices: new Array(0),
      scenes: new Array(0),
      createdAt: getDateUTC(),
      updatedAt: getDateUTC(),
    });
    setSaveProjectFlag(false);
  };

  const setProjectData = (projectTmp: Project, saveFlag: boolean) => {
    setProject({ ...projectTmp });
    setSaveProjectFlag(saveFlag);
  };

  /** Save Project data in DB */
  const saveProjectData = async () => {
    if (saveProjectFlag) {
      await ProjectService.saveProject(project);
      setSaveProjectFlag(false);
    }
  };

  return { project, projects, saveProjectFlag, createNewProject, setProjectData, saveProjectData };
};
