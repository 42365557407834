import { MenuItem } from "primereact/menuitem";
import { useCallback, useEffect, useState } from "react";
import { MENU_ITEM_TYPE } from "src/models/types";
import useWindowSize from "src/hooks/useWindowSize";

export type LayoutMode = "overlay" | "static" | null;

export interface ActiveButton {
  id: string;
  hasItems: boolean;
}

export interface SidebarContext {
  layoutMode: LayoutMode;
  sidebarActive: boolean;
  toggleSidebar: () => void;
  setSidebarClicked: React.Dispatch<React.SetStateAction<boolean>>;
  setSidebarClickedOut: React.Dispatch<React.SetStateAction<boolean>>;
  closeSidebarOnLayoutOnverlay: () => void;
  checkIfButtonIsActive: (id: string | undefined) => boolean;
  handleOnClickButton: (item: MenuItem) => void;
}

export const sidebarContextDefault: SidebarContext = {
  layoutMode: null,
  sidebarActive: false,
  toggleSidebar: () => {},
  setSidebarClicked: () => {},
  setSidebarClickedOut: () => {},
  closeSidebarOnLayoutOnverlay: () => {},
  checkIfButtonIsActive: () => false,
  handleOnClickButton: () => {},
};

export const useSidebarProvider = (): SidebarContext => {
  const [layoutMode, setLayoutMode] = useState<LayoutMode>(null);
  const [sidebarActive, setSidebarActive] = useState<boolean>(true);
  const [sidebarClicked, setSidebarClicked] = useState<boolean>(false);
  const [sidebarClickedOut, setSidebarClickedOut] = useState<boolean>(false);
  const [activeButtons, setActiveButtons] = useState<ActiveButton[]>([]);

  const { width } = useWindowSize();

  useEffect(() => {
    if (width >= 992) setLayoutMode("static");
    else {
      setLayoutMode("overlay");
      setSidebarActive(false);
    }
  }, [width]);

  // Hide sidebar if clicked outside
  useEffect(() => {
    if (sidebarActive && layoutMode === "overlay") {
      if (!sidebarClicked && sidebarClickedOut) {
        toggleSidebar();
      }
      setSidebarClicked(false);
      setSidebarClickedOut(false);
    }
  }, [sidebarActive, layoutMode, sidebarClicked, sidebarClickedOut]);

  const toggleSidebar = useCallback(() => {
    setSidebarActive((prevState) => !prevState);
  }, []);

  const closeSidebarOnLayoutOnverlay = () => {
    layoutMode === "overlay" && setSidebarActive(false);
  };

  const checkIfButtonIsActive = useCallback(
    (id: string | undefined): boolean => {
      if (!id) return false;
      return !!activeButtons.find((btn) => btn.id === id);
    },
    [activeButtons]
  );

  const handleOnClickButton = (item: MenuItem): void => {
    const { id, items } = item;

    if (!id) return;

    const hasItems = !!items;
    const newBtn: ActiveButton = {
      id,
      hasItems,
    };

    // Handle ROOT buttons
    if (id.startsWith(MENU_ITEM_TYPE.ROOT)) {
      if (!hasItems) {
        setActiveButtons([newBtn]);
        return;
      }
      const isActive = checkIfButtonIsActive(id);
      setActiveButtons(isActive ? [] : [newBtn]);
      return;
    }

    const lastIndex = activeButtons.length - 1;

    // Handle NESTED buttons without items property
    if (!hasItems) {
      const lastActiveBtnHasItems = activeButtons[lastIndex].hasItems;
      if (lastActiveBtnHasItems) {
        setActiveButtons([...activeButtons, newBtn]);
      } else {
        activeButtons[lastIndex] = newBtn;
        setActiveButtons([...activeButtons]);
      }
      return;
    }

    // Handle NESTED buttons with items property
    const btnIndex = activeButtons.findIndex((btn) => btn.id === id);
    if (btnIndex >= 0) {
      setActiveButtons(activeButtons.slice(0, btnIndex));
    } else {
      const lastActiveBtnHasItems = activeButtons[lastIndex].hasItems;
      if (lastActiveBtnHasItems) {
        setActiveButtons([...activeButtons, newBtn]);
      } else {
        activeButtons[lastIndex] = newBtn;
        setActiveButtons([...activeButtons]);
      }
    }
  };

  return {
    layoutMode,
    sidebarActive,
    toggleSidebar,
    closeSidebarOnLayoutOnverlay,
    checkIfButtonIsActive,
    handleOnClickButton,
    setSidebarClicked,
    setSidebarClickedOut,
  };
};
