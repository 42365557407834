import { Dialog, DialogProps } from "primereact/dialog";
import React from "react";
import Button from "src/components/Button";
import { useStore } from "src/providers/StoreProvider";
import { getDateLocale } from "src/utils";

const ProfileSettings = (props: DialogProps) => {
  const {
    authContext: { userData },
  } = useStore();

  const renderFooter = () => {
    return (
      <div>
        <Button label="Fechar" onClick={props.onHide} />
      </div>
    );
  };

  return (
    <Dialog
      header="Configurações do Perfil"
      visible={props.visible}
      onHide={props.onHide}
      breakpoints={{ "960px": "75vw" }}
      style={{ width: "50vw" }}
      footer={renderFooter()}
    >
      {userData && (
        <div>
          <p>
            <b>Nome: </b>
            {` ${userData.name}`}
          </p>
          <p>
            <b>E-mail: </b>
            {` ${userData.email}`}
          </p>
          <p>
            <b>Perfil: </b>
            {` ${userData.role}`}
          </p>
          <p>
            <b>Data de Cadastro: </b>
            {` ${getDateLocale(userData.createdAt)}`}
          </p>
        </div>
      )}
    </Dialog>
  );
};

export default ProfileSettings;
