import React from "react";
import AppTopbar from "./AppTopbar";
import AppSidebar from "./AppSidebar";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import { useStore } from "src/providers/StoreProvider";
import { Toast } from "primereact/toast";

interface HomeProps {
  children?: React.ReactNode;
}

const Home = (props: HomeProps) => {
  const {
    sidebarContext: { layoutMode, sidebarActive, setSidebarClickedOut },
    toastContext: { toast },
  } = useStore();

  const wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": layoutMode === "overlay",
    "layout-static": layoutMode === "static",
    "layout-mobile-sidebar-active": sidebarActive && layoutMode === "overlay",
    "layout-static-sidebar-inactive": !sidebarActive && layoutMode === "static",
  });

  return (
    <div className={wrapperClass}>
      <AppTopbar />
      <AppSidebar />
      <div className="layout-main-container">
        <Toast ref={toast} />
        {props.children}
      </div>
      <CSSTransition
        classNames="layout-mask"
        timeout={{ enter: 200, exit: 200 }}
        in={sidebarActive && layoutMode === "overlay"}
        unmountOnExit
      >
        <div className="layout-mask p-component-overlay" onClick={() => setSidebarClickedOut(true)} />
      </CSSTransition>
    </div>
  );
};

export default Home;
