import { auth } from "src/api";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  User,
  NextOrObserver,
  ErrorFn,
  CompleteFn,
  Unsubscribe,
  setPersistence,
  browserSessionPersistence,
  browserLocalPersistence,
  sendPasswordResetEmail,
} from "firebase/auth";
import UserService from "../UserService";
import { FirebaseError } from "firebase/app";

class AuthenticationService {
  static async signUp(email: string, password: string): Promise<void> {
    try {
      await this.setSessionPersistence();
      const {
        user: { uid },
      } = await createUserWithEmailAndPassword(auth, email, password);
      await UserService.attachUID(email, uid);
    } catch (error) {
      const err = error as FirebaseError;
      console.error(err);
      throw err;
    }
  }

  static async signIn(
    email: string,
    password: string,
    rememberMe: boolean
  ): Promise<void> {
    try {
      if (rememberMe) await this.setLocalPersistence();
      else await this.setSessionPersistence();
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      const err = error as FirebaseError;
      console.error(err);
      throw err;
    }
  }

  static async signOut(): Promise<void> {
    try {
      await signOut(auth);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  static onAuthStateChanged(
    nextOrObserver: NextOrObserver<User | null>,
    error?: ErrorFn,
    completed?: CompleteFn
  ): Unsubscribe {
    return onAuthStateChanged(auth, nextOrObserver, error, completed);
  }

  static async setSessionPersistence(): Promise<void> {
    try {
      await setPersistence(auth, browserSessionPersistence);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  static async setLocalPersistence(): Promise<void> {
    try {
      await setPersistence(auth, browserLocalPersistence);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }

  static async sendPasswordResetEmail(email: string): Promise<void> {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }
}

export default AuthenticationService;
